/*!
 * Bootstrap v3.3.7 (http://getbootstrap.com)
 * Copyright 2011-2016 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
/* Key Content Sizing */
/* Interval between consecutive placeholders */
/* Repeated Placeholder */
/* Image */
/* Header Image */
/* Paragraph */
/* Variations */
/* Glow Gradient */
/*!
 * bootstrap-star-rating v4.0.6
 * http://plugins.krajee.com/star-rating
 *
 * Author: Kartik Visweswaran
 * Copyright: 2013 - 2019, Kartik Visweswaran, Krajee.com
 *
 * Licensed under the BSD 3-Clause
 * https://github.com/kartik-v/bootstrap-star-rating/blob/master/LICENSE.md
 */
.rating-loading {
  width: 25px;
  height: 25px;
  font-size: 0;
  color: #fff;
  border: none;
}

/*
* Stars & Input
*/
.rating-container .rating-stars {
  position: relative;
  cursor: pointer;
  vertical-align: middle;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
}

.rating-container .rating-input {
  position: absolute;
  cursor: pointer;
  width: 100%;
  height: 1px;
  bottom: 0;
  left: 0;
  font-size: 1px;
  border: none;
  background: none;
  opacity: 0;
  padding: 0;
  margin: 0;
}

.rating-container.is-display-only .rating-input,
.rating-container.is-display-only .rating-stars {
  cursor: default;
}

.rating-disabled .rating-input, .rating-disabled .rating-stars {
  cursor: not-allowed;
}

.rating-container .star {
  display: inline-block;
  margin: 0 2px;
  text-align: center;
}

.rating-container .empty-stars {
  color: #aaa;
}

.rating-container .filled-stars {
  position: absolute;
  left: 0;
  top: 0;
  margin: auto;
  color: #fde16d;
  white-space: nowrap;
  overflow: hidden;
  -webkit-text-stroke: 1px #777;
  text-shadow: 1px 1px #999;
}

.rating-rtl {
  float: right;
}

.rating-animate .filled-stars {
  transition: width 0.25s ease;
}

.rating-rtl .filled-stars {
  left: auto;
  right: 0;
  transition: none;
  -webkit-transform: matrix(-1, 0, 0, 1, 0, 0);
  transform: matrix(-1, 0, 0, 1, 0, 0);
}

.rating-rtl.is-star .filled-stars {
  right: 0.06em;
}

.rating-rtl.is-heart .empty-stars {
  margin-right: 0.07em;
}

/**
* Clear
*/
.rating-container .clear-rating {
  color: #aaa;
  cursor: not-allowed;
  display: inline-block;
  vertical-align: middle;
  font-size: 60%;
}

.clear-rating-active {
  cursor: pointer !important;
}

.clear-rating-active:hover {
  color: #843534;
}

.rating-container .clear-rating {
  padding-right: 5px;
}

/**
* Caption
*/
/* extend support to BS4 */
.rating-container .caption .label {
  display: inline-block;
  padding: .25em .4em;
  line-height: 1;
  text-align: center;
  vertical-align: baseline;
  border-radius: .25rem;
}

.rating-container .caption {
  color: #999;
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
}

.rating-container .caption {
  margin-left: 5px;
  margin-right: 0;
}

.rating-rtl .caption {
  margin-right: 5px;
  margin-left: 0;
}

/**
* Print
*/
@media print {
  .rating-container .clear-rating {
    display: none;
  }
}

/**
* Sizes
*/
.rating-xl {
  font-size: 48px;
}

.rating-lg {
  font-size: 40px;
}

.rating-md {
  font-size: 32px;
}

.rating-sm {
  font-size: 24px;
}

.rating-xs {
  font-size: 16px;
}

.rating-xl .caption {
  font-size: 20px;
}

.rating-lg .caption {
  font-size: 18px;
}

.rating-md .caption {
  font-size: 16px;
}

.rating-sm .caption {
  font-size: 14px;
}

.rating-xs .caption {
  font-size: 12px;
}

.pages-padding {
  padding: 24px 0;
}

.section-admin-login {
  background: rgba(0, 0, 0, 0.1);
  padding: 0;
}

.section-admin-login:after {
  position: absolute;
  content: '';
  background: #fff;
  top: 0;
  width: 100%;
  height: 52%;
  background: rgba(0, 0, 0, 0.03);
}

.login-logo-outer {
  text-align: center;
  margin-bottom: 16px;
}

.login-logo-outer .profile-login-logo {
  display: block;
  height: 50px;
  margin: 0 auto;
}

.login-logo-outer .profile-login-logo img {
  margin: 0 auto;
  display: block;
  height: 100%;
  width: auto;
}

.login-form {
  padding: 16px 0;
}

.login-form .login-section {
  top: 50%;
  margin-top: -240px;
  position: absolute;
  width: 410px;
  left: 50%;
  margin-left: -205px;
  z-index: 33;
}

.login-form .card-signup {
  padding: 48px;
  border-radius: 4px;
  background: #fff;
}

.login-form .login-form-text {
  font-size: 320%;
  text-align: center;
  margin-bottom: 30px;
  line-height: 1.2;
}

.login-form .login-form-text .small {
  display: block;
  font-size: 35%;
  color: #4a00e0;
}

@media (max-width: 768px) {
  .login-form .login-section {
    top: auto;
    margin-top: auto;
    margin-left: auto;
    position: relative;
    width: 92%;
    left: auto;
    margin: 0 auto;
    z-index: 33;
  }
}

.errorHandler {
  display: none;
}

.login-section {
  padding: 32px;
  border: 2px solid #bfbfbf;
  border-radius: 14px;
}

.login-section .button-action {
  text-align: center;
  margin: 16px auto;
}

.login-section .register {
  min-width: 160px;
  margin-bottom: 6px;
}

@media (max-width: 768px) {
  .login-section {
    padding: 16px;
  }
}

.heading-page {
  position: relative;
  z-index: 10;
  margin-bottom: 16px;
}

.heading-page:before, .heading-page:after {
  display: table;
  content: " ";
}

.heading-page:after {
  clear: both;
}

.heading-page .heading {
  font-size: 220%;
  margin: 0;
  font-weight: 700;
  position: relative;
}

.heading-page .heading.with-line {
  margin-bottom: 16px;
  padding-bottom: 16px;
}

.heading-page .heading.with-line:before {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100px;
  height: 4px;
  background-color: #0080ff;
  content: '';
  font-family: "Font Awesome 5 Free";
  font-weight: 600;
}

.heading-page .heading.left {
  float: left;
}

.heading-page.with-link {
  border-bottom: 2px solid #bfbfbf;
  padding-bottom: 6px;
}

.heading-page.with-link:before, .heading-page.with-link:after {
  display: table;
  content: " ";
}

.heading-page.with-link:after {
  clear: both;
}

.heading-page.with-link .header {
  float: left;
  margin: 0;
  padding: 0;
}

.heading-page.with-link .link-button {
  float: right;
  margin-top: 11.42857px;
  font-weight: 600;
}

.heading-page .sub-heading {
  font-size: 110%;
  color: #333333;
}

.heading-page .sub-heading.large {
  font-size: 180%;
}

.heading-page .header {
  font-size: 220%;
  text-transform: uppercase;
  margin: 0;
  padding: 0;
}

@media (max-width: 768px) {
  .heading-page .heading {
    font-size: 160%;
    font-weight: 700;
  }
  .heading-page .sub-heading {
    font-size: 90%;
    font-weight: 300;
  }
  .heading-page .header {
    font-size: 160%;
  }
  .heading-page.with-link .header {
    float: none;
  }
  .heading-page.with-link .link-button {
    float: none;
    margin-top: 6px;
  }
}

.heading-dasshboard {
  position: relative;
  z-index: 10;
  margin-bottom: 6px;
  background-color: #f0f0f0;
  padding: 12px 16px;
}

.heading-dasshboard:before, .heading-dasshboard:after {
  display: table;
  content: " ";
}

.heading-dasshboard:after {
  clear: both;
}

.heading-dasshboard.with-link {
  padding-right: 80px;
}

.heading-dasshboard .heading {
  font-size: 140%;
  margin: 0;
  font-weight: 700;
  margin-bottom: 3px;
  position: relative;
}

.heading-dasshboard .heading .label {
  font-size: 50%;
}

.heading-dasshboard .heading.with-line {
  margin-bottom: 16px;
  padding-bottom: 16px;
}

.heading-dasshboard .heading.with-line:before {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100px;
  height: 4px;
  background-color: #0080ff;
  content: '';
  font-family: "Font Awesome 5 Free";
  font-weight: 600;
}

.heading-dasshboard .heading.left {
  float: left;
}

.heading-dasshboard .heading-link {
  position: absolute;
  z-index: 2;
  right: 0;
  top: 0;
}

.heading-dasshboard .links:before, .heading-dasshboard .links:after {
  display: table;
  content: " ";
}

.heading-dasshboard .links:after {
  clear: both;
}

.heading-dasshboard .links li {
  list-style: none;
  float: left;
  margin: 0 2px;
}

.heading-dasshboard .sub-heading {
  font-size: 90%;
  color: #333333;
  font-weight: 400;
}

.heading-dasshboard .header {
  font-size: 220%;
  text-transform: uppercase;
  color: #6f6f6f;
  font-weight: 700;
}

@media (max-width: 768px) {
  .heading-dasshboard .heading {
    font-size: 160%;
    font-weight: 700;
  }
  .heading-dasshboard .sub-heading {
    font-size: 90%;
    font-weight: 300;
  }
}

.section-header {
  margin: 0;
  position: relative;
  margin-bottom: 10.66667px;
}

.section-header.mini {
  border: 0;
  font-size: 120%;
  font-weight: 500;
}

.section-header.base {
  border: 0;
  font-size: 170%;
  font-weight: 300;
}

.section-header .link-back {
  position: absolute;
  right: 0;
  font-weight: 500;
  font-size: 60%;
  bottom: 0;
}

.section-header .link-back .icons {
  margin-right: 3px;
}

.page-header {
  margin: 0;
  border: 0;
}

.page-header.with-float:before, .page-header.with-float:after {
  display: table;
  content: " ";
}

.page-header.with-float:after {
  clear: both;
}

.page-header.with-float .section-header {
  float: left;
  margin: 0;
}

@media (max-width: 768px) {
  .page-header.with-float .section-header {
    float: none;
  }
}

.flurry-user-card {
  padding: 16px;
  border: 1px solid #bfbfbf;
  border-radius: 4px;
  position: relative;
  padding-left: 80px;
  min-height: 86px;
}

.flurry-user-card .flt-logo {
  position: absolute;
  left: 16px;
  top: 16px;
}

.flurry-user-card .flt-logo .icons-img {
  width: 48px;
  height: 48px;
  line-height: 48px;
  display: block;
  color: #fff;
  font-size: 220%;
  text-align: center;
  border-radius: 100%;
  background-color: #0080ff;
}

.flurry-user-card .contents-btns {
  position: absolute;
  right: 16px;
  top: 16px;
  z-index: 3;
}

.flurry-user-card .flt-contents .tittle {
  font-size: 130%;
  font-weight: 600;
}

.flurry-user-artical {
  margin: 6px auto;
}

.flurry-user-artical .heading {
  margin-bottom: 6px;
}

.no-display {
  display: none;
}

.load-more-sec {
  text-align: center;
}

.load-more-sec .load-more {
  text-transform: uppercase;
  position: relative;
}

.load-more-sec .load-more.wl {
  padding-left: 50px;
}

.load-more-sec .loader-round-w {
  position: absolute;
  top: 50%;
  left: 1em;
  margin-top: -0.8em;
  z-index: 5;
}

.load-more-sec .loader-round-b {
  position: absolute;
  top: 50%;
  left: 1em;
  margin-top: -1em;
  z-index: 5;
}

.view-all {
  text-align: center;
}

.view-all .link {
  text-transform: uppercase;
  position: relative;
}

.view-all .link.with-icon {
  padding-right: 60px;
}

.view-all .icd-ico {
  position: absolute;
  top: 50%;
  right: 0.5em;
  margin-top: -0.52em;
  z-index: 5;
  font-size: 180%;
}

.loader-round {
  margin: 0px auto;
  font-size: 32px;
  position: relative;
  text-indent: -9999em;
  border-top: 0.2em solid rgba(0, 0, 0, 0.1);
  border-right: 0.2em solid rgba(0, 0, 0, 0.1);
  border-bottom: 0.2em solid rgba(0, 0, 0, 0.1);
  border-left: 0.2em solid #F04124;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: key-linear-anmi 0.7s infinite linear;
  animation: key-linear-anmi 0.7s infinite linear;
}

.loader-round,
.loader-round:after {
  border-radius: 50%;
  width: 2em;
  height: 2em;
}

@-webkit-keyframes key-linear-anmi {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes key-linear-anmi {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.loader-round-w {
  margin: 0px auto;
  display: inline-block;
  margin-right: 6px;
  vertical-align: sub;
  font-size: inherit;
  position: relative;
  text-indent: -9999em;
  border-top: 0.15em solid rgba(255, 255, 255, 0.25);
  border-right: 0.15em solid rgba(255, 255, 255, 0.25);
  border-bottom: 0.15em solid rgba(255, 255, 255, 0.25);
  border-left: 0.15em solid white;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: key-linear-anmi 0.7s infinite linear;
  animation: key-linear-anmi 0.7s infinite linear;
}

.loader-round-w,
.loader-round-w:after {
  border-radius: 50%;
  width: 1.5em;
  height: 1.5em;
}

.loader-round-b {
  margin: 0px auto;
  display: inline-block;
  margin-right: 6px;
  vertical-align: sub;
  font-size: inherit;
  position: relative;
  text-indent: -9999em;
  border-top: 0.18em solid rgba(0, 0, 0, 0.25);
  border-right: 0.18em solid rgba(0, 0, 0, 0.25);
  border-bottom: 0.18em solid rgba(0, 0, 0, 0.25);
  border-left: 0.18em solid black;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: key-linear-anmi 0.7s infinite linear;
  animation: key-linear-anmi 0.7s infinite linear;
}

.loader-round-b,
.loader-round-b:after {
  border-radius: 50%;
  width: 1.7em;
  height: 1.7em;
}

.loader-elastic,
.loader-elastic:before,
.loader-elastic:after {
  border-radius: 50%;
}

.loader-elastic:before,
.loader-elastic:after {
  position: absolute;
  content: '';
}

.loader-elastic:before {
  width: 5.2em;
  height: 10.2em;
  background: #fff;
  border-radius: 10.2em 0 0 10.2em;
  top: -0.1em;
  left: -0.1em;
  -webkit-transform-origin: 5.2em 5.1em;
  transform-origin: 5.2em 5.1em;
  -webkit-animation: key-elastic 2s infinite ease 1.5s;
  animation: key-elastic 2s infinite ease 1.5s;
}

.loader-elastic {
  color: #F04124;
  font-size: 11px;
  text-indent: -99999em;
  margin: 55px auto;
  position: relative;
  width: 10em;
  height: 10em;
  box-shadow: inset 0 0 0 1em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}

.loader-elastic:after {
  width: 5.2em;
  height: 10.2em;
  background: #fff;
  border-radius: 0 10.2em 10.2em 0;
  top: -0.1em;
  left: 5.1em;
  -webkit-transform-origin: 0px 5.1em;
  transform-origin: 0px 5.1em;
  -webkit-animation: key-elastic 2s infinite ease;
  animation: key-elastic 2s infinite ease;
}

@-webkit-keyframes key-elastic {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes key-elastic {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.modalLoop .dialog-login {
  width: 460px;
  margin: 30px auto;
}

.modalLoop .dialog-signup {
  width: 580px;
  margin: 30px auto;
}

@media (max-width: 768px) {
  .modalLoop .dialog-signup {
    width: 92%;
  }
}

.modal-content.modalpop-forms {
  background-color: #fff;
  border-radius: 14px;
}

.modal-content.modalpop-forms .profile-login-logo {
  text-align: center;
  max-width: 160px;
  margin: 0 auto 1em;
}

.modal-content.modalpop-forms .profile-login-logo img {
  width: 100%;
  display: block;
}

.modal-content.modalpop-forms .login-form-text {
  text-align: center;
  border-top: 1px solid #bfbfbf;
  padding-top: 0.5em;
  font-size: 140%;
  text-transform: uppercase;
  margin-bottom: 1.5em;
  font-weight: 200;
}

.modal-content.modalpop-forms .login-form-text .small {
  font-size: 60%;
  display: block;
}

.modal-content.modalpop-forms .modal-title {
  text-align: center;
  font-size: 140%;
  margin: 0;
  font-weight: 600;
}

.modal-content.modalpop-forms .modalpop-header {
  padding: 20px 60px 0;
  margin-bottom: 16px;
}

.modal-content.modalpop-forms .heading {
  margin: 0;
  text-align: center;
}

.modal-content.modalpop-forms .heading .header {
  font-weight: 600;
  font-size: 130%;
}

.modal-content.modalpop-forms .modalpop-body {
  padding: 10px 40px;
}

.modal-content.modalpop-forms .sucess-body {
  color: #5cb85c;
  padding: 50px 3px;
}

.modal-content.modalpop-forms .forgot {
  text-align: right;
  font-size: 90%;
  margin-top: 5px;
}

.modal-content.modalpop-forms .forgot a:hover {
  color: #00b9f5;
  text-decoration: none;
}

.modal-content.modalpop-forms .form-actions {
  border-top: 1px solid #bfbfbf;
  padding-top: 1em;
  margin-top: 2em;
}

.modal-content.modalpop-forms .new-account {
  padding-top: 1em;
}

.modal-content.modalpop-forms .new-account a {
  cursor: pointer;
}

.modal-content.modalpop-forms .register {
  min-width: 140px;
}

.modal-content.modalpop-forms .modalpop-footer {
  text-align: center;
  padding: 16px;
}

.modal-content.modalpop-forms .info-footer {
  color: #F04124;
  font-size: 90%;
  border-top: 1px solid #bfbfbf;
  padding: 10px 60px;
}

.modal-content.modalpop-forms .info-footer .info-footer-item {
  margin-bottom: 10px;
}

.modal-content.modalpop-forms .footer-link {
  margin-top: 6px;
}

@media (max-width: 768px) {
  .modal-content.modalpop-forms {
    border-radius: 4px;
  }
  .modal-content.modalpop-forms .modalpop-header {
    padding: 15px 0 0;
  }
  .modal-content.modalpop-forms .modalpop-body {
    padding: 10px 20px;
  }
}

.remove {
  outline: 0 !important;
  outline-offset: 0 !important;
  border: 1px solid rgba(111, 111, 111, 0.5);
  border-radius: 100%;
  width: 32px;
  height: 32px;
  z-index: 20;
  color: #222d32;
  background: #fff;
  position: absolute;
  top: 16px;
  right: 16px;
}

.remove:hover {
  color: #F04124;
  border-color: #F04124;
}

@media (max-width: 768px) {
  .remove {
    border: none;
    top: 5.33333px;
    right: 5.33333px;
  }
}

.cated {
  margin: 0;
  padding: 0;
}

.cated li {
  list-style: none;
  display: inline-block;
  margin-right: 10px;
}

.cated li .checkbox {
  margin: 0;
  font-weight: 400;
}

.msocial-card {
  text-align: center;
}

.msocial-card .modal-social {
  margin: 0;
  padding: 0;
}

.msocial-card .modal-social li {
  list-style: none;
  display: inline-block;
}

.msocial-card .modal-social li a {
  display: block;
  color: #fff;
  border-radius: 100%;
}

.msocial-card .modal-social li a .icons {
  display: block;
  width: 42px;
  line-height: 40px;
  height: 42px;
}

.msocial-card .modal-social .facebook a {
  background-color: #0096fb;
}

.msocial-card .modal-social .twitter a {
  background-color: #00b9f5;
}

.msocial-card .modal-social .google a {
  background-color: #F04124;
}

.msocial-card .line-or {
  position: relative;
  padding: 20px 0;
}

.msocial-card .line-or .text-or {
  font-size: 120%;
  text-transform: uppercase;
}

.msocial-card .line-or .left,
.msocial-card .line-or .right {
  position: absolute;
  height: 1px;
  width: 30%;
  background-color: #bfbfbf;
  top: 50%;
  margin-top: -1px;
}

.msocial-card .line-or .left {
  left: 0;
}

.msocial-card .line-or .right {
  right: 0;
}

.page-forms-section {
  border: 1px solid #bfbfbf;
  border-radius: 14px;
  padding: 0 3em;
  max-width: 480px;
  margin: 32px auto;
}

.listing-alphabet {
  text-align: center;
}

.listing-alphabet .alphabet-list {
  display: inline-block;
  margin: 0;
  padding: 0;
  margin-left: -1.5px;
  margin-right: -1.5px;
  margin-bottom: 32px;
}

.listing-alphabet .alphabet-list:before, .listing-alphabet .alphabet-list:after {
  display: table;
  content: " ";
}

.listing-alphabet .alphabet-list:after {
  clear: both;
}

.listing-alphabet .alphabet-list li {
  float: left;
  list-style: none;
}

.listing-alphabet .alphabet-list li a {
  -webkit-transition: 0.2s ease-out;
  -o-transition: 0.2s ease-out;
  transition: 0.2s ease-out;
  margin: 1.5px;
  display: block;
  font-weight: 600;
  font-family: "Poppins", Helvetica, Arial, sans-serif;
  width: 34px;
  height: 34px;
  line-height: 30.90909px;
  text-align: center;
  border-radius: 40px;
  border: 2px solid #fff;
}

.listing-alphabet .alphabet-list li:hover a, .listing-alphabet .alphabet-list li.active a {
  color: #0061c2;
  border-color: #0061c2;
}

.testimonial-container {
  overflow: hidden;
  position: relative;
  padding: 2em 0;
}

.testimonial-container .home-header {
  color: #fff;
}

.testimonial-container .home-header .sub-header {
  margin-top: 10px;
  font-weight: 200;
  font-size: 30%;
  text-transform: uppercase;
  color: #fff;
}

.testimonial-container .read-more {
  position: absolute;
  bottom: -20px;
  z-index: 2;
  background: #fff;
  right: 30px;
  text-transform: uppercase;
}

.testimonial-article {
  position: relative;
  overflow: hidden;
  background-color: #fff;
  padding: 20px 25px;
  padding-bottom: 40px;
  border: 2px solid #bfbfbf;
  border-radius: 14px;
}

.testimonial-article .testimonial-name {
  margin: 0;
  font-size: 240%;
  position: relative;
  padding-bottom: 15px;
}

.testimonial-article .testimonial-name:after {
  position: absolute;
  bottom: -2px;
  height: 2px;
  width: 60px;
  background: #00b9f5;
  content: "";
}

.testimonial-article .testimonial-designation {
  display: block;
  font-size: 50%;
  color: #607f8d;
}

.testimonial-article .testimonial-thumb {
  position: relative;
  border-radius: 100%;
  padding-left: 80px;
  min-height: 60px;
  margin-bottom: 20px;
}

.testimonial-article .testimonial-thumb img {
  display: block;
  width: 100%;
  position: absolute;
  left: 0;
  width: 60px;
  height: 60px;
  border-radius: 100%;
}

.testimonial-article .testimonial-text {
  position: relative;
  text-align: justify;
  line-height: 1.8;
}

.testimonial-article .tastimonial-contents {
  padding-top: 5px;
}

.testimonial-article .tastimonial-contents span {
  display: block;
}

.testimonial-article .services {
  display: block;
  font-size: 130%;
  font-weight: 700;
  text-transform: uppercase;
}

.testimonial-article .address {
  display: block;
  font-size: 90%;
  color: #b3b3b3;
}

.side-nav {
  box-shadow: 0 20px 30px 15px rgba(0, 0, 0, 0.1), 0 10px 40px 20px rgba(0, 0, 0, 0.08), 0 10px 15px -8px rgba(0, 0, 0, 0.1);
  position: fixed;
  max-width: 380px;
  width: 86%;
  left: 0;
  top: 0;
  margin: 0;
  height: 100%;
  height: calc(100% + 60px);
  height: -moz-calc(100%);
  padding-bottom: 60px;
  background-color: #fff;
  z-index: 1130;
  overflow-y: auto;
  will-change: transform;
  backface-visibility: hidden;
  transform: translateX(-105%);
}

.side-nav.right-aligned {
  right: 0;
  transform: translateX(105%);
  left: auto;
  transform: translateX(100%);
}

.side-nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
  margin-bottom: 20px;
}

.side-nav ul li {
  border: none;
  display: block;
  margin: 0;
  padding: 0;
}

.side-nav ul li a {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-top: 1px solid #d9d9d9;
  display: block;
  margin: 0;
  font-size: 110%;
  color: #222d32;
  padding: 10.66667px 16px;
  position: relative;
  text-decoration: none;
}

.side-nav ul li a .side-arrow {
  position: absolute;
  right: 15px;
  top: 50%;
  margin-top: -8px;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.side-nav ul li a .side-arrow:before {
  font-size: 70%;
  content: '\f054';
  font-weight: 600;
  font-family: "Font Awesome 5 Free";
}

.side-nav ul li a:after {
  content: '';
  left: 0;
  width: 3px;
  top: 0;
  bottom: 0;
  z-index: 2;
  position: absolute;
}

.side-nav ul li a:before {
  content: '\f054';
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  right: 15px;
  top: 50%;
  color: #8c8c8c;
  margin-top: -6px;
  z-index: 2;
  position: absolute;
  font-size: 70%;
}

.side-nav ul li a:hover, .side-nav ul li a:focus {
  background-color: rgba(0, 0, 0, 0.05);
  color: #0080ff;
}

.side-nav ul li a:hover:after, .side-nav ul li a:focus:after {
  background-color: #0080ff;
}

.side-nav ul li a:hover:before, .side-nav ul li a:focus:before {
  color: #0080ff;
}

.side-nav ul li.active > a {
  background-color: #f2f2f2;
}

.side-nav ul li.active > a:after {
  background-color: #0080ff;
}

.side-nav ul li.active > ul.sub-menu {
  display: block;
  padding: 0;
  background: rgba(0, 0, 0, 0.05);
}

.side-nav ul li.active > a > .side-arrow {
  filter: alpha(opacity=100);
  opacity: 1;
}

.side-nav ul li.active > a > .side-arrow:before {
  font-weight: 600;
  font-family: "Font Awesome 5 Free";
  content: '\f078';
}

.side-nav ul li ul.sub-menu {
  clear: both;
  list-style: none;
  margin: 0;
  padding: 0;
  display: none;
}

.side-nav ul li ul.sub-menu li {
  background: none;
  margin-bottom: 0;
  margin-left: 0;
  margin-right: 0;
  padding: 0;
}

.side-nav ul li ul.sub-menu li a {
  display: block;
  border: none;
  margin: 0;
  padding-bottom: 10px;
  padding-left: 30px !important;
  padding-right: 15px;
  padding-top: 10px;
  text-decoration: none;
}

.side-nav ul li ul.sub-menu li a .side-arrow {
  margin-right: 22px;
}

.side-nav ul li ul.sub-menu li a:after {
  display: none;
}

.side-nav ul li ul.sub-menu li.current > a {
  background: #0080ff;
  color: #fff;
}

.side-nav ul li ul.sub-menu li.dropdown-header {
  padding-left: 30px !important;
}

.side-nav ul li ul.sub-menu li.divider {
  height: 1px;
  margin: 9px 0;
  overflow: hidden;
  margin-left: 30px !important;
}

.side-nav ul li ul.sub-menu li ul.sub-menu {
  margin: 0;
}

.side-nav ul li ul.sub-menu li ul.sub-menu li a {
  padding-left: 45px !important;
}

.side-nav ul li ul.sub-menu li li ul.sub-menu li a {
  padding-left: 55px !important;
}

.side-nav .collapsible {
  margin: 0;
}

.side-nav .profile-card {
  display: block;
  padding: 32px 0;
  background-color: #0080ff;
  position: relative;
  min-height: 72px;
}

.side-nav .profile-card .avatar {
  width: 72px;
  height: 72px;
  position: absolute;
  left: 9px;
  top: 50%;
  margin-top: -36px;
  z-index: 2;
}

.side-nav .profile-card .avatar img {
  width: 100%;
  height: 100%;
  display: block;
  border-radius: 100%;
}

.side-nav .profile-card .avatar .count {
  position: absolute;
  width: 28.8px;
  height: 28.8px;
  background-color: #0080ff;
  text-align: center;
  font-weight: 500;
  font-size: 80%;
  line-height: 24px;
  right: -2px;
  top: -1.5px;
  border: 3px solid #0080ff;
  color: #fff;
  border-radius: 100%;
}

.side-nav .profile-card .avatar .count a {
  display: block;
  font-weight: 600;
  color: #fff;
}

.side-nav .profile-card .about {
  display: block;
  padding-left: 104px;
  color: #fff;
}

.side-nav .profile-card .about .user-name {
  line-height: 1;
  display: block;
  margin: 0;
  font-size: 120%;
}

.side-nav .profile-card .about .email-id {
  font-size: 90%;
}

.side-nav .profile-card .about .user-id {
  display: block;
  font-size: 85%;
  text-transform: uppercase;
}

.side-nav .profile-card .about .user-id a {
  color: inherit;
}

.side-nav .profile-card .about .email-id {
  display: block;
}

.side-nav .profile-card .about .email-id a {
  color: inherit;
}

.drag-target {
  height: 100%;
  width: 20px;
  position: fixed;
  top: 0;
  z-index: 1100;
}

.side-nav.fixed {
  left: 0;
  transform: translateX(0);
  position: fixed;
}

.side-nav.fixed.right-aligned {
  right: 0;
  left: auto;
}

@media (min-width: 768px) {
  .side-nav.fixed {
    transform: translateX(-105%);
  }
  .side-nav.fixed.right-aligned {
    transform: translateX(105%);
  }
  .side-nav .userView {
    padding: 16px 16px 0;
  }
}

.side-nav .collapsible-body > ul:not(.collapsible) > li.active,
.side-nav.fixed .collapsible-body > ul:not(.collapsible) > li.active {
  background-color: #0096fb;
}

.side-nav .collapsible-body > ul:not(.collapsible) > li.active a,
.side-nav.fixed .collapsible-body > ul:not(.collapsible) > li.active a {
  color: #5cb85c;
}

#sidenav-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1090;
  background-color: #000;
  opacity: 0.7 !important;
}

.siteLogo svg {
  width: 30em;
  margin: 0 auto;
  display: block;
}

.dashboard-container {
  padding: 24px 0;
  padding-bottom: 8px;
}

.site-padding {
  padding: 32px 0;
}

#slidenavbar-overlay {
  position: fixed;
  top: 0;
  height: 120vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 997;
  will-change: opacity;
}

.pages,
.page {
  position: relative;
}

.pages.with-padding,
.page.with-padding {
  padding: 16px 0;
}

.pages.account-artical,
.page.account-artical {
  padding: 32px 0;
}

.pages.detail-artical,
.page.detail-artical {
  padding: 32px 0;
  padding-bottom: 0;
}

.pages.with-large .heading-page,
.page.with-large .heading-page {
  font-size: 140%;
}

.pages.with-large .sub-heading,
.page.with-large .sub-heading {
  font-weight: 600;
}

.pages.with-large .content-inner,
.page.with-large .content-inner {
  padding-left: 32px;
  font-size: 130%;
  font-weight: 300;
}

@media (max-width: 768px) {
  .pages.account-artical,
  .page.account-artical {
    padding: 16px 0;
  }
  .pages.with-large .content-inner,
  .page.with-large .content-inner {
    padding-left: 0;
    font-size: 100%;
    font-weight: 400;
  }
}

.newsletter-section.mini {
  position: relative;
  padding: 1.5em 0;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.03);
}

.newsletter-section.mini .maler-form {
  position: relative;
  z-index: 3;
  float: right;
  width: 45%;
  padding-top: 1em;
}

.newsletter-section.mini .header-sec {
  position: relative;
  z-index: 2;
  float: left;
  width: 55%;
}

.newsletter-section.mini .header-sec .header {
  margin: 0;
  padding: 0;
  font-size: 260%;
  margin-bottom: 0.2em;
  font-weight: 100;
}

.newsletter-section.mini .header-sec .sub-header {
  margin: 0;
  padding: 0;
}

@media (max-width: 768px) {
  .newsletter-section.mini:after, .newsletter-section.mini:before {
    display: none;
  }
  .newsletter-section.mini .maler-form,
  .newsletter-section.mini .header-sec {
    float: none;
    text-align: center;
    width: auto;
  }
  .newsletter-section.mini .header-sec {
    margin-top: 1.5em;
  }
}

.newsletter-section.full {
  border: 1px solid #bfbfbf;
  padding: 32px 0;
}

.newsletter-section.full .header-sec {
  text-align: center;
  position: relative;
  z-index: 2;
}

.newsletter-section.full .header-sec .header {
  margin: 0;
  padding: 0;
  font-size: 260%;
  margin-bottom: 0.2em;
  font-weight: 100;
}

.newsletter-section.full .header-sec .sub-header {
  margin: 0;
  padding: 0;
}

.newsletter-section.full .maler-form {
  max-width: 480px;
  margin: 16px auto;
}

.pd-right-left0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.pd-right0 {
  padding-right: 0 !important;
}

.pd-left0 {
  padding-left: 0 !important;
}

.package-highlights {
  padding: 20px 0;
}

.package-highlights .heading {
  font-size: 200%;
  margin-bottom: 15px;
}

.about-us-section {
  margin-top: 16px;
  padding: 32px 0;
  background-color: #e4e4e4;
  color: inverse-text-color(#0080ff);
}

.about-us-section .heading-page {
  margin-bottom: 16px;
}

.about-us-section .icons {
  width: 64px;
  height: 64px;
  margin: 20px auto;
}

.about-us-section .icons img {
  display: block;
  width: 100%;
}

.about-us-section .iconblock-card {
  text-align: center;
  -webkit-transition: background 0.3s ease-out;
  -o-transition: background 0.3s ease-out;
  transition: background 0.3s ease-out;
  /*margin-left: -10px;
        margin-right: -10px;
        border: 1px solid $brand-default;
        border-left: 0;
        border-top: 0;*/
  border-radius: 4px;
  padding: 1em 1em;
}

.about-us-section .iconblock-card .header {
  font-weight: 500;
  font-size: 120%;
}

.about-us-section .iconblock-card .sub-header {
  font-size: 90%;
}

.about-us-section .iconblock-card:hover {
  background-color: #0080ff;
}

@media (max-width: 768px) {
  .about-us-section {
    padding: 1em 0;
  }
}

.flight-container {
  margin: 16px auto;
}

.flight-container .heading-page {
  margin-bottom: 8px;
}

.flight-container .heading-sec {
  font-size: 160%;
  font-weight: 600;
  margin-bottom: 5.33333px;
}

.flight-history .heading {
  font-size: 120%;
  margin-bottom: 5px;
}

.flight-history ul.history-list {
  margin: 0;
  padding: 0;
}

.flight-history ul.history-list li {
  border-top: 1px dashed #bfbfbf;
  list-style: none;
  margin-bottom: 5.33333px;
}

.flight-history ul.history-list li:before, .flight-history ul.history-list li:after {
  display: table;
  content: " ";
}

.flight-history ul.history-list li:after {
  clear: both;
}

.flight-history .flight-history-list {
  position: relative;
}

.flight-history .flight-history-list .flight-iconn {
  display: inline-block;
  vertical-align: middle;
  padding: 0 8px;
  text-align: center;
  line-height: 1.6;
  font-size: 110%;
  font-weight: 600;
}

.flight-history .flight-history-list .flight-iconn img {
  display: block;
  width: 100%;
  height: 100%;
}

.flight-history .flight-history-list .history-icons {
  position: absolute;
  top: 50%;
  left: 0;
  margin-top: -10.66667px;
  z-index: 2;
}

.flight-history .flight-history-list .history-content {
  padding: 8px 96px;
  font-size: 130%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.flight-history .flight-history-list .history-time {
  position: absolute;
  top: 50%;
  right: 0;
  margin-top: -13.33333px;
  z-index: 2;
  width: 90px;
}

@media (max-width: 768px) {
  .flight-history ul li {
    margin: 0;
  }
  .flight-history .history-icons {
    padding: 12px 0;
  }
  .flight-history .history-content {
    padding: 12px 0;
    width: 63%;
  }
  .flight-history .history-time {
    padding: 3px 0;
    font-size: 85%;
    width: 100%;
  }
}

.ng-hide,
.nodisplay {
  display: none;
}

.ng-hide.active,
.nodisplay.active {
  display: block;
}

.no-margin {
  margin: 0 !important;
}

.alert-dismissible .close {
  right: 0;
}

.parallax-container {
  position: relative;
  overflow: hidden;
  height: 520px;
}

@media (max-width: 768px) {
  .parallax-container {
    height: 300px;
  }
}

@media (max-width: 768px) {
  .parallax-container {
    height: 200px;
  }
}

.parallax-container.info-parallax {
  height: 200px;
  background: #0080ff;
}

.parallax-container.compay-talk-widget {
  height: 450px;
  padding: 40px 0;
}

.parallax-container.serch-parallax {
  height: 240px;
}

@media (max-width: 768px) {
  .parallax-container.serch-parallax {
    height: 140px;
  }
}

.parallax-container.contact-parallax {
  height: 300px;
}

.parallax-container.contact-parallax:before {
  content: '';
  position: absolute;
  z-index: 2;
  filter: alpha(opacity=40);
  opacity: 0.4;
}

@media (max-width: 768px) {
  .parallax-container.contact-parallax {
    height: 160px;
  }
}

.parallax-container.page-parallax {
  height: 120px;
}

.parallax-container.page-parallax:after {
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
  content: '';
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -2;
}

.parallax-container.page-parallax .page-header {
  margin: 0;
  display: table;
  width: 100%;
  font-size: 180%;
  padding: 0;
}

.parallax-container.page-parallax .base {
  height: 120px;
  display: table-cell;
  vertical-align: middle;
}

@media (max-width: 768px) {
  .parallax-container.page-parallax {
    height: 90px;
  }
  .parallax-container.page-parallax .page-header {
    font-size: 140%;
  }
  .parallax-container.page-parallax .base {
    height: 90px;
    display: table-cell;
    vertical-align: middle;
  }
}

.parallax-container.addons-parallax {
  height: 60px;
}

.parallax {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -5;
}

.parallax img {
  display: block;
  position: absolute;
  left: 50%;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  transform: translateX(-50%);
}

.modal-content {
  border: 0;
  box-shadow: 0 20px 30px 15px rgba(0, 0, 0, 0.1), 0 10px 40px 20px rgba(0, 0, 0, 0.08), 0 10px 15px -8px rgba(0, 0, 0, 0.1);
  border-radius: 18px;
}

.modal-jumbotron {
  padding: 16px 6px;
  margin: 0;
}

.modal-jumbotron h1 {
  font-size: 300%;
}

.modal-base {
  width: 450px;
}

@media (max-width: 768px) {
  .modal-base {
    width: auto;
  }
}

.modal-dialog.dialog-full {
  margin: 0 auto;
  width: auto;
  border-radius: 0;
}

.modal-dialog.dialog-full .modalpop-forms {
  background-color: #fff;
  border-radius: 0;
}

.modal-footer:before, .modal-footer:after {
  display: table;
  content: " ";
}

.modal-footer:after {
  clear: both;
}

.social-menu {
  /* a[href*="tumblr.com"]:before { content: "\f173"; }*/
  /*a[href*="vimeo.com"]:before { content: "\f27d"; }
    a[href*="wordpress.com"]:before,
    a[href*="wordpress.org"]:before { content: "\f19a"; }*/
  /* a[href*="twitch.tv"]:before { content: "\f1e8"; }*/
}

.social-menu a[href*="codepen.io"]:hover, .social-menu a[href*="codepen.io"]:focus {
  color: #0096fb;
}

.social-menu a[href*="digg.com"]:hover, .social-menu a[href*="digg.com"]:focus {
  color: #00b9f5;
}

.social-menu a[href*="dribbble.com"]:hover, .social-menu a[href*="dribbble.com"]:focus,
.social-menu a[href*="flickr.com"]:hover,
.social-menu a[href*="flickr.com"]:focus {
  color: #C73B6F;
}

.social-menu a[href*="dropbox.com"]:hover, .social-menu a[href*="dropbox.com"]:focus {
  color: #3d9ae8;
}

.social-menu a[href*="facebook.com"]:hover, .social-menu a[href*="facebook.com"]:focus {
  color: #3b5998;
}

.social-menu a[href*="foursquare.com"]:hover, .social-menu a[href*="foursquare.com"]:focus {
  color: #f7931e;
}

.social-menu a[href*="plus.google.com"]:hover, .social-menu a[href*="plus.google.com"]:focus {
  color: #F04124;
}

.social-menu a[href*="github.com"]:hover, .social-menu a[href*="github.com"]:focus {
  color: #00b9f5;
}

.social-menu a[href*="instagram.com"]:hover, .social-menu a[href*="instagram.com"]:focus {
  color: #8a3ab9;
}

.social-menu a[href*="linkedin.com"]:hover, .social-menu a[href*="linkedin.com"]:focus {
  color: #4875B4;
}

.social-menu a[href*="pinterest.com"]:hover, .social-menu a[href*="pinterest.com"]:focus {
  color: #F04124;
}

.social-menu a[href*="reddit.com"]:hover, .social-menu a[href*="reddit.com"]:focus {
  color: #C73B6F;
}

.social-menu a[href*="skype.com"]:hover, .social-menu a[href*="skype.com"]:focus {
  color: #00b9f5;
}

.social-menu a[href*="stumbleupon.com"]:hover, .social-menu a[href*="stumbleupon.com"]:focus {
  color: #f74425;
}

.social-menu a[href*="twitter.com"]:hover, .social-menu a[href*="twitter.com"]:focus {
  color: #00b9f5;
}

.social-menu a[href*="youtube.com"]:hover, .social-menu a[href*="youtube.com"]:focus {
  color: #F04124;
}

.social-menu a[href^="mailto:"]:hover, .social-menu a[href^="mailto:"]:focus {
  color: #0096fb;
}

.social-menu a[href*="spotify.com"]:hover, .social-menu a[href*="spotify.com"]:focus {
  color: #0096fb;
}

.social-menu a[href$="/feed/"]:hover, .social-menu a[href$="/feed/"]:focus {
  color: #f7931e;
}

.social-menu a:before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: 'Font Awesome 5 Brands';
  font-size: 150%;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  speak: none;
  text-decoration: inherit;
  vertical-align: middle;
}

.social-menu a[href*="codepen.io"]:before {
  content: "\f1cb";
}

.social-menu a[href*="digg.com"]:before {
  content: "\f1a6";
}

.social-menu a[href*="dribbble.com"]:before {
  content: "\f17d";
}

.social-menu a[href*="dropbox.com"]:before {
  content: "\f16b";
}

.social-menu a[href*="facebook.com"]:before {
  content: "\f39e";
}

.social-menu a[href*="flickr.com"]:before {
  content: "\f16e";
}

.social-menu a[href*="foursquare.com"]:before {
  content: "\f180";
}

.social-menu a[href*="plus.google.com"]:before {
  content: "\f0d5";
}

.social-menu a[href*="github.com"]:before {
  content: "\f09b";
}

.social-menu a[href*="instagram.com"]:before {
  content: "\f16d";
}

.social-menu a[href*="linkedin.com"]:before {
  content: "\f0e1";
}

.social-menu a[href*="pinterest.com"]:before {
  content: "\f231";
}

.social-menu a[href*="reddit.com"]:before {
  content: "\f281";
}

.social-menu a[href*="skype.com"]:before {
  content: "\f17e";
}

.social-menu a[href*="stumbleupon.com"]:before {
  content: "\f1a4";
}

.social-menu a[href*="twitter.com"]:before {
  content: "\f099";
}

.social-menu a[href*="youtube.com"]:before {
  content: "\f167";
}

.social-menu a[href^="mailto:"]:before {
  content: "\f0e0";
}

.social-menu a[href*="spotify.com"]:before {
  content: "\f1bc";
}

.social-menu a[href$="/feed/"]:before {
  content: "\f09e";
}

.move-top {
  position: fixed;
  right: 35px;
  bottom: 17.5px;
  padding: 0;
  width: 45px;
  height: 45px;
  line-height: 39.55px;
  text-align: center;
  font-size: 140%;
  border-radius: 50%;
  z-index: 999;
  background-color: #0080ff;
  color: #fff;
  border: 2px solid #0080ff;
}

.move-top:hover {
  background-color: #F04124;
  color: #fff;
  border-color: #F04124;
}

@media (max-width: 768px) {
  .move-top {
    bottom: 56px;
    right: 17.5px;
  }
  .move-top.bottom {
    bottom: 17.5px;
  }
}

.table-address {
  margin-bottom: 20px;
  width: 100%;
}

.table-address > thead > tr > th,
.table-address > thead > tr > td,
.table-address > tbody > tr > th,
.table-address > tbody > tr > td,
.table-address > tfoot > tr > th,
.table-address > tfoot > tr > td {
  vertical-align: top;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.9);
  padding: 2.5px;
}

.table-address > thead > tr > th a,
.table-address > thead > tr > td a,
.table-address > tbody > tr > th a,
.table-address > tbody > tr > td a,
.table-address > tfoot > tr > th a,
.table-address > tfoot > tr > td a {
  color: rgba(255, 255, 255, 0.9);
}

.table-address > thead > tr > th a:hover,
.table-address > thead > tr > td a:hover,
.table-address > tbody > tr > th a:hover,
.table-address > tbody > tr > td a:hover,
.table-address > tfoot > tr > th a:hover,
.table-address > tfoot > tr > td a:hover {
  color: #fff;
}

.table-activities {
  margin-bottom: 20px;
  width: 100%;
}

.table-activities > tbody > tr > th,
.table-activities > tbody > tr > td {
  vertical-align: top;
  padding: 2.5px;
}

.table-activities > tbody > tr > th .base-price.large,
.table-activities > tbody > tr > td .base-price.large {
  font-size: 150%;
}

.table-activities > tbody > tr th {
  font-weight: 500;
  font-size: 90%;
}

.table-activities > thead > tr > th,
.table-activities > thead > tr > td {
  vertical-align: top;
  font-weight: 600;
  padding: 2.5px;
}

.page-footer {
  position: relative;
  background: rgba(0, 0, 0, 0.9);
}

@media (max-width: 768px) {
  .page-footer {
    padding-top: 20px;
  }
}

.page-footer a {
  -webkit-transition: 0.2s ease-out;
  -o-transition: 0.2s ease-out;
  transition: 0.2s ease-out;
}

.page-footer .header {
  font-size: 120%;
  padding: 0;
  margin: 0;
  margin-top: 32px;
  margin-bottom: 16px;
  font-weight: 500;
  color: white;
}

@media (max-width: 768px) {
  .page-footer .header {
    margin-bottom: 0;
    margin-top: 0.5em;
  }
}

.page-footer .footer-nav {
  margin-bottom: 20px;
}

.page-footer .footer-nav ul {
  margin: 0;
  padding: 0;
}

.page-footer .footer-nav ul li {
  list-style: none;
  display: block;
}

.page-footer .footer-nav ul li a {
  display: inline-block;
  color: rgba(255, 255, 255, 0.7);
  padding: 0.22em 0;
}

.page-footer .footer-nav ul li a:hover {
  color: #fff;
  text-decoration: none;
}

@media (max-width: 768px) {
  .page-footer .footer-nav ul li {
    display: inline-block;
  }
}

.page-footer .footer-cards {
  margin-bottom: 25px;
}

.page-footer .footer-cards:before, .page-footer .footer-cards:after {
  display: table;
  content: " ";
}

.page-footer .footer-cards:after {
  clear: both;
}

.page-footer .footer-cards ul {
  margin: 0;
  padding: 0;
}

.page-footer .footer-cards ul li {
  line-height: 1;
  list-style: none;
  display: block;
  float: left;
  margin-right: 5px;
  color: rgba(255, 255, 255, 0.7);
}

.page-footer .footer-cards ul li span {
  font-size: 240%;
}

@media (max-width: 768px) {
  .page-footer .footer-cards ul li {
    display: inline-block;
  }
}

.page-footer .soical-nav {
  margin-bottom: 2em;
}

.page-footer .soical-nav .header {
  margin-top: 0;
}

.page-footer .soical-nav ul {
  margin: 0;
  padding: 0;
}

.page-footer .soical-nav ul li {
  list-style: none;
  display: inline-block;
}

.page-footer .soical-nav ul li span {
  display: none;
}

.page-footer .soical-nav ul li a {
  display: block;
  color: rgba(255, 255, 255, 0.7);
  padding: 0;
  text-align: center;
  font-size: 90%;
  width: 38px;
  line-height: 38px;
  text-decoration: none;
  height: 38px;
  margin-bottom: 5px;
}

.page-footer .soical-nav ul li a:hover {
  color: #fff;
  text-decoration: none;
}

.page-footer .soical-nav ul li:last-child a {
  border: 0;
}

@media (max-width: 768px) {
  .page-footer .soical-nav {
    margin-bottom: 0.5em;
  }
}

.page-footer .footer-copyright {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  padding: 20px 0;
}

.page-footer .footer-copyright .copy-text {
  text-align: center;
  font-size: 90%;
  color: rgba(255, 255, 255, 0.7);
}

.page-footer .footer-copyright .copy-text a {
  color: rgba(255, 255, 255, 0.7);
}

.page-footer .footer-copyright .copy-text a:hover {
  color: #fff;
}

.panel-default.panelinfo {
  border: 0;
  box-shadow: none;
  margin: 0;
}

.panel-default .panelinfo-heading {
  font-weight: 500;
  position: relative;
  padding: 0;
  font-size: 100%;
  border-radius: 4px;
  border-top-left-radius: 4px;
  color: #000;
}

.panel-default .panelinfo-heading .link-button {
  position: absolute;
  top: 3px;
  right: 0;
  z-index: 2;
}

.panel-default .panelinfo-heading a.links {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  position: relative;
  line-height: 1;
  font-weight: 600;
  padding: 6px;
  padding-left: 20.8px;
  color: #0080ff;
  display: block;
  border-radius: 4px;
  text-decoration: none;
}

.panel-default .panelinfo-heading a.links:after {
  font-family: "Font Awesome 5 Free";
  content: '\f0fe';
  font-weight: 700;
  font-size: 100%;
  color: #0080ff;
  position: absolute;
  left: 0;
  top: 6px;
  z-index: 1;
}

.panel-default .panelinfo-heading a.links.collapsed {
  color: #000;
}

.panel-default .panelinfo-heading a.links.collapsed:after {
  content: '\f146';
  color: #6f6f6f;
  font-weight: 700;
  font-family: "Font Awesome 5 Free";
}

.panel-default .panelinfo-heading a.links .besic-info {
  margin-left: 14%;
}

@media (max-width: 768px) {
  .panel-default .panelinfo-heading a.links {
    font-size: 90%;
  }
}

.open-account-panel {
  padding: 32px 0;
  position: relative;
}

.open-account-panel .open-account-bg {
  position: absolute;
  left: 0;
  right: 0;
  z-index: -1;
  top: 0;
}

.open-account-panel .open-account-bg img {
  display: block;
  width: 100%;
}

.review-metadata {
  position: relative;
  padding-right: 15px;
  margin-bottom: 15px;
}

.rate-count {
  display: inline-block;
  position: relative;
  vertical-align: middle;
}

.rate-count:before, .rate-count:after {
  display: table;
  content: " ";
}

.rate-count:after {
  clear: both;
}

.rate-count .text {
  float: left;
}

.stars {
  font-family: "Font Awesome 5 Free";
  position: relative;
  vertical-align: top;
  color: #f7931e;
  display: inline-block;
  margin-right: 5px;
  float: left;
}

.stars:after {
  font-weight: 400;
}

.stars:before {
  font-weight: 900;
}

.stars.star0:after {
  content: '\f005\f005\f005\f005\f005';
}

.stars.star1:before {
  content: '\f005';
}

.stars.star1:after {
  content: '\f005\f005\f005\f005';
}

.stars.star2:before {
  content: '\f005\f005';
}

.stars.star2:after {
  content: '\f005\f005\f005';
}

.stars.star3:before {
  content: '\f005\f005\f005';
}

.stars.star3:after {
  content: '\f005\f005';
}

.stars.star4:before {
  content: '\f005\f005\f005\f005';
}

.stars.star4:after {
  content: '\f005';
}

.stars.star5:before {
  content: '\f005\f005\f005\f005\f005';
}

.star-circle {
  font-family: "Font Awesome 5 Free";
  position: relative;
  vertical-align: top;
  display: inline-block;
  margin-right: 5px;
}

.star-circle:after {
  font-weight: 400;
}

.star-circle:before {
  font-weight: 900;
}

.star-circle.star0:after {
  content: '\f111\f111\f111\f111\f111';
}

.star-circle.star1:before {
  content: '\f111';
}

.star-circle.star1:after {
  content: '\f111\f111\f111\f111';
}

.star-circle.star2:before {
  content: '\f111\f111';
}

.star-circle.star2:after {
  content: '\f111\f111\f111';
}

.star-circle.star3:before {
  content: '\f111\f111\f111';
}

.star-circle.star3:after {
  content: '\f111\f111';
}

.star-circle.star4:before {
  content: '\f111\f111\f111\f111';
}

.star-circle.star4:after {
  content: '\f111';
}

.star-circle.star5:before {
  content: '\f111\f111\f111\f111\f111';
}

.hotel-search-card-contant .hotel-search-header .tittle {
  font-size: 140%;
  font-weight: 600;
  display: inline-block;
}

.hotel-search-card-contant .hotel-search-header .sub-tittle {
  display: block;
  font-size: 80%;
  color: #0080ff;
  font-weight: 600;
}

.hotel-search-card-contant .hotel-search-header .sub-text {
  display: block;
  font-size: 90%;
  font-weight: 600;
}

.hotel-search-card-contant .hotel-search-header .sub-text .text {
  margin-right: 16px;
  display: inline-block;
  color: #6f6f6f;
}

.hotel-search-card-contant .hotel-search-header .sub-text .bold {
  margin-right: 6px;
  color: #0080ff;
}

.hotel-activity .list-activity {
  margin: 0;
  padding: 0;
  display: inline-block;
  margin-left: 0;
  font-size: 80%;
}

.hotel-activity .list-activity.list-footer {
  margin-top: 16px;
}

.hotel-activity .list-activity.large-text {
  font-size: 100%;
  margin-top: 16px;
}

.hotel-activity .list-activity li {
  float: left;
  list-style: none;
  line-height: 1;
  font-weight: 600;
  position: relative;
  padding: 0 16px;
  margin: 1.5px 0;
}

.hotel-activity .list-activity li:after {
  content: '';
  position: absolute;
  background-color: #006cd6;
  width: 6px;
  height: 6px;
  left: 0;
  top: 50%;
  margin-top: -3px;
  border-radius: 30px;
}

.hotel-activity .list-activity li:last-child {
  border: 0;
  padding-right: 0;
}

.hotel-search-rout {
  padding-bottom: 5px;
}

.hotel-search-rout:before, .hotel-search-rout:after {
  display: table;
  content: " ";
}

.hotel-search-rout:after {
  clear: both;
}

.hotel-search-rout .rout-list {
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.hotel-search-rout .rout-list li {
  font-size: 85%;
  list-style: none;
  float: left;
  position: relative;
  padding: 0 30px;
  color: #6f6f6f;
}

.hotel-search-rout .rout-list li .text {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 12px;
}

.hotel-search-rout .rout-list li:before {
  top: 50%;
  margin-top: -1px;
  position: absolute;
  left: -12px;
  width: 34px;
  height: 2px;
  background-color: #bfbfbf;
  content: '';
}

.hotel-search-rout .rout-list li:after {
  top: 50%;
  margin-top: -5px;
  position: absolute;
  left: 0;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background-color: #8c8c8c;
  content: '';
}

.rate {
  position: relative;
}

.link-outer {
  padding-top: 6px;
}

.link-outer.with-package {
  margin-top: 16px;
}

.link-outer.with-package .link-button {
  margin: 6px auto;
}

.link-outer .link-text {
  padding: 10px 0;
}

.link-outer .text-canc {
  margin: 0;
  font-size: 82%;
  text-align: center;
  font-weight: 600;
}

.hotel-search-mail {
  position: absolute;
  top: 3px;
  right: -8px;
  z-index: 3;
  text-transform: uppercase;
}

.hotel-search-mail .checkbox {
  font-size: 80%;
  font-weight: 600;
}

.hotel-search-price .package-price {
  font-size: 140%;
}

.hotel-search-price .tittle {
  color: #F04124;
  display: block;
  font-size: 12px;
  font-weight: 600;
}

.hotel-search-price .hotel-search-id {
  font-size: 12px;
  display: block;
  color: #6f6f6f;
}

.hotel-search-price .about {
  font-size: 80%;
  color: gray;
}

.hotel-search-price .rate {
  margin-top: 6px;
}

.amenities-info {
  position: relative;
}

.amenities-info .tittle {
  color: gray;
  font-weight: 600;
}

.amenities-info .hotelcard-amenities {
  margin: 0;
  padding: 0;
  margin-left: -5px;
  margin-right: -5px;
}

.amenities-info .hotelcard-amenities:before, .amenities-info .hotelcard-amenities:after {
  display: table;
  content: " ";
}

.amenities-info .hotelcard-amenities:after {
  clear: both;
}

.amenities-info .hotelcard-amenities li {
  margin: 2px 3px;
  list-style: none;
  float: left;
}

.amenities-info .hotel-search-mail {
  top: 6px;
  right: 16px;
}

.amenities {
  position: relative;
  border: 1px solid #bfbfbf;
  border-radius: 4px;
  line-height: 21.33333px;
}

.amenities .icon {
  line-height: 21.33333px;
  width: 21.33333px;
  height: 21.33333px;
  text-align: center;
}

.amenities .tooltip {
  min-width: 100px;
}

.amenities.active {
  color: #bfbfbf;
}

.amenities.active .icon {
  color: #a6a6a6;
}

.amenities .header {
  font-size: 11px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.hotel-search-card-price {
  position: relative;
  padding: 0px;
  padding-right: 6px;
}

.hotel-search-card-price.with-package {
  padding: 16px 0;
  padding-right: 6px;
}

.hotel-search-card-price.with-package .link-outer {
  margin-top: 16px;
}

.hotel-search-card-price.with-package .link-button {
  margin: 6px auto;
}

@media (max-width: 768px) {
  .hotel-search-card-price {
    padding-right: 0;
  }
}

.hotel-search-card-artical,
.package-search-card-artical {
  -webkit-transition: 0.2s ease-in-out all;
  -o-transition: 0.2s ease-in-out all;
  transition: 0.2s ease-in-out all;
  border: 1px solid #bfbfbf;
  border-radius: 4px;
  margin-bottom: 10px;
  padding: 6px;
}

.hotel-search-card-artical .card-thumbnail .card-img,
.package-search-card-artical .card-thumbnail .card-img {
  display: block;
}

.hotel-search-card-artical .card-thumbnail img,
.package-search-card-artical .card-thumbnail img {
  display: block;
  width: 100%;
}

.hotel-search-card-artical .hotel-search-details,
.package-search-card-artical .hotel-search-details {
  padding: 8px 0;
}

.hotel-search-card-artical .hotel-search-details.full-width,
.package-search-card-artical .hotel-search-details.full-width {
  padding: 6px;
  padding-bottom: 0;
}

.hotel-search-card-artical .hotel-search-details .content,
.package-search-card-artical .hotel-search-details .content {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  height: 58px;
  margin-bottom: 6px;
}

.hotel-search-card-artical .hotel-search-details p,
.package-search-card-artical .hotel-search-details p {
  margin: 0;
}

.hotel-search-card-artical .hotel-search-details .header,
.package-search-card-artical .hotel-search-details .header {
  margin: 0;
  padding: 0;
  font-size: 90%;
  font-weight: 600;
}

.hotel-search-card-artical:hover,
.package-search-card-artical:hover {
  border-color: #b3d9ff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 1px 5px 0 rgba(0, 0, 0, 0.08), 0 3px 1px -2px rgba(0, 0, 0, 0.08);
}

.hotel-search-card-artical.grid-view .hotel-search-card-price,
.package-search-card-artical.grid-view .hotel-search-card-price {
  padding: 0px;
  padding-top: 14px;
  padding-right: 16px;
}

@media (max-width: 768px) {
  .hotel-search-card-artical .card-thumbnail,
  .package-search-card-artical .card-thumbnail {
    margin-bottom: 6px;
  }
  .hotel-search-card-artical .hotel-search-card-contant,
  .package-search-card-artical .hotel-search-card-contant {
    margin-bottom: 6px;
  }
}

.pkg-main-slider .owl-thumbs {
  display: block;
}

.pkg-main-slider .owl-thumbs:before, .pkg-main-slider .owl-thumbs:after {
  display: table;
  content: " ";
}

.pkg-main-slider .owl-thumbs:after {
  clear: both;
}

.pkg-main-slider .owl-thumb-item {
  width: 64px;
  border: 0;
  float: left;
  padding: 0;
  margin: 0;
  outline: 0 !important;
  outline-offset: 0 !important;
}

.pkg-main-slider .owl-thumb-item img {
  display: block;
  width: 100%;
}

.hotel-serch-bg {
  position: relative;
  background-color: #0080ff;
}

.hotel-serch-bg .figure {
  overflow: hidden;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  left: 0;
  height: 330px;
}

.hotel-serch-bg .figure img {
  display: block;
  width: 100%;
}

.hotel-serch-bg .figure:after {
  position: absolute;
  content: '';
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
}

.hotel-serch-section {
  position: relative;
  z-index: 500;
  padding: 64px 0;
}

.hotel-serch-section .heading-page {
  color: #fff;
  margin: 0;
}

.hotel-serch-section .heading-page .heading,
.hotel-serch-section .heading-page .sub-heading {
  color: #fff;
}

.hotel-serch-section .btn-serch {
  text-align: right;
}

.hotel-serch-section .btn-serch:before, .hotel-serch-section .btn-serch:after {
  display: table;
  content: " ";
}

.hotel-serch-section .btn-serch:after {
  clear: both;
}

.hotel-serch-section .btn-serch .btn-min-width {
  min-width: 180px;
}

@media (max-width: 768px) {
  .hotel-serch-section .btn-serch .btn-min-width {
    min-width: initial;
  }
}

.hotel-serch-section .btn-serch.with-border {
  padding-top: 6px;
  border-top: 1px solid #bfbfbf;
}

@media (max-width: 768px) {
  .hotel-serch-section {
    padding-bottom: 16px;
  }
  .hotel-serch-section .btn-serch {
    text-align: center;
  }
  .hotel-serch-section .btn-serch .btn-min-width {
    min-width: 180px;
  }
}

@media (max-width: 768px) and (max-width: 768px) {
  .hotel-serch-section .btn-serch .btn-min-width {
    min-width: initial;
  }
}

.cart-footer-action {
  padding: 16px;
  padding-top: 0;
}

.serch-artical-tabs {
  max-width: 720px;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 4px;
  padding: 16px 24px;
}

.hotel-serch-artical {
  padding: 0;
  padding-top: 16px;
}

.hotel-serch-artical .tab-content {
  padding: 0;
}

.swal-footer {
  text-align: center !important;
}

.booking-modal-right {
  position: relative;
  z-index: 2;
  min-height: 400px;
}

.booking-modal-right .heading-modal .heading {
  margin: 0;
  padding: 0;
  margin-bottom: 10px;
  font-size: 160%;
}

.booking-modal-right .heading-modal .sub-heading {
  margin: 0;
  margin-bottom: 10px;
  font-size: 90%;
  color: #6f6f6f;
}

.booking-modal-traveler {
  padding: 4px 0;
}

.booking-modal-traveler:before, .booking-modal-traveler:after {
  display: table;
  content: " ";
}

.booking-modal-traveler:after {
  clear: both;
}

.booking-modal-traveler .header {
  float: left;
  font-weight: 600;
  color: #0080ff;
}

.booking-modal-traveler .modal-traveler {
  float: right;
  min-width: 120px;
}

.booking-modal-room-artical,
.booking-modal-flight-artical {
  position: relative;
  padding-bottom: 0;
}

.booking-modal-room-artical .form-group,
.booking-modal-flight-artical .form-group {
  margin-bottom: 6px;
}

.booking-modal-room-artical .header,
.booking-modal-flight-artical .header {
  color: #F04124;
  font-weight: 600;
}

.close-traveler {
  position: absolute;
  font-size: 140%;
  text-align: center;
  padding: 0;
  border: 2px solid #F04124;
  border-radius: 40px;
  right: -8px;
  top: 0;
  background-color: #fff;
  color: #F04124;
  margin-top: 28.8px;
  width: 20.58824px;
  height: 20.58824px;
  line-height: 11.66667px;
  z-index: 1100;
  outline: 0 !important;
  outline-offset: 0 !important;
}

.close-traveler:hover {
  border-color: #F04124;
  color: #F04124;
}

.btn-serch-toggle {
  display: block;
  text-align: center;
}

.btn-serch-toggle .text {
  font-weight: 600;
  font-size: 80%;
}

.nav-search-tabs {
  background-color: #fff;
  padding-bottom: 16px;
  border-bottom: 2px solid #bfbfbf;
}

.nav-search-tabs .nav-tabs > li {
  float: left;
  margin-bottom: 0;
}

.nav-search-tabs .nav-tabs > li > a {
  -webkit-transition: 0.2s ease-out;
  -o-transition: 0.2s ease-out;
  transition: 0.2s ease-out;
  margin-right: 6px;
  border-radius: 4px;
  position: relative;
  font-weight: 500;
  font-size: 110%;
  color: #0080ff;
  text-transform: uppercase;
  border: 2px solid #bfbfbf;
  padding: 6px 20px;
  padding-left: 32px;
}

.nav-search-tabs .nav-tabs > li > a:after {
  content: '';
  position: absolute;
  background-color: #006cd6;
  width: 9px;
  height: 9px;
  left: 12px;
  top: 50%;
  margin-top: -5px;
  border-radius: 30px;
}

.nav-search-tabs .nav-tabs > li > a:hover {
  color: #fff;
  border-color: #006cd6;
  background-color: #006cd6;
}

.nav-search-tabs .nav-tabs > li > a:hover:after {
  background-color: rgba(255, 255, 255, 0.5);
}

@media (max-width: 768px) {
  .nav-search-tabs .nav-tabs > li > a:hover {
    background-color: transparent;
  }
}

@media (max-width: 768px) {
  .nav-search-tabs .nav-tabs > li > a {
    padding: 6px;
  }
  .nav-search-tabs .nav-tabs > li > a:after {
    display: none;
  }
}

.nav-search-tabs .nav-tabs > li.active > a, .nav-search-tabs .nav-tabs > li.active > a:hover, .nav-search-tabs .nav-tabs > li.active > a:focus {
  border: 2px solid #0080ff;
  color: #fff;
  background-color: #0080ff;
  border-color: #0080ff;
  border-bottom-color: #0080ff;
  cursor: default;
}

.nav-search-tabs .nav-tabs > li.active > a:after, .nav-search-tabs .nav-tabs > li.active > a:hover:after, .nav-search-tabs .nav-tabs > li.active > a:focus:after {
  background-color: #fff;
}

@media (max-width: 768px) {
  .nav-search-tabs .nav-tabs > li.active > a, .nav-search-tabs .nav-tabs > li.active > a:hover, .nav-search-tabs .nav-tabs > li.active > a:focus {
    background-color: transparent;
  }
  .nav-search-tabs .nav-tabs > li.active > a:after, .nav-search-tabs .nav-tabs > li.active > a:hover:after, .nav-search-tabs .nav-tabs > li.active > a:focus:after {
    display: none;
  }
}

.flights-tabs {
  margin-bottom: 16px;
}

.flights-tabs .nav-tabs > li {
  float: left;
  margin-bottom: 0;
}

.flights-tabs .nav-tabs > li > a,
.flights-tabs .nav-tabs > li .nav-text {
  cursor: pointer;
  border: 2px solid #999999;
  -webkit-transition: 0.2s ease-out;
  -o-transition: 0.2s ease-out;
  transition: 0.2s ease-out;
  margin-right: 6px;
  border-radius: 30px;
  position: relative;
  font-weight: 600;
  font-size: 90%;
  color: #222222;
  padding: 3px 16px;
  padding-left: 32px;
}

.flights-tabs .nav-tabs > li > a:after,
.flights-tabs .nav-tabs > li .nav-text:after {
  content: '';
  position: absolute;
  background-color: #999999;
  width: 9px;
  height: 9px;
  left: 12px;
  top: 50%;
  margin-top: -5px;
  border-radius: 30px;
}

.flights-tabs .nav-tabs > li > a:hover,
.flights-tabs .nav-tabs > li .nav-text:hover {
  color: #fff;
  background-color: #006cd6;
  border-color: #006cd6;
}

.flights-tabs .nav-tabs > li > a:hover:after,
.flights-tabs .nav-tabs > li .nav-text:hover:after {
  background-color: rgba(255, 255, 255, 0.5);
}

.flights-tabs .nav-tabs > li.active > a, .flights-tabs .nav-tabs > li.active > a:hover, .flights-tabs .nav-tabs > li.active > a:focus,
.flights-tabs .nav-tabs > li.active > .nav-text,
.flights-tabs .nav-tabs > li.active > .nav-text:hover,
.flights-tabs .nav-tabs > li.active > .nav-text:focus {
  color: #fff;
  background-color: #0080ff;
  border-color: #006cd6;
  cursor: default;
}

.flights-tabs .nav-tabs > li.active > a:after, .flights-tabs .nav-tabs > li.active > a:hover:after, .flights-tabs .nav-tabs > li.active > a:focus:after,
.flights-tabs .nav-tabs > li.active > .nav-text:after,
.flights-tabs .nav-tabs > li.active > .nav-text:hover:after,
.flights-tabs .nav-tabs > li.active > .nav-text:focus:after {
  background-color: #fff;
}

.page-activity-packages {
  padding: 6px 0;
  margin-bottom: 32px;
}

.page-activity-packages .owl-carousel .owl-nav .owl-prev,
.page-activity-packages .owl-carousel .owl-nav .owl-next {
  box-shadow: none;
  border-radius: 0;
  background-color: #0080ff;
  color: #fff;
}

.page-activity-packages .owl-carousel .owl-nav .owl-prev:hover,
.page-activity-packages .owl-carousel .owl-nav .owl-next:hover {
  color: #fff;
}

@media (max-width: 768px) {
  .page-activity-packages .owl-carousel .owl-nav .owl-prev,
  .page-activity-packages .owl-carousel .owl-nav .owl-next {
    top: 20%;
  }
}

.page-activity-packages .owl-carousel .owl-nav .owl-prev {
  left: 0;
}

.page-activity-packages .owl-carousel .owl-nav .owl-next {
  right: 0;
}

.paynow-card {
  max-width: 350px;
  padding-left: 32px;
  padding-top: 32px;
}

.paynow-card .traveller-booking-list {
  margin-top: 16px;
}

.paynow-card .traveller-booking-pay {
  margin: 16px auto;
}

.paynow-card .button-block {
  margin-top: 32px;
}

.paynow-card .link-booknow {
  margin: 0 auto;
  display: block;
  max-width: 200px;
}

@media (max-width: 768px) {
  .paynow-card .link-booknow .link-booknow {
    max-width: initial;
  }
}

.package-price {
  position: relative;
}

.package-price .tittle {
  font-size: 11px;
  color: #6f6f6f;
}

.package-price .header {
  color: #6f6f6f;
  font-weight: 600;
}

.package-price .pinfo {
  color: #6f6f6f;
  font-size: 120%;
}

.package-price .new-price {
  line-height: 1;
  font-size: 120%;
  font-weight: 600;
}

.package-price .new-price .text {
  color: #6f6f6f;
  font-size: 55%;
  font-weight: 600;
}

.package-price .new-price .icons {
  font-size: 60%;
  margin-right: 1px;
}

.package-price .new-price .price {
  font-size: 100%;
}

.package-price .base-price {
  line-height: 1;
  font-size: 100%;
  font-weight: 600;
}

.package-price .base-price .text {
  color: #6f6f6f;
  font-size: 55%;
  font-weight: 600;
}

.package-price .base-price .icons {
  font-size: 90%;
  margin-right: 1px;
}

.package-price .base-price .price {
  font-size: 100%;
}

.package-price .old-price {
  line-height: 1;
  font-size: 70%;
  color: #6f6f6f;
}

.package-price .old-price .text {
  color: #6f6f6f;
  font-size: 90%;
  font-weight: 600;
}

.package-price .old-price .icons {
  font-size: 100%;
  margin-right: 1px;
}

.package-price .old-price .price {
  font-size: 140%;
  text-decoration: line-through;
}

.package-price .about {
  font-size: 100%;
  color: #6f6f6f;
}

.mini-blog-section {
  padding: 32px 0;
}

.mini-blog-card {
  margin-bottom: 16px;
  border: 2px solid #bfbfbf;
  overflow: hidden;
  border-radius: 4px;
}

.mini-blog-card .card-img {
  overflow: hidden;
}

.mini-blog-card .card-img a {
  display: block;
}

.mini-blog-card .card-img img {
  -webkit-transform: scale(1.2);
  -ms-transform: scale(1.2);
  -o-transform: scale(1.2);
  transform: scale(1.2);
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  display: block;
  width: 100%;
}

.mini-blog-card .card-about {
  overflow: hidden;
}

.mini-blog-card .card-about .about-contant {
  padding: 16px 16px;
  position: relative;
}

.mini-blog-card .card-about .about-contant .tittle {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 180%;
  font-weight: 700;
  margin-bottom: 5px;
}

.mini-blog-card .card-about .about-contant .icons {
  font-size: 150%;
  position: absolute;
  left: 0;
  color: #898989;
  border-right: 1px solid rgba(255, 255, 255, 0.5);
  top: 3px;
}

.mini-blog-card .card-about .contant-meta {
  position: relative;
}

.mini-blog-card .link-button {
  padding: 6px 16px;
}

.listing-item {
  position: relative;
  padding: 0 6px;
  padding-right: 35px;
  margin: 10.66667px 0;
}

.listing-item .author-vcard {
  margin-bottom: 10.66667px;
}

.listing-item .author-vcard .hashtag {
  font-size: 80%;
  line-height: 1;
  color: #6f6f6f;
}

.listing-item .comments-feeds {
  position: relative;
  background-color: #d6d6d6;
  padding: 8px 16px;
  border-radius: 4px;
  border-bottom-left-radius: 0;
}

.listing-item .comments-feeds:after {
  content: '';
  position: absolute;
  z-index: 1;
  width: 0;
  height: 0;
  bottom: 0;
  right: initial;
  left: -6px;
  border-bottom: 16px solid #d6d6d6;
  border-left: 16px solid transparent;
}

.listing-item .vcard {
  margin: 0;
  padding: 0;
}

.listing-item .vcard:before, .listing-item .vcard:after {
  display: table;
  content: " ";
}

.listing-item .vcard:after {
  clear: both;
}

.listing-item .vcard li {
  list-style: none;
  float: left;
  position: relative;
  margin-right: 24px;
}

.listing-item .vcard li.meta-name {
  font-size: 110%;
  font-weight: 500;
}

.listing-item .vcard li.meta-time {
  margin-top: 3.2px;
  font-size: 90%;
}

.listing-item .vcard li:after {
  position: absolute;
  content: '';
  width: 4px;
  height: 4px;
  background-color: #6f6f6f;
  border-radius: 100%;
  right: -13.33333px;
  top: 50%;
  margin-top: -2px;
  z-index: 2;
}

.listing-item .vcard li:last-child:after {
  display: none;
}

.listing-item .vcard li a {
  display: block;
}

.listing-item .vcard li .slug-reactions {
  color: #6f6f6f;
  font-size: 95%;
  line-height: 1;
}

.listing-item .vcard li:hover a .slug-reactions {
  color: #F04124;
}

.listing-item.even {
  padding: 0 6px;
  padding-left: 35px;
}

.listing-item.even .comments-feeds {
  border-radius: 4px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 0;
  background-color: #d1d1d1;
}

.listing-item.even .comments-feeds:after {
  left: initial;
  right: -6px;
  border-bottom: 16px solid #d1d1d1;
  border-right: 16px solid transparent;
}

.form-control-comments {
  display: block;
  min-height: 52.5px;
  border: 2px solid #bfbfbf;
  border-radius: 4px;
  padding: 6px 10.66667px;
  padding-right: 29.16667px;
}

.form-control-comments textarea {
  display: block;
  width: 100%;
  border: none;
  resize: none;
  outline: 0 !important;
  outline-offset: 0 !important;
  overflow: auto;
  padding: 0;
  max-height: 140px;
}

.is-focused .form-control-comments {
  border-color: #0096fb;
}

.comments-comment-texteditor {
  padding-top: 6px;
}

.messagefeed-section {
  min-height: 160px;
  max-height: 300px;
  overflow-x: auto;
}

.messagefeed-section.max-height {
  max-height: 430px;
}

.message-comment-section {
  padding-right: 16px;
  padding-bottom: 6px;
}

.message-feed-section {
  margin-bottom: 16px;
}

.message-feed-section.with-post {
  background-color: #fff;
}

.message-feed-section ul {
  margin: 0;
  padding: 0;
}

.message-feed-section ul li {
  list-style: none;
}

.message-feed-section ul.comment-listing {
  padding: 0;
  padding-top: 0;
}

.message-feed-section .message-footer {
  padding: 8px;
  border-top: 1px solid #bfbfbf;
}

.message-feed-section .message-footer:before, .message-feed-section .message-footer:after {
  display: table;
  content: " ";
}

.message-feed-section .message-footer:after {
  clear: both;
}

.message-feed-section .message-footer .heading {
  margin: 0;
  padding: 0;
  font-weight: 500;
  float: left;
}

.message-feed-section .message-footer .count {
  color: #333333;
  float: right;
}

.comments-comment-action {
  position: relative;
}

.post-comment-article {
  border-top: 1px solid #bfbfbf;
}

.post-comment-article .comments-filter-options {
  float: right;
}

.comments-texteditor {
  -webkit-transition: all 0.15s ease-in-out;
  -o-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
}

.comments-texteditor.form-group {
  margin: 0;
}

.comments-texteditor .form-control {
  height: 35px;
  -webkit-transition: all 0.15s ease-in-out;
  -o-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
}

.comments-shared-btn {
  position: absolute;
  right: 4px;
  bottom: 4px;
  z-index: 4;
}

.comments-shared-btn:before, .comments-shared-btn:after {
  display: table;
  content: " ";
}

.comments-shared-btn:after {
  clear: both;
}

.comments-shared-btn .fix-height {
  width: 26.92308px;
  height: 26.92308px;
  padding: 3px 0;
  border-radius: 100%;
  text-align: center;
}

.message-profile {
  position: relative;
  margin: 6px auto;
}

.profile-intro-info {
  position: relative;
  padding-top: 1.5px;
  padding-left: 62.4px;
  min-height: 48px;
}

.profile-intro-info .author-image {
  position: absolute;
  left: 0;
  top: 3px;
  width: 48px;
  height: 48px;
  border-radius: 4px;
  overflow: hidden;
}

.profile-intro-info .author-image .avatar {
  display: block;
  width: 100%;
  height: 100%;
}

.profile-intro-info .author-vcard .fn {
  font-size: 110%;
  font-weight: 500;
  color: #000;
  line-height: 1;
}

.profile-intro-info .author-vcard .profile {
  font-size: 90%;
  color: #6f6f6f;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.profile-intro-info .author-vcard .post-date:before, .profile-intro-info .author-vcard .post-date:after {
  display: table;
  content: " ";
}

.profile-intro-info .author-vcard .post-date:after {
  clear: both;
}

.profile-intro-info .author-vcard .reactions-counts-list li {
  float: left;
  position: relative;
  margin-right: 16px;
}

.profile-intro-info .author-vcard .reactions-counts-list li:after {
  position: absolute;
  content: '';
  width: 4px;
  height: 4px;
  background-color: #6f6f6f;
  border-radius: 100%;
  right: -8px;
  top: 50%;
  margin-top: -2px;
  z-index: 2;
}

.profile-intro-info .author-vcard .reactions-counts-list li:last-child:after {
  display: none;
}

.profile-intro-info .author-vcard .reactions-counts-list li a {
  display: block;
}

.profile-intro-info .author-vcard .reactions-counts-list li a .slug-reactions {
  color: #6f6f6f;
  font-size: 85%;
  line-height: 1;
}

.profile-intro-info .author-vcard .reactions-counts-list li a:hover .slug-reactions {
  color: #F04124;
}

.message-dropdown-link {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
  padding: 6px 0;
}

.message-dropdown-link:before, .message-dropdown-link:after {
  display: table;
  content: " ";
}

.message-dropdown-link:after {
  clear: both;
}

.message-dropdown-link .message-options {
  position: relative;
}

.message-dropdown-link.no-fixed {
  position: relative;
  right: initial;
  top: initial;
  z-index: 2;
  padding: 8px 0;
}

.message-dropdown-link .message-link {
  padding: 8px 16px;
}

.message-dropdown-link .sharecategory-dropdown {
  position: relative;
  padding: 8px 16px;
  padding-left: 44.8px;
}

.message-dropdown-link .sharecategory-dropdown .img-thumbnails {
  position: absolute;
  top: 8px;
  left: 8px;
  z-index: 2;
  width: 32px;
  height: 32px;
  line-height: 32px;
  text-align: center;
}

.message-dropdown-link .sharecategory-dropdown .img-thumbnails .icon {
  font-size: 110%;
}

.message-dropdown-link .sharecategory-dropdown .category {
  font-size: 110%;
  font-weight: 500;
}

.message-dropdown-link .sharecategory-dropdown .slug {
  font-size: 80%;
}

.message-dropdown-link .dropdown-menu {
  left: auto;
  right: 0;
  margin: 0;
  padding: 0;
  border-radius: 0;
}

.message-dropdown-link .dropdown-menu:before {
  content: '';
  width: 0;
  height: 0;
  bottom: 100%;
  z-index: 2;
  right: 16px;
  position: absolute;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid #fff;
}

.message-dropdown-link .dropdown-menu:after {
  content: '';
  width: 0;
  z-index: 1;
  height: 0;
  bottom: 100%;
  right: 14.54545px;
  position: absolute;
  border-left: 9.41176px solid transparent;
  border-right: 9.41176px solid transparent;
  border-bottom: 9.41176px solid #bfbfbf;
}

.message-article-image .share-article-image img {
  display: block;
  width: 100%;
}

.message-thread-panel {
  padding: 4px;
  overflow-x: auto;
  max-height: 500px;
  border-right: 1px solid #bfbfbf;
}

.thread-list-status {
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  padding: 6px;
  border-radius: 4px;
  margin: 3px auto;
  border: 1px solid transparent;
}

.thread-list-status.active {
  background-color: #fff;
  border-color: #bfbfbf;
}

.thread-list-status:hover {
  background-color: rgba(255, 255, 255, 0.8);
}

.no-padding {
  padding: 0;
}

.card-article {
  position: relative;
  padding: 16px 0;
}

.card-article.no-padding {
  padding-bottom: 0;
}

@media (max-width: 768px) {
  .card-article {
    padding: 0;
  }
}

.card-article .owl-nav button.owl-next,
.card-article .owl-nav button.owl-prev {
  -webkit-transition: box-shadow 0.3s ease-in-out;
  -o-transition: box-shadow 0.3s ease-in-out;
  transition: box-shadow 0.3s ease-in-out;
  display: block;
  position: absolute;
  top: 50%;
  margin-top: -30px;
  z-index: 2;
  cursor: pointer;
  text-align: center;
  background-color: #fff;
  line-height: 60px;
  height: 60px;
  width: 60px;
  font-size: 200%;
  border-radius: 100%;
}

.card-article .owl-nav button.owl-next:hover,
.card-article .owl-nav button.owl-prev:hover {
  color: #0080ff;
  text-decoration: none;
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.1), 0 6px 30px 5px rgba(0, 0, 0, 0.1), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
}

.card-article .owl-nav button.owl-next:focus,
.card-article .owl-nav button.owl-prev:focus {
  outline: none;
}

.card-article .owl-nav button.owl-next {
  right: -35px;
}

.card-article .owl-nav button.owl-prev {
  left: -35px;
}

.card-article .owl-nav .disabled {
  opacity: 0.5;
  cursor: default;
}

@media (max-width: 768px) {
  .card-article .owl-nav button.owl-next,
  .card-article .owl-nav button.owl-prev {
    border-radius: 4px;
    line-height: 33.33333px;
    height: 33.33333px;
    width: 33.33333px;
    font-size: 16.66667px;
    top: 41.66667px;
  }
  .card-article .owl-nav button.owl-next {
    right: 6px;
  }
  .card-article .owl-nav button.owl-prev {
    left: auto;
    right: 50px;
  }
}

.spacial-packages-card {
  margin: 0 auto;
  -webkit-transition: border 0.3s ease-in-out;
  -o-transition: border 0.3s ease-in-out;
  transition: border 0.3s ease-in-out;
  margin-bottom: 16px;
  border: 1px solid #bfbfbf;
  border-radius: 4px;
}

.spacial-packages-card .card-img-wrap {
  position: relative;
}

.spacial-packages-card .card-img {
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
}

.spacial-packages-card .card-img a {
  display: block;
}

.spacial-packages-card .card-img img {
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  display: block;
  width: 100%;
}

.spacial-packages-card.with-pkg-id .card-about .link-booknow {
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  filter: alpha(opacity=0);
  opacity: 0;
  position: absolute;
  z-index: 2;
  right: -200px;
  left: 200px;
}

.spacial-packages-card.with-pkg-id .card-about .package-id-artical {
  padding-left: 20px;
}

.spacial-packages-card.with-pkg-id .card-about .package-id-artical .tittle {
  font-size: 12px;
  color: #6f6f6f;
}

.spacial-packages-card.with-pkg-id .card-about .package-id-artical .package-id {
  font-size: 11px;
  font-weight: 500;
}

.spacial-packages-card.with-pkg-id:hover .link-booknow {
  filter: alpha(opacity=100);
  opacity: 1;
  right: 5px;
  left: 15px;
}

.spacial-packages-card .card-about {
  overflow: hidden;
}

.spacial-packages-card .card-about .about-contant {
  padding: 12px 16px;
  position: relative;
}

.spacial-packages-card .card-about .about-contant .tittle {
  font-size: 130%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.spacial-packages-card .card-about .about-contant .address {
  position: relative;
}

.spacial-packages-card .card-about .price-contant {
  padding: 12px 16px;
  padding-top: 0;
}

.spacial-packages-card .card-about .price-contant:before, .spacial-packages-card .card-about .price-contant:after {
  display: table;
  content: " ";
}

.spacial-packages-card .card-about .price-contant:after {
  clear: both;
}

.spacial-packages-card .card-about .link-button {
  position: relative;
  width: 50%;
  float: right;
}

.spacial-packages-card .card-about .package-price {
  position: relative;
  float: left;
  width: 50%;
  border-right: 1px solid #bfbfbf;
  padding-right: 20px;
}

.spacial-packages-card:hover {
  border-color: #F04124;
}

.spacial-packages-card:hover .card-img img {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -o-transform: scale(1.1);
  transform: scale(1.1);
}

.top-destinations-section {
  margin-top: 16px;
}

@media (max-width: 768px) {
  .top-destinations-section {
    margin-top: 0;
  }
}

.top-destinations-card {
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  margin: 0 auto;
  display: block;
  overflow: hidden;
  border: 1px solid #bfbfbf;
  background-color: #fff;
  border-radius: 4px;
  margin-bottom: 16px;
  color: inherit;
}

.top-destinations-card .card-img-wrap {
  position: relative;
}

.top-destinations-card .card-img-wrap .link-button.fixed {
  -webkit-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  filter: alpha(opacity=0);
  opacity: 0;
  position: absolute;
  bottom: -20px;
  right: -50px;
  z-index: 200;
}

.top-destinations-card .card-img-wrap .link-button.fixed .btn {
  width: 36px;
  height: 36px;
  padding: 0;
  line-height: 36px;
}

.top-destinations-card .card-img {
  overflow: hidden;
}

.top-destinations-card .card-img a {
  display: block;
}

.top-destinations-card .card-img img {
  display: block;
  width: 100%;
}

.top-destinations-card .card-about {
  overflow: hidden;
}

.top-destinations-card .card-about .about-contant {
  padding: 6px 16px;
  position: relative;
}

.top-destinations-card .card-about .about-contant .tittle {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 130%;
  font-weight: 600;
  margin-bottom: 5px;
}

.top-destinations-card .card-about .about-contant .icons {
  font-size: 150%;
  position: absolute;
  left: 0;
  color: #898989;
  border-right: 1px solid rgba(255, 255, 255, 0.5);
  top: 3px;
}

.top-destinations-card .card-about .about-contant .address {
  position: relative;
}

.top-destinations-card .card-about .price-contant {
  padding: 15px;
  padding-top: 0;
}

.top-destinations-card .card-about .price-contant:before, .top-destinations-card .card-about .price-contant:after {
  display: table;
  content: " ";
}

.top-destinations-card .card-about .price-contant:after {
  clear: both;
}

.top-destinations-card .card-about .package-id-artical {
  font-size: 12px;
}

.top-destinations-card .card-about .package-id-artical .tittle {
  color: #6f6f6f;
  font-size: 100%;
  font-weight: 400;
}

.top-destinations-card .card-about .package-price {
  position: relative;
}

.top-destinations-card .card-about .package-price .tittle {
  color: #6f6f6f;
  margin-right: 10px;
}

.top-destinations-card .card-about .package-price .about {
  color: #6f6f6f;
}

.top-destinations-card.with-facility {
  position: relative;
}

.top-destinations-card.with-facility .card-about {
  position: absolute;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  z-index: 3;
  top: 16px;
  bottom: 16px;
  right: 16px;
  left: 16px;
  padding: 16px;
  border-radius: 4px;
}

.top-destinations-card.with-facility .card-about .about-contant {
  color: #fff;
  padding: 0;
}

.top-destinations-card.with-facility .card-about .about-contant .tittle {
  font-size: 180%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.top-destinations-card.with-facility .card-about .about-contant.with-facility {
  position: absolute;
  bottom: 6px;
  text-align: right;
  right: 0;
  padding: 0 16px;
}

.top-destinations-card.with-facility .card-about .about-contant .facility.large {
  font-size: 120%;
  font-weight: 700;
}

.top-destinations-card.with-facility .card-about:after {
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  content: '';
  background-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: -1;
}

.top-destinations-card.with-facility:hover .about-contant {
  color: #fff;
}

.top-destinations-card.with-facility:hover .card-about {
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

.top-destinations-card.with-facility:hover .card-about:after {
  background-color: rgba(0, 0, 0, 0.4);
}

.top-destinations-card:hover {
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.1), 0 6px 30px 5px rgba(0, 0, 0, 0.1), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
}

.populer-theme-section {
  margin-top: 16px;
}

.populer-theme-card {
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  margin: 0 auto;
  overflow: hidden;
  border: 1px solid #bfbfbf;
  background-color: #fff;
  border-radius: 4px;
  margin-bottom: 6px;
}

.populer-theme-card .card-img-wrap {
  position: relative;
}

.populer-theme-card .card-img {
  overflow: hidden;
}

.populer-theme-card .card-img:after {
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  content: '';
  position: absolute;
  top: 16px;
  bottom: 16px;
  left: 16px;
  right: 16px;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 2;
  filter: alpha(opacity=0);
  opacity: 0;
}

.populer-theme-card .card-img a {
  display: block;
}

.populer-theme-card .card-img img {
  -webkit-transform: scale(1.2);
  -ms-transform: scale(1.2);
  -o-transform: scale(1.2);
  transform: scale(1.2);
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  display: block;
  width: 100%;
}

.populer-theme-card .link-button {
  position: absolute;
  filter: alpha(opacity=100);
  opacity: 1;
  right: 32px;
  left: 32px;
  top: 50%;
  z-index: 20;
  margin-top: -24px;
}

.populer-theme-card .link-button .header {
  text-align: center;
  color: #fff;
  font-size: 200%;
  font-weight: 700;
  line-height: 1;
  text-transform: uppercase;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
}

.populer-theme-card .link-button .btn {
  width: 36px;
  height: 36px;
  padding: 0;
  line-height: 36px;
}

.populer-theme-card .card-about {
  overflow: hidden;
}

.populer-theme-card .card-about .about-contant {
  padding: 6px 16px;
  position: relative;
}

.populer-theme-card .card-about .about-contant .tittle {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 110%;
  font-weight: 600;
  margin-bottom: 5px;
}

.populer-theme-card .card-about .about-contant .icons {
  font-size: 150%;
  position: absolute;
  left: 0;
  color: #898989;
  border-right: 1px solid rgba(255, 255, 255, 0.5);
  top: 3px;
}

.populer-theme-card .card-about .about-contant .address {
  position: relative;
}

.populer-theme-card .card-about .price-contant {
  padding: 15px;
  padding-top: 0;
}

.populer-theme-card .card-about .price-contant:before, .populer-theme-card .card-about .price-contant:after {
  display: table;
  content: " ";
}

.populer-theme-card .card-about .price-contant:after {
  clear: both;
}

.populer-theme-card .card-about .package-id-artical {
  font-size: 12px;
}

.populer-theme-card .card-about .package-id-artical .tittle {
  color: #6f6f6f;
  font-size: 100%;
  font-weight: 400;
}

.populer-theme-card .card-about .package-price {
  position: relative;
}

.populer-theme-card .card-about .package-price .tittle {
  color: #6f6f6f;
  margin-right: 10px;
}

.populer-theme-card .card-about .package-price .about {
  color: #6f6f6f;
}

.populer-theme-card:hover {
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.1), 0 6px 30px 5px rgba(0, 0, 0, 0.1), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
  border-color: #F04124;
}

.populer-theme-card:hover .card-img:after {
  filter: alpha(opacity=100);
  opacity: 1;
}

.populer-theme-card:hover .card-img img {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}

.populer-destinations-card {
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  margin: 0 auto;
  overflow: hidden;
  border: 1px solid #bfbfbf;
  background-color: #fff;
  border-radius: 4px;
  margin-bottom: 16px;
  color: #fff;
  display: block;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
}

.populer-destinations-card .card-img-wrap {
  position: relative;
}

.populer-destinations-card .card-img {
  overflow: hidden;
}

.populer-destinations-card .card-img:after {
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 2;
  filter: alpha(opacity=0);
  opacity: 0;
}

.populer-destinations-card .card-img a {
  display: block;
}

.populer-destinations-card .card-img img {
  -webkit-transform: scale(1.2);
  -ms-transform: scale(1.2);
  -o-transform: scale(1.2);
  transform: scale(1.2);
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  display: block;
  width: 100%;
}

.populer-destinations-card .link-button {
  position: absolute;
  filter: alpha(opacity=100);
  opacity: 1;
  right: 0;
  bottom: 0;
  z-index: 20;
  padding: 16px;
}

.populer-destinations-card .link-button .header {
  text-align: center;
  color: #fff;
  font-size: 200%;
  font-weight: 700;
  line-height: 1;
  text-transform: uppercase;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
}

.populer-destinations-card .link-button .facility.large {
  font-size: 120%;
  font-weight: 700;
}

.populer-destinations-card .link-button .btn {
  width: 36px;
  height: 36px;
  padding: 0;
  line-height: 36px;
}

.populer-destinations-card .about-contant {
  padding: 16px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 5;
}

.populer-destinations-card .about-contant .card-tittle {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 180%;
  font-weight: 600;
  margin-bottom: 5px;
}

.populer-destinations-card .about-contant .icons {
  font-size: 150%;
  position: absolute;
  left: 0;
  color: #898989;
  border-right: 1px solid rgba(255, 255, 255, 0.5);
  top: 3px;
}

.populer-destinations-card .about-contant .address {
  position: relative;
}

.populer-destinations-card .card-about {
  overflow: hidden;
}

.populer-destinations-card .card-about .price-contant {
  padding: 15px;
  padding-top: 0;
}

.populer-destinations-card .card-about .price-contant:before, .populer-destinations-card .card-about .price-contant:after {
  display: table;
  content: " ";
}

.populer-destinations-card .card-about .price-contant:after {
  clear: both;
}

.populer-destinations-card .card-about .package-id-artical {
  font-size: 12px;
}

.populer-destinations-card .card-about .package-price .tittle {
  color: #fff;
}

.populer-destinations-card .card-about .package-price .about {
  color: #6f6f6f;
}

.populer-destinations-card:hover {
  color: #fff;
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.1), 0 6px 30px 5px rgba(0, 0, 0, 0.1), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
  border-color: #F04124;
}

.populer-destinations-card:hover .card-img:after {
  filter: alpha(opacity=100);
  opacity: 1;
}

.populer-destinations-card:hover .card-img img {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}

.populer-destinations-card.wide .card-img:after {
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 30%, rgba(0, 0, 0, 0.2) 80%);
  background-image: -o-linear-gradient(top, rgba(0, 0, 0, 0) 30%, rgba(0, 0, 0, 0.2) 80%);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 30%, rgba(0, 0, 0, 0.2) 80%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#33000000', GradientType=0);
  background-repeat: repeat-x;
  filter: alpha(opacity=100);
  opacity: 1;
}

.populer-destinations-card.wide .about-contant {
  top: auto;
  bottom: 0;
}

.populer-destinations-card.wide .about-contant .card-tittle {
  font-size: 140%;
}

.populer-destinations-card.wide .about-contant .facility {
  font-size: 90%;
}

.populer-destinations-card.wide .link-button {
  bottom: auto;
  top: 0;
}

.populer-destinations-card.wide:hover .card-img:after {
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.4) 100%);
  background-image: -o-linear-gradient(top, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.4) 100%);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.4) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#66000000', endColorstr='#66000000', GradientType=0);
  background-repeat: repeat-x;
}

.populer-activity-section {
  margin-top: 32px;
}

.populer-activity-card {
  -webkit-transition: box-shadow 0.3s ease-in-out;
  -o-transition: box-shadow 0.3s ease-in-out;
  transition: box-shadow 0.3s ease-in-out;
  margin: 0 auto;
  overflow: hidden;
  border: 1px solid #bfbfbf;
  background-color: #fff;
  border-radius: 4px;
  margin-bottom: 6px;
}

.populer-activity-card .populer-activity-card-img-wrap {
  position: relative;
}

.populer-activity-card .populer-activity-card-img {
  overflow: hidden;
}

.populer-activity-card .populer-activity-card-img a {
  display: block;
}

.populer-activity-card .populer-activity-card-img img {
  display: block;
  width: 100%;
  height: 160px;
}

.populer-activity-card .link-button {
  -webkit-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  filter: alpha(opacity=0);
  opacity: 0;
  position: absolute;
  bottom: -20px;
  right: -50px;
  z-index: 200;
}

.populer-activity-card .link-button .btn {
  width: 36px;
  height: 36px;
  padding: 0;
  line-height: 36px;
}

.populer-activity-card .populer-activity-card-about {
  overflow: hidden;
}

.populer-activity-card .populer-activity-card-about .about-contant {
  padding: 6px 16px;
  position: relative;
}

.populer-activity-card .populer-activity-card-about .about-contant .tittle {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 130%;
  font-weight: 600;
  margin-bottom: 5px;
}

.populer-activity-card .populer-activity-card-about .about-contant .icons {
  font-size: 150%;
  position: absolute;
  left: 0;
  color: #898989;
  border-right: 1px solid rgba(255, 255, 255, 0.5);
  top: 3px;
}

.populer-activity-card .populer-activity-card-about .about-contant .address {
  position: relative;
}

.populer-activity-card .populer-activity-card-about .price-contant {
  padding: 15px;
  padding-top: 0;
}

.populer-activity-card .populer-activity-card-about .price-contant:before, .populer-activity-card .populer-activity-card-about .price-contant:after {
  display: table;
  content: " ";
}

.populer-activity-card .populer-activity-card-about .price-contant:after {
  clear: both;
}

.populer-activity-card .populer-activity-card-about .package-id-artical {
  font-size: 12px;
}

.populer-activity-card .populer-activity-card-about .package-id-artical .tittle {
  color: #6f6f6f;
  font-size: 100%;
  font-weight: 400;
}

.populer-activity-card .populer-activity-card-about .package-price {
  position: relative;
}

.populer-activity-card .populer-activity-card-about .package-price .tittle {
  color: #6f6f6f;
  margin-right: 10px;
}

.populer-activity-card .populer-activity-card-about .package-price .about {
  color: #6f6f6f;
}

.populer-activity-card:hover {
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.1), 0 6px 30px 5px rgba(0, 0, 0, 0.1), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
}

.populer-activity-card:hover .link-button {
  filter: alpha(opacity=100);
  opacity: 1;
  bottom: -20px;
  right: 10px;
}

.populer-activity-card.with-overlay {
  overflow: hidden;
  position: relative;
}

.populer-activity-card.with-overlay .populer-activity-card-about {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.8);
  right: 16px;
  left: 16px;
  bottom: 0;
  color: #fff;
}

.populer-activity-card.with-overlay .populer-activity-card-about .about-contant .tittle,
.populer-activity-card.with-overlay .populer-activity-card-about .package-price .tittle,
.populer-activity-card.with-overlay .populer-activity-card-about .package-id-artical .tittle {
  color: #fff;
}

.activity-listing-section {
  margin-top: 32px;
}

.activity-listing-card {
  -webkit-transition: box-shadow 0.3s ease-in-out;
  -o-transition: box-shadow 0.3s ease-in-out;
  transition: box-shadow 0.3s ease-in-out;
  margin: 0 auto;
  overflow: hidden;
  border: 1px solid #bfbfbf;
  background-color: #fff;
  border-radius: 4px;
  margin-bottom: 16px;
}

.activity-listing-card .activity-listing-card-img-wrap {
  margin: 6px;
  position: relative;
  min-height: 110.76923px;
  padding-left: 165.6px;
  padding-top: 6px;
}

.activity-listing-card .activity-listing-card-img {
  position: absolute;
  left: 0;
  top: 0;
  width: 144px;
  overflow: hidden;
}

.activity-listing-card .activity-listing-card-img a {
  display: block;
}

.activity-listing-card .activity-listing-card-img img {
  display: block;
  width: 100%;
  height: 100%;
}

.activity-listing-card .package-amenities {
  padding: 3px 0;
}

.activity-listing-card .amenities-list {
  margin: 0;
  padding: 0;
}

.activity-listing-card .amenities-list:before, .activity-listing-card .amenities-list:after {
  display: table;
  content: " ";
}

.activity-listing-card .amenities-list:after {
  clear: both;
}

.activity-listing-card .amenities-list li {
  float: left;
  max-width: 28.8px;
  list-style: none;
  margin: 0 3px;
}

.activity-listing-card .amenities-list img {
  display: block;
  width: 100%;
}

.activity-listing-card .activity-listing-card-about {
  overflow: hidden;
}

.activity-listing-card .activity-listing-card-about .about-contant {
  padding: 0 16px;
  margin-bottom: 16px;
  position: relative;
}

.activity-listing-card .activity-listing-card-about .about-contant .tittle {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 130%;
  font-weight: 600;
}

.activity-listing-card .activity-listing-card-about .about-contant .icons {
  font-size: 150%;
  position: absolute;
  left: 0;
  color: #898989;
  border-right: 1px solid rgba(255, 255, 255, 0.5);
  top: 3px;
}

.activity-listing-card .activity-listing-card-about .about-contant .address {
  position: relative;
}

.activity-listing-card .activity-listing-card-about .price-contant {
  padding: 16px;
  padding-top: 0;
}

.activity-listing-card .activity-listing-card-about .price-contant:before, .activity-listing-card .activity-listing-card-about .price-contant:after {
  display: table;
  content: " ";
}

.activity-listing-card .activity-listing-card-about .price-contant:after {
  clear: both;
}

.activity-listing-card .package-price {
  position: relative;
  font-size: 120%;
}

.activity-listing-card .package-price .tittle {
  color: #6f6f6f;
  margin-bottom: 3px;
}

.activity-listing-card .package-price .about {
  color: #6f6f6f;
}

.activity-listing-card .package-price .old-price {
  margin-bottom: 6px;
}

.activity-listing-card .rate-count .stars {
  font-size: 80%;
  vertical-align: text-bottom;
}

.activity-listing-card .package-id-artical {
  font-size: 80%;
}

.activity-listing-card .package-id-artical .tittle {
  color: #6f6f6f;
  font-size: 100%;
  font-weight: 400;
}

.activity-listing-card:hover {
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.1), 0 6px 30px 5px rgba(0, 0, 0, 0.1), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
}

.package-label {
  position: absolute;
  text-align: right;
  top: 16px;
  right: 0;
  z-index: 2;
  padding: 3px 6px;
}

.package-label .tittle {
  font-weight: 600;
  font-size: 90%;
}

.package-label:after {
  content: '';
  position: absolute;
  border-top: 28.8px solid transparent;
  border-left: 16px solid transparent;
  right: 100%;
  top: 0;
}

.package-label.new-arrivals {
  background-color: #5cb85c;
  color: #fff;
}

.package-label.new-arrivals:after {
  border-top-color: #5cb85c;
}

.package-label.on-sale {
  background-color: #0080ff;
  color: #fff;
}

.package-label.on-sale:after {
  border-top-color: #0080ff;
}

.package-label.sold-out, .package-label.off-stock {
  background-color: #F04124;
  color: #fff;
}

.package-label.sold-out:after, .package-label.off-stock:after {
  border-top-color: #F04124;
}

@media (max-width: 768px) {
  .package-label {
    padding: 2px 6px;
  }
  .package-label .tittle {
    font-size: 80%;
  }
  .package-label:after {
    content: '';
    position: absolute;
    border-top: 24px solid transparent;
    border-left: 16px solid transparent;
    right: 100%;
    top: 0;
  }
}

.package-plist-package {
  margin: 6px auto;
}

.package-plist-package .header {
  font-size: 90%;
  font-weight: 600;
}

.package-plist-package ul:before, .package-plist-package ul:after,
.package-plist-package .list:before,
.package-plist-package .list:after {
  display: table;
  content: " ";
}

.package-plist-package ul:after,
.package-plist-package .list:after {
  clear: both;
}

.package-plist-package li {
  position: relative;
  float: left;
  padding: 0;
}

.package-plist-package li .count {
  margin-left: 3px;
  color: gray;
  font-size: 90%;
  font-weight: 500;
}

.package-plist-package li .count .icons {
  font-size: 80%;
  margin-left: 2px;
}

.package-plist-package li label {
  cursor: pointer;
  padding-right: 16px;
}

.package-plist-package li label .options {
  padding-left: 19.2px !important;
}

.package-plist-package li .figure {
  display: inline-block;
  width: 18px;
}

.package-plist-package li .star-circle {
  color: #009688;
}

.package-plist-package li .booking-price {
  position: absolute;
  right: 0;
  bottom: 0;
  top: 50%;
  margin-top: -7.8px;
  z-index: 2;
  color: gray;
}

.package-plist-package li .booking-price .fa-w-10 {
  height: 0.7em;
  margin-right: 2px;
}

.package-plist-package li .booking-price .text {
  font-weight: 600;
}

.package-plist-package li .booking-price .text.count {
  font-size: 90%;
}

.package-plist-package li .sub-text {
  display: block;
  position: relative;
  margin: 4px auto;
  margin-right: 6px;
  padding-left: 30.8px;
  min-height: 22px;
}

.package-plist-package li .sub-text .icons {
  font-size: 140%;
  color: #4a00e0;
}

.package-plist-package li .sub-text .text-ico {
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 22px;
  height: 22px;
  line-height: 22px;
  overflow: hidden;
  display: block;
  border-radius: 30px;
}

.package-plist-package li .sub-text .text-ico img {
  display: block;
  width: 100%;
  height: 100%;
}

.package-plist-package li .sub-text .text {
  display: block;
}

.package-plist-package .view-more {
  text-align: right;
  font-weight: 600;
}

.package-plist-package.with-inclusion {
  padding: 3px 6px;
}

.hotel-combo-card {
  -webkit-transition: box-shadow 0.3s ease-in-out;
  -o-transition: box-shadow 0.3s ease-in-out;
  transition: box-shadow 0.3s ease-in-out;
  margin: 0 auto;
  overflow: hidden;
  border: 1px solid #bfbfbf;
  background-color: #fff;
  border-radius: 4px;
  margin-bottom: 16px;
}

.hotel-combo-card-img-wrap {
  margin: 6px;
  position: relative;
  min-height: 123.07692px;
  padding-left: 184px;
  margin-right: 16px;
}

.hotel-combo-card-img-wrap .tittle {
  font-size: 140%;
  font-weight: 600;
}

.hotel-combo-card-img-wrap .more-info {
  margin-top: 6px;
}

.hotel-combo-card-img {
  position: absolute;
  left: 0;
  top: 0;
  width: 160px;
  overflow: hidden;
}

.hotel-combo-card-img a {
  display: block;
}

.hotel-combo-card-img img {
  display: block;
  width: 100%;
  height: 100%;
}

.hotel-combo-card .about-contant {
  position: relative;
  z-index: 2;
  background-color: #fff;
}

@media (max-width: 768px) {
  .hotel-combo-card-img-wrap {
    margin: 0;
    padding: 6px;
  }
  .hotel-combo-card-img {
    position: relative;
    width: auto;
    margin-bottom: 16px;
  }
  .hotel-combo-card-image {
    display: none;
    width: 66%;
  }
}

.hotel-combo-card .package-amenities {
  padding: 3px 0;
}

.hotel-combo-card .amenities-list {
  margin: 0;
  padding: 0;
}

.hotel-combo-card .amenities-list:before, .hotel-combo-card .amenities-list:after {
  display: table;
  content: " ";
}

.hotel-combo-card .amenities-list:after {
  clear: both;
}

.hotel-combo-card .amenities-list li {
  float: left;
  max-width: 28.8px;
  list-style: none;
  margin: 0 3px;
}

.hotel-combo-card .amenities-list img {
  display: block;
  width: 100%;
}

.hotel-combo-card .package-price {
  position: relative;
}

.hotel-combo-card .package-price .tittle {
  color: #6f6f6f;
  margin-bottom: 3px;
}

.hotel-combo-card .package-price .about {
  color: #6f6f6f;
}

.hotel-combo-card .package-price .old-price {
  margin-bottom: 6px;
  margin-right: 6px;
}

.hotel-combo-card .package-price .new-price {
  font-size: 180%;
}

.hotel-combo-card .rate-count .stars {
  font-size: 80%;
  vertical-align: text-bottom;
}

.hotel-combo-card .package-id-artical {
  font-size: 80%;
}

.hotel-combo-card .package-id-artical .tittle {
  color: #6f6f6f;
  font-size: 100%;
  font-weight: 400;
}

.hotel-combo-card-about {
  background-color: #fff;
  padding: 6px 16px;
  border-top: 1px solid #bfbfbf;
  position: relative;
  padding-left: 48px;
  padding-right: 160px;
}

.hotel-combo-card-about:after {
  position: absolute;
  content: '';
  display: block;
  width: 16px;
  height: 16px;
  border-radius: 100%;
  left: 16px;
  top: 16px;
  border: 2px solid #8c8c8c;
}

.hotel-combo-card-about.active:after {
  background-color: #0080ff;
  border-color: #0080ff;
}

.hotel-combo-card-about .about-contant {
  margin-bottom: 6px;
  position: relative;
}

.hotel-combo-card-about .about-contant .tittle {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 130%;
  font-weight: 600;
}

.hotel-combo-card-about .about-contant .icons {
  font-size: 150%;
  position: absolute;
  left: 0;
  color: #898989;
  border-right: 1px solid rgba(255, 255, 255, 0.5);
  top: 3px;
}

.hotel-combo-card-about .about-contant .address {
  position: relative;
}

.hotel-combo-card-about .contant-list {
  margin-left: -16px;
}

.hotel-combo-card-about .contant-list:before, .hotel-combo-card-about .contant-list:after {
  display: table;
  content: " ";
}

.hotel-combo-card-about .contant-list:after {
  clear: both;
}

.hotel-combo-card-about .contant-list .list-out {
  float: left;
}

.hotel-combo-card-about .contant-list .list-out li {
  padding: 0 16px;
}

.hotel-combo-card-about .contant-list .pax-count {
  position: relative;
  float: right;
  font-weight: 600;
  line-height: 1;
}

.hotel-combo-card-about .contant-list .pax-count .options {
  left: 0;
  z-index: 2;
  right: 0;
  text-align: center;
  position: absolute;
  bottom: -24px;
}

.hotel-combo-card-about .contant-list .pax-count .text-options {
  border-radius: 40px;
  background-color: #333333;
  color: #fff;
  padding: 3px 6px;
  font-size: 90%;
}

.hotel-combo-card-about .link-button {
  position: absolute;
  right: 16px;
  top: 50%;
  margin-top: -24px;
  z-index: 2;
}

.hotel-combo-card-about .link-booknow {
  margin-bottom: 6px;
}

.hotel-combo-card-about .price-contant:before, .hotel-combo-card-about .price-contant:after {
  display: table;
  content: " ";
}

.hotel-combo-card-about .price-contant:after {
  clear: both;
}

@media (max-width: 768px) {
  .hotel-combo-card-about {
    padding: 3px 16px;
    padding-left: 32px;
    padding-right: 0;
  }
  .hotel-combo-card-about:after {
    position: absolute;
    content: '';
    display: block;
    width: 16px;
    height: 16px;
    border-radius: 100%;
    left: 8px;
    top: 10.66667px;
    border: 2px solid #8c8c8c;
  }
  .hotel-combo-card-about .link-button {
    position: relative;
    top: auto;
    margin-top: 6px;
    right: auto;
  }
  .hotel-combo-card-about .link-button .link-booknow {
    max-width: 60%;
  }
}

@media (max-width: 768px) and (max-width: 768px) {
  .hotel-combo-card-about .link-button .link-booknow {
    max-width: initial;
  }
}

@media (max-width: 768px) {
  .hotel-combo-card-about .about-contant {
    margin-bottom: 0;
  }
  .hotel-combo-card-about .contant-list {
    margin-bottom: 6px;
  }
  .hotel-combo-card-about .contant-list .list-out {
    float: none;
  }
  .hotel-combo-card-about .contant-list .list-out li {
    margin: 2px auto;
  }
  .hotel-combo-card-about .contant-list .pax-count {
    float: none;
    margin-left: 16px;
  }
  .hotel-combo-card-about .contant-list .pax-count .text {
    display: inline-block;
  }
  .hotel-combo-card-about .contant-list .pax-count .options {
    position: relative;
    bottom: auto;
    left: auto;
    right: auto;
    font-size: 80%;
    font-weight: 600;
  }
}

.round {
  border-radius: 40px !important;
}

.booking-forms {
  border-top: 1px solid #bfbfbf;
  margin-bottom: 20px;
}

.booking-forms .heading {
  padding: 16px 0;
  font-size: 120%;
  border-bottom: 1px solid #bfbfbf;
}

.booking-formicons {
  position: relative;
  padding: 16px 0;
  padding-left: 54px;
  font-size: 95%;
  border-bottom: 1px solid #bfbfbf;
}

.booking-formicons .icons {
  font-size: 260%;
  position: absolute;
  left: 0;
  color: #F04124;
  top: 22px;
}

.booking-formicons .icons.normal {
  font-weight: 400;
}

.booking-formicons .header {
  font-size: 120%;
  font-weight: 600;
  margin-bottom: 5px;
}

.booking-frmheader {
  padding: 16px 0;
}

.booking-body {
  padding: 16px 0;
}

.widget-booking-policy {
  padding-left: 16px;
}

@media (max-width: 768px) {
  .widget-booking-policy {
    padding-left: 0;
  }
}

.package-header .heading {
  font-size: 180%;
  font-weight: 600;
}

.package-header .heading.bordered {
  font-size: 150%;
  border-bottom: 1px solid #bfbfbf;
  padding-bottom: 8px;
  margin-bottom: 16px;
}

.package-header .sub-heading {
  font-size: 110%;
  color: #6f6f6f;
  font-weight: 400;
  text-transform: uppercase;
}

.package-header .header {
  font-size: 220%;
  text-transform: uppercase;
  color: #6f6f6f;
  font-weight: 700;
}

.package-header .list-out {
  margin: 0;
  padding: 0;
  display: inline-block;
  margin-left: -8px;
  padding-bottom: 8px;
}

.package-header .list-out.list-footer {
  margin-top: 16px;
}

.package-header .list-out li {
  float: left;
  list-style: none;
  line-height: 1;
  border-right: 1px solid #bfbfbf;
  font-size: 90%;
  padding: 0 8px;
}

.package-header .list-out li:last-child {
  border: 0;
  padding-right: 0;
}

@media (max-width: 768px) {
  .package-header .heading {
    font-size: 160%;
    font-weight: 700;
  }
  .package-header .sub-heading {
    font-size: 90%;
    font-weight: 300;
  }
}

.package-header .sub-footer {
  border-bottom: 1px solid #bfbfbf;
  padding-bottom: 16px;
  margin-bottom: 16px;
  font-size: 120%;
}

.table-booking {
  border: 0;
}

.table-booking > thead > tr > th,
.table-booking > thead > tr > td,
.table-booking > tbody > tr > th,
.table-booking > tbody > tr > td,
.table-booking > tfoot > tr > th,
.table-booking > tfoot > tr > td {
  font-size: 115%;
  padding: 3px 0;
  border: 0;
  vertical-align: bottom;
}

.table-booking > thead > tr > th .small,
.table-booking > thead > tr > td .small,
.table-booking > tbody > tr > th .small,
.table-booking > tbody > tr > td .small,
.table-booking > tfoot > tr > th .small,
.table-booking > tfoot > tr > td .small {
  font-size: 80%;
  color: #6f6f6f;
}

.table-booking > thead > tr > th .input-offer,
.table-booking > thead > tr > td .input-offer,
.table-booking > tbody > tr > th .input-offer,
.table-booking > tbody > tr > td .input-offer,
.table-booking > tfoot > tr > th .input-offer,
.table-booking > tfoot > tr > td .input-offer {
  padding: 6px 0;
}

.table-booking > thead > tr:last-child > th,
.table-booking > thead > tr:last-child > td,
.table-booking > tbody > tr:last-child > th,
.table-booking > tbody > tr:last-child > td,
.table-booking > tfoot > tr:last-child > th,
.table-booking > tfoot > tr:last-child > td {
  padding-bottom: 16px;
}

.table-booking > thead > tr.borderd > th,
.table-booking > thead > tr.borderd > td,
.table-booking > tbody > tr.borderd > th,
.table-booking > tbody > tr.borderd > td,
.table-booking > tfoot > tr.borderd > th,
.table-booking > tfoot > tr.borderd > td {
  padding: 6px 0;
  border-top: 1px solid #bfbfbf;
}

.table-booking > thead > tr.borderd > th .input-offer,
.table-booking > thead > tr.borderd > td .input-offer,
.table-booking > tbody > tr.borderd > th .input-offer,
.table-booking > tbody > tr.borderd > td .input-offer,
.table-booking > tfoot > tr.borderd > th .input-offer,
.table-booking > tfoot > tr.borderd > td .input-offer {
  padding: 6px 0;
}

.table-booking > thead > tr.borderd > th .large-text,
.table-booking > thead > tr.borderd > td .large-text,
.table-booking > tbody > tr.borderd > th .large-text,
.table-booking > tbody > tr.borderd > td .large-text,
.table-booking > tfoot > tr.borderd > th .large-text,
.table-booking > tfoot > tr.borderd > td .large-text {
  font-size: 140%;
}

.table-booking > thead > tr > th,
.table-booking > thead > tr > td {
  font-size: 85%;
  padding: 10px 0px 5px 10px;
  border: 0;
  color: #F04124;
}

.table-booking-list {
  border: 0;
  margin: 0;
}

.table-booking-list > thead > tr > th,
.table-booking-list > thead > tr > td,
.table-booking-list > tbody > tr > th,
.table-booking-list > tbody > tr > td,
.table-booking-list > tfoot > tr > th,
.table-booking-list > tfoot > tr > td {
  font-size: 115%;
  padding: 0;
  border: 0;
  vertical-align: bottom;
}

.table-booking-list > thead > tr > th .small,
.table-booking-list > thead > tr > td .small,
.table-booking-list > tbody > tr > th .small,
.table-booking-list > tbody > tr > td .small,
.table-booking-list > tfoot > tr > th .small,
.table-booking-list > tfoot > tr > td .small {
  font-size: 70%;
  color: #6f6f6f;
}

.table-booking-list > thead > tr > th .mini,
.table-booking-list > thead > tr > td .mini,
.table-booking-list > tbody > tr > th .mini,
.table-booking-list > tbody > tr > td .mini,
.table-booking-list > tfoot > tr > th .mini,
.table-booking-list > tfoot > tr > td .mini {
  font-size: 90%;
  color: #6f6f6f;
}

.table-booking-list > thead > tr > th .large,
.table-booking-list > thead > tr > td .large,
.table-booking-list > tbody > tr > th .large,
.table-booking-list > tbody > tr > td .large,
.table-booking-list > tfoot > tr > th .large,
.table-booking-list > tfoot > tr > td .large {
  font-size: 120%;
  color: #6f6f6f;
}

.table-booking-list > thead > tr > th .mini,
.table-booking-list > thead > tr > td .mini,
.table-booking-list > tbody > tr > th .mini,
.table-booking-list > tbody > tr > td .mini,
.table-booking-list > tfoot > tr > th .mini,
.table-booking-list > tfoot > tr > td .mini {
  font-size: 90%;
  color: #6f6f6f;
}

.table-booking-list > thead > tr.borderd > th,
.table-booking-list > thead > tr.borderd > td,
.table-booking-list > tbody > tr.borderd > th,
.table-booking-list > tbody > tr.borderd > td,
.table-booking-list > tfoot > tr.borderd > th,
.table-booking-list > tfoot > tr.borderd > td {
  padding: 3px 0;
  border-top: 1px solid #bfbfbf;
}

.table-booking-list > thead > tr.borderd > th .large,
.table-booking-list > thead > tr.borderd > td .large,
.table-booking-list > tbody > tr.borderd > th .large,
.table-booking-list > tbody > tr.borderd > td .large,
.table-booking-list > tfoot > tr.borderd > th .large,
.table-booking-list > tfoot > tr.borderd > td .large {
  font-size: 120%;
  color: #6f6f6f;
}

.table-booking-list > thead > tr > th,
.table-booking-list > thead > tr > td {
  font-size: 85%;
  padding: 10px 0px 5px 10px;
  border: 0;
  color: #F04124;
}

.traveller-booking-list ul.list {
  margin: 0;
  padding: 0;
}

.traveller-booking-list ul.list > li {
  padding: 6px 0;
  display: block;
  list-style: none;
}

.traveller-booking-list .contant-list {
  margin-left: -6px;
}

.traveller-booking-list .contant-list:before, .traveller-booking-list .contant-list:after {
  display: table;
  content: " ";
}

.traveller-booking-list .contant-list:after {
  clear: both;
}

.traveller-booking-list .contant-list .list-out li {
  line-height: 1;
  font-size: 80%;
  padding: 0 6px;
}

.traveller-outer {
  padding: 6px 0;
  border-bottom: 1px solid #bfbfbf;
}

.traveller-outer .traveller-info {
  font-size: 130%;
  font-weight: 600;
}

.heading-address {
  padding: 6px 16px;
  border-bottom: 1px dashed #bfbfbf;
}

.heading-address .tittle {
  font-weight: 600;
  margin-bottom: 6px;
}

.heading-address .tittle:before, .heading-address .tittle:after {
  display: table;
  content: " ";
}

.heading-address .tittle:after {
  clear: both;
}

.heading-address .tittle .header {
  float: left;
  font-size: 160%;
}

.heading-address .tittle .info {
  float: right;
  margin-top: 6px;
  font-size: 80%;
}

.heading-address .tittle .info .rate-count {
  margin: 0;
}

.heading-address .rout-address {
  position: relative;
  padding-left: 25.6px;
  margin-bottom: 6px;
}

.heading-address .rout-address .rout-icons {
  position: absolute;
  z-index: 2;
  top: -3px;
  left: 0;
  color: #999999;
  font-size: 140%;
}

.heading-address .rout-address .address {
  position: relative;
  padding-right: 128px;
  line-height: 1.2;
}

.heading-address .rout-address .address .address-info {
  font-size: 100%;
  color: #6f6f6f;
}

@media (max-width: 768px) {
  .heading-address .tittle .header,
  .heading-address .tittle .info {
    float: none;
  }
  .heading-address .rout-address .address {
    padding-right: 0;
  }
}

.table-fare {
  margin: 0;
}

.table-fare > tbody > tr > th,
.table-fare > tbody > tr > td,
.table-fare > tfoot > tr > th,
.table-fare > tfoot > tr > td {
  padding: 3px;
}

.table-fare > tbody > tr:first-child > th,
.table-fare > tbody > tr:first-child > td,
.table-fare > tfoot > tr:first-child > th,
.table-fare > tfoot > tr:first-child > td {
  border: 0;
}

.table-fare > thead > tr > th,
.table-fare > thead > tr > td {
  padding: 3px;
  padding-top: 0;
}

@media (max-width: 768px) {
  .table-fare {
    margin-top: 16px;
  }
}

.destination-icons {
  position: relative;
  text-transform: uppercase;
}

.destination-icons .text-in {
  font-size: 90%;
  color: #6f6f6f;
}

.destination-icons .text-out {
  font-size: 90%;
  text-align: right;
  color: #6f6f6f;
}

.travel-icons {
  position: relative;
  padding: 9px 0;
}

.travel-icons:after, .travel-icons:before {
  position: absolute;
  content: "";
  width: 10px;
  height: 10px;
  top: 50%;
  margin-top: -5px;
  border-radius: 100%;
  background: #bfbfbf;
}

.travel-icons:after {
  left: 0;
}

.travel-icons:before {
  right: 0;
}

.travel-icons .icons {
  text-align: center;
  position: absolute;
  width: 24px;
  height: 24px;
  color: #3c3c3c;
  left: 50%;
  margin-left: -12px;
  top: 50%;
  margin-top: -8px;
  font-size: 110%;
}

.travel-icons .icons.rotate-45 {
  text-align: right;
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=1)";
  transform: rotate(-45deg);
}

.travel-icons .line:after, .travel-icons .line:before {
  position: absolute;
  content: "";
  width: 40%;
  height: 2px;
  top: 50%;
  margin-top: -1px;
  background: #bfbfbf;
}

.travel-icons .line:after {
  left: 0;
}

.travel-icons .line:before {
  right: 0;
}

.destination-icon-card {
  padding: 8px 0;
  position: relative;
}

.destination-icon-card.max-width {
  max-width: 70%;
}

.destination-icon-card .destination-in .location, .destination-icon-card .destination-out .location {
  font-weight: 500;
}

.destination-icon-card .destination-in .trip-time, .destination-icon-card .destination-out .trip-time {
  font-size: 90%;
  color: #6f6f6f;
}

.destination-icon-card .destination-in .trip-time .time, .destination-icon-card .destination-out .trip-time .time {
  margin-right: 10px;
}

a,
button {
  cursor: pointer;
}

.product-detail {
  padding: 15px 0;
}

.product-name {
  padding-bottom: 6px;
}

.product-name .tittle {
  margin: 0;
  padding: 0;
  font-size: 200%;
  font-weight: 500;
}

.product-name .sub-tittle {
  color: #6f6f6f;
  font-size: 50%;
  margin-left: 15px;
}

.product-name .contant-list {
  padding-top: 3px;
  margin-left: -3px;
  font-size: 110%;
}

.package-hightlight-outer {
  padding-top: 20px;
}

.product-detail-container {
  padding-right: 16px;
}

@media (max-width: 768px) {
  .product-detail-container {
    padding-right: 0;
  }
}

.products-figure-slide {
  padding-bottom: 16px;
}

.package-rout {
  position: relative;
  padding-bottom: 16px;
}

.package-rout:before, .package-rout:after {
  display: table;
  content: " ";
}

.package-rout:after {
  clear: both;
}

.package-rout .header {
  font-size: 110%;
  font-weight: 600;
}

.package-rout .rout-list {
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.package-rout .rout-list li {
  font-size: 85%;
  list-style: none;
  float: left;
  position: relative;
  padding: 0 30px;
  color: #6f6f6f;
}

.package-rout .rout-list li .text {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 12px;
}

.package-rout .rout-list li:before {
  top: 50%;
  margin-top: -1px;
  position: absolute;
  left: -12px;
  width: 34px;
  height: 2px;
  background-color: #bfbfbf;
  content: '';
}

.package-rout .rout-list li:after {
  top: 50%;
  margin-top: -5px;
  position: absolute;
  left: 0;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background-color: #8c8c8c;
  content: '';
}

.rout-address {
  position: relative;
  padding-left: 32px;
}

.rout-address.with-list {
  padding-left: 0;
}

.rout-address.with-list .list-activity {
  margin: 0;
}

.rout-address .rout-icons {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  color: #999999;
  font-size: 160%;
}

.rout-address .address {
  position: relative;
  padding-right: 128px;
  margin-bottom: 6px;
}

.rout-address .address .address-info {
  font-size: 120%;
  color: #999999;
}

.rout-address .address .rout-icons {
  font-size: 120%;
}

.rout-address .address .address-map {
  position: absolute;
  z-index: 2;
  top: 0;
  right: 0;
  font-weight: 500;
}

@media (max-width: 768px) {
  .rout-address .address .address-map {
    top: auto;
    bottom: -25.6px;
  }
}

@media (max-width: 768px) {
  .rout-address .address {
    padding-right: 0;
  }
  .rout-address .address .address-map {
    position: relative;
    bottom: auto;
    margin-top: 16px;
  }
}

.figure-slide img,
.with-owl-nav img {
  display: block;
  width: 100%;
}

.figure-slide .owl-nav button.owl-next,
.figure-slide .owl-nav button.owl-prev,
.with-owl-nav .owl-nav button.owl-next,
.with-owl-nav .owl-nav button.owl-prev {
  -webkit-transition: box-shadow 0.3s ease-in-out;
  -o-transition: box-shadow 0.3s ease-in-out;
  transition: box-shadow 0.3s ease-in-out;
  display: block;
  position: absolute;
  top: 50%;
  margin-top: -16.66667px;
  z-index: 1100;
  cursor: pointer;
  text-align: center;
  background-color: #fff;
  line-height: 50px;
  height: 50px;
  width: 50px;
  font-size: 25px;
  border-radius: 100%;
}

.figure-slide .owl-nav button.owl-next:hover,
.figure-slide .owl-nav button.owl-prev:hover,
.with-owl-nav .owl-nav button.owl-next:hover,
.with-owl-nav .owl-nav button.owl-prev:hover {
  color: #0080ff;
  text-decoration: none;
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.1), 0 6px 30px 5px rgba(0, 0, 0, 0.1), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
}

.figure-slide .owl-nav button.owl-next:focus,
.figure-slide .owl-nav button.owl-prev:focus,
.with-owl-nav .owl-nav button.owl-next:focus,
.with-owl-nav .owl-nav button.owl-prev:focus {
  outline: none;
}

.figure-slide .owl-nav button.owl-next,
.with-owl-nav .owl-nav button.owl-next {
  right: -25px;
}

.figure-slide .owl-nav button.owl-prev,
.with-owl-nav .owl-nav button.owl-prev {
  left: -25px;
}

.figure-slide .owl-nav .disabled,
.with-owl-nav .owl-nav .disabled {
  opacity: 0.5;
  cursor: default;
}

@media (max-width: 768px) {
  .figure-slide .owl-nav button.owl-next,
  .figure-slide .owl-nav button.owl-prev,
  .with-owl-nav .owl-nav button.owl-next,
  .with-owl-nav .owl-nav button.owl-prev {
    border-radius: 4px;
    line-height: 33.33333px;
    height: 33.33333px;
    width: 33.33333px;
    font-size: 16.66667px;
    top: auto;
    bottom: 6px;
  }
  .figure-slide .owl-nav button.owl-next,
  .with-owl-nav .owl-nav button.owl-next {
    right: 6px;
  }
  .figure-slide .owl-nav button.owl-prev,
  .with-owl-nav .owl-nav button.owl-prev {
    left: auto;
    right: 50px;
  }
}

.itinerary-slide-card {
  border-top: 1px solid #bfbfbf;
  padding: 16px 40px;
}

.itinerary-slide img {
  display: block;
  width: 100%;
}

.itinerary-slide .owl-nav button.owl-next,
.itinerary-slide .owl-nav button.owl-prev {
  -webkit-transition: box-shadow 0.3s ease-in-out;
  -o-transition: box-shadow 0.3s ease-in-out;
  transition: box-shadow 0.3s ease-in-out;
  display: block;
  position: absolute;
  top: 50%;
  margin-top: -16.66667px;
  z-index: 1100;
  cursor: pointer;
  text-align: center;
  background-color: #e6e6e6;
  line-height: 25px;
  height: 33.33333px;
  width: 33.33333px;
  font-size: 16.66667px;
  border-radius: 4px;
}

.itinerary-slide .owl-nav button.owl-next:hover,
.itinerary-slide .owl-nav button.owl-prev:hover {
  color: #0080ff;
  text-decoration: none;
}

.itinerary-slide .owl-nav button.owl-next:focus,
.itinerary-slide .owl-nav button.owl-prev:focus {
  outline: none;
}

.itinerary-slide .owl-nav button.owl-next {
  right: -41.66667px;
}

.itinerary-slide .owl-nav button.owl-prev {
  left: -41.66667px;
}

.itinerary-slide .owl-nav .disabled {
  opacity: 0.5;
  cursor: default;
}

@media (max-width: 768px) {
  .itinerary-slide .owl-nav button.owl-next,
  .itinerary-slide .owl-nav button.owl-prev {
    border-radius: 4px;
    line-height: 33.33333px;
    height: 33.33333px;
    width: 33.33333px;
    font-size: 16.66667px;
    top: auto;
    bottom: 6px;
  }
  .itinerary-slide .owl-nav button.owl-next {
    right: 6px;
  }
  .itinerary-slide .owl-nav button.owl-prev {
    left: auto;
    right: 50px;
  }
}

.fare-calander-artical {
  padding: 0 40px;
  margin-bottom: 6px;
  background-color: #e6e6e6;
  border: 1px solid #d9d9d9;
}

.fare-calander-slide {
  background: #fff;
}

.fare-calander-slide img {
  display: block;
  width: 100%;
}

.fare-calander-slide .center .dept-icons {
  position: relative;
}

.fare-calander-slide .center .dept-icons:after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  height: 3px;
  top: 0;
  z-index: 2;
  background-color: #F04124;
}

.fare-calander-slide .owl-nav button.owl-next,
.fare-calander-slide .owl-nav button.owl-prev {
  -webkit-transition: box-shadow 0.3s ease-in-out;
  -o-transition: box-shadow 0.3s ease-in-out;
  transition: box-shadow 0.3s ease-in-out;
  display: block;
  position: absolute;
  top: 50%;
  margin-top: -16.66667px;
  z-index: 1100;
  cursor: pointer;
  text-align: center;
  line-height: 25px;
  height: 33.33333px;
  width: 33.33333px;
  font-size: 16.66667px;
  border-radius: 4px;
}

.fare-calander-slide .owl-nav button.owl-next:hover,
.fare-calander-slide .owl-nav button.owl-prev:hover {
  color: #0080ff;
  text-decoration: none;
}

.fare-calander-slide .owl-nav button.owl-next:focus,
.fare-calander-slide .owl-nav button.owl-prev:focus {
  outline: none;
}

.fare-calander-slide .owl-nav button.owl-next {
  right: -41.66667px;
}

.fare-calander-slide .owl-nav button.owl-prev {
  left: -41.66667px;
}

.fare-calander-slide .owl-nav .disabled {
  opacity: 0.5;
  cursor: default;
}

.package-hightlight,
.hightlight-scroll {
  padding: 0;
  -webkit-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.package-hightlight:before, .package-hightlight:after,
.hightlight-scroll:before,
.hightlight-scroll:after {
  display: table;
  content: " ";
}

.package-hightlight:after,
.hightlight-scroll:after {
  clear: both;
}

.package-hightlight.fixed-top,
.hightlight-scroll.fixed-top {
  display: none;
  top: 0;
  position: fixed;
  z-index: 1000;
}

.package-hightlight.fixed-top.is-affixed,
.hightlight-scroll.fixed-top.is-affixed {
  display: block;
}

.package-hightlight.fixed-top.is-affixed .inner-wrapper-sticky,
.hightlight-scroll.fixed-top.is-affixed .inner-wrapper-sticky {
  left: auto !important;
  width: inherit !important;
}

.package-hightlight.fixed-top.is-affixed .inner-wrapper-sticky:before,
.hightlight-scroll.fixed-top.is-affixed .inner-wrapper-sticky:before {
  content: '';
  position: absolute;
  top: 0;
  left: 48px;
  right: 48px;
  height: 20px;
  box-shadow: 0 20px 30px 15px rgba(0, 0, 0, 0.1), 0 10px 40px 20px rgba(0, 0, 0, 0.08), 0 10px 15px -8px rgba(0, 0, 0, 0.1);
  background-color: #0080ff;
  display: block;
}

.package-hightlight .info-list,
.hightlight-scroll .info-list {
  width: 100%;
  margin: 0;
  padding: 0;
  position: relative;
  background-color: #fff;
}

.package-hightlight .info-list:before, .package-hightlight .info-list:after,
.hightlight-scroll .info-list:before,
.hightlight-scroll .info-list:after {
  display: table;
  content: " ";
}

.package-hightlight .info-list:after,
.hightlight-scroll .info-list:after {
  clear: both;
}

.package-hightlight .info-list li,
.hightlight-scroll .info-list li {
  float: left;
  list-style: none;
  position: relative;
}

.package-hightlight .info-list li a,
.hightlight-scroll .info-list li a {
  display: block;
  position: relative;
  padding: 15px 15px;
  text-transform: uppercase;
  color: #4b626d;
  font-weight: 500;
  font-size: 120%;
}

.package-hightlight .info-list li a:after,
.hightlight-scroll .info-list li a:after {
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  position: absolute;
  z-index: 1000;
  left: 50%;
  right: 50%;
  content: '';
  background-color: #F04124;
  height: 3px;
  bottom: 0;
}

.package-hightlight .info-list li a:hover, .package-hightlight .info-list li a:focus,
.hightlight-scroll .info-list li a:hover,
.hightlight-scroll .info-list li a:focus {
  color: #F04124;
}

.package-hightlight .info-list li a:hover:after, .package-hightlight .info-list li a:focus:after,
.hightlight-scroll .info-list li a:hover:after,
.hightlight-scroll .info-list li a:focus:after {
  left: 4.5px;
  right: 4.5px;
}

.package-hightlight .info-list li.active a, .package-hightlight .info-list li.active a:hover, .package-hightlight .info-list li.active a:focus,
.hightlight-scroll .info-list li.active a,
.hightlight-scroll .info-list li.active a:hover,
.hightlight-scroll .info-list li.active a:focus {
  color: #F04124;
}

.package-hightlight .info-list li.active a:after, .package-hightlight .info-list li.active a:hover:after, .package-hightlight .info-list li.active a:focus:after,
.hightlight-scroll .info-list li.active a:after,
.hightlight-scroll .info-list li.active a:hover:after,
.hightlight-scroll .info-list li.active a:focus:after {
  left: 4.5px;
  right: 4.5px;
}

.widget-slider {
  margin-bottom: 6px;
  background-color: #f0f0f0;
}

.widget-slider .info-list {
  width: 100%;
  margin: 0;
  padding: 0;
  position: relative;
}

.widget-slider .info-list:before, .widget-slider .info-list:after {
  display: table;
  content: " ";
}

.widget-slider .info-list:after {
  clear: both;
}

.widget-slider .info-list li {
  float: left;
  list-style: none;
  position: relative;
}

.widget-slider .info-list li a {
  display: block;
  position: relative;
  padding: 12px 16px;
  text-transform: uppercase;
  color: #4b626d;
  font-weight: 500;
  font-size: 100%;
}

.widget-slider .info-list li a:after {
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  position: absolute;
  z-index: 1000;
  left: 50%;
  right: 50%;
  content: '';
  background-color: #F04124;
  height: 3px;
  bottom: 0;
}

.widget-slider .info-list li a:hover, .widget-slider .info-list li a:focus {
  color: #F04124;
}

.widget-slider .info-list li a:hover:after, .widget-slider .info-list li a:focus:after {
  left: 4.5px;
  right: 4.5px;
}

.widget-slider .info-list li.active a, .widget-slider .info-list li.active a:hover, .widget-slider .info-list li.active a:focus {
  color: #F04124;
}

.widget-slider .info-list li.active a:after, .widget-slider .info-list li.active a:hover:after, .widget-slider .info-list li.active a:focus:after {
  left: 4.5px;
  right: 4.5px;
}

.hightlight-inclusions .inclusions-list {
  margin: 0;
  padding: 0;
}

.hightlight-inclusions .inclusions-list:before, .hightlight-inclusions .inclusions-list:after {
  display: table;
  content: " ";
}

.hightlight-inclusions .inclusions-list:after {
  clear: both;
}

.hightlight-inclusions .inclusions-list li {
  float: left;
  list-style: none;
  position: relative;
}

.hightlight-inclusions .inclusions-list li a {
  font-size: 90%;
  display: block;
  position: relative;
  padding: 16px 16px;
  text-transform: uppercase;
  color: #4b626d;
  font-weight: 500;
}

.hightlight-inclusions .inclusions-list li a:after {
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  position: absolute;
  z-index: 1000;
  left: 50%;
  right: 50%;
  content: '';
  background-color: #F04124;
  height: 2px;
  bottom: 0;
  filter: alpha(opacity=0);
  opacity: 0;
}

.hightlight-inclusions .inclusions-list li a:hover, .hightlight-inclusions .inclusions-list li a:focus {
  color: #F04124;
}

.hightlight-inclusions .inclusions-list li a:hover:after, .hightlight-inclusions .inclusions-list li a:focus:after {
  filter: alpha(opacity=100);
  opacity: 1;
  left: 4.5px;
  right: 4.5px;
}

.hightlight-inclusions .inclusions-list li.active a, .hightlight-inclusions .inclusions-list li.active a:hover, .hightlight-inclusions .inclusions-list li.active a:focus {
  color: #F04124;
}

.hightlight-inclusions .inclusions-list li.active a:after, .hightlight-inclusions .inclusions-list li.active a:hover:after, .hightlight-inclusions .inclusions-list li.active a:focus:after {
  filter: alpha(opacity=100);
  opacity: 1;
  left: 4.5px;
  right: 4.5px;
}

.hightlight-inclusions .inclusions-list.single-list li a {
  padding: 6px 16px;
}

.hightlight-inclusions .tab-content:before, .hightlight-inclusions .tab-content:after {
  display: table;
  content: " ";
}

.hightlight-inclusions .tab-content:after {
  clear: both;
}

.inclusions-tablist ul {
  margin: 0;
  padding: 0;
}

.inclusions-tablist ul li {
  position: relative;
  list-style: none;
  padding: 3px 0;
  padding-left: 25px;
}

.inclusions-tablist ul li:after {
  content: '';
  position: absolute;
  background-color: #8bc34a;
  width: 10px;
  height: 10px;
  left: 0;
  top: 8px;
  border-radius: 2px;
}

.package-hightlight-panel {
  margin-bottom: 16px;
}

.package-hightlight-panel .heading-page .heading {
  margin: 0;
  font-size: 160%;
  color: #0080ff;
}

.package-hightlight-panel .header {
  margin: 0;
  margin-bottom: 10px;
  font-size: 115%;
  color: #0080ff;
}

.package-hightlight-panel .hightlight-content {
  text-align: justify;
}

.package-hightlight-panel .hightlight-days {
  margin-bottom: 25px;
}

.hightlight-rates.with-package {
  border: 2px solid #bfbfbf;
  padding: 16px 24px;
}

.hightlight-rates.with-package .inclusions-list {
  background-color: #e6e6e6;
}

.widget-itinerary .widget-header {
  position: relative;
  margin-bottom: 6px;
}

.widget-itinerary .widget-header .count {
  position: relative;
  font-size: 130%;
  font-weight: 600;
}

.widget-itinerary .widget-header .day {
  color: #0080ff;
  font-size: 130%;
  font-weight: 600;
}

.widget-itinerary .widget-header .destinaction {
  margin-left: 16px;
  font-size: 80%;
  color: #6f6f6f;
}

.widget-itinerary .widget-header:after {
  content: '';
  position: absolute;
  background-color: #bfbfbf;
  width: 6px;
  height: 6px;
  left: -12px;
  top: 11.42857px;
  border-radius: 40px;
}

.widget-itinerary .widget-header:before {
  content: '';
  position: absolute;
  background-color: #bfbfbf;
  height: 2px;
  left: -22.4px;
  width: 16px;
  top: 13.33333px;
}

.available-hightlight:before, .available-hightlight:after {
  display: table;
  content: " ";
}

.available-hightlight:after {
  clear: both;
}

.available-hightlight .Available-lit {
  margin: 0;
  padding: 0;
}

.available-hightlight .Available-lit li {
  float: left;
  list-style: none;
  position: relative;
  margin-right: 20px;
}

.available-hightlight .Available-lit li a {
  color: #F04124;
  font-weight: 500;
}

.available-hightlight .Available-lit li .icons {
  margin-right: 10px;
  color: #333333;
}

.available-hightlight .Available-lit li a:hover {
  color: #0080ff;
}

.available-hightlight .Available-lit li a:hover .icons {
  color: #0080ff;
}

.product-detail-sidebar {
  float: right;
  position: relative;
}

@media (max-width: 768px) {
  .product-detail-sidebar {
    float: none;
  }
}

.detail-sidebar-inner {
  padding: 32px;
  border-radius: 4px;
  border: 2px dashed #bfbfbf;
  -webkit-transition: all 0.05s ease-in;
  -o-transition: all 0.05s ease-in;
  transition: all 0.05s ease-in;
}

@media (max-width: 768px) {
  .detail-sidebar-inner {
    border: 0;
    border-radius: 0;
    margin-top: 0;
    padding: 0;
    margin-bottom: 32px;
  }
}

.detail-sidebar-inner.without-fixed {
  margin-top: 32px;
}

.detail-sidebar-inner.fixed-top {
  top: 0px;
  border: 0;
  position: fixed;
  z-index: 888;
  background-color: #fff;
  margin: 0;
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.1), 0 6px 30px 5px rgba(0, 0, 0, 0.1), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
}

.detail-sidebar-inner .heading-page .heading {
  font-size: 140%;
  border-bottom: 2px solid #bfbfbf;
  padding-bottom: 9px;
}

.detail-sidebar-inner .link-inquire {
  background-color: #fff;
  color: #F04124;
  border: 1px solid #F04124;
  display: block;
}

.detail-sidebar-inner .new-price {
  line-height: 1;
  font-size: 200%;
  font-weight: 600;
  margin-right: 10px;
}

.detail-sidebar-inner .new-price .icons {
  font-size: 80%;
  margin-right: 2px;
}

.detail-sidebar-inner .new-price .price {
  font-size: 120%;
}

.detail-sidebar-inner .old-price {
  line-height: 1;
  font-size: 120%;
  font-weight: 600;
  color: #6f6f6f;
}

.detail-sidebar-inner .old-price .icons {
  font-size: 80%;
  margin-right: 2px;
}

.detail-sidebar-inner .old-price .price {
  font-size: 120%;
  text-decoration: line-through;
}

.detail-sidebar-inner .product-price-filter {
  padding: 6px 0;
}

.detail-sidebar-inner .product-price-filter.with-padding {
  padding: 16px 0;
}

.detail-sidebar-inner .package-validity {
  padding-top: 16px;
}

.detail-sidebar-inner .package-validity .tittle {
  font-weight: 300;
  font-size: 90%;
}

.detail-sidebar-inner .package-validity .time-outer {
  color: #607d8b;
  font-size: 110%;
}

.detail-sidebar-inner .package-validity .time-outer .or {
  margin: 0 10px;
  color: #333333;
  text-transform: uppercase;
  font-weight: 600;
}

.detail-sidebar-inner .packageid-outer {
  margin-bottom: 6px;
}

.detail-sidebar-inner .packageid-outer:before, .detail-sidebar-inner .packageid-outer:after {
  display: table;
  content: " ";
}

.detail-sidebar-inner .packageid-outer:after {
  clear: both;
}

.detail-sidebar-inner .package-id {
  position: relative;
}

.detail-sidebar-inner .package-id .tittle {
  color: gray;
}

.detail-sidebar-inner .package-id .packid {
  color: #6f6f6f;
  font-weight: 600;
}

.sidebar-sticky .sticky-card {
  text-align: center;
  border-radius: 4px;
  border: 2px dashed #bfbfbf;
  padding: 32px;
  padding-bottom: 6px;
}

.sidebar-sticky .sticky-card .heading {
  position: relative;
  margin: 0;
  padding: 0;
  margin-bottom: 16px;
  font-weight: 700;
  font-size: 160%;
  padding-bottom: 16px;
}

.sidebar-sticky .sticky-card .heading:after {
  z-index: 1;
  content: '';
  position: absolute;
  bottom: 0;
  height: 2px;
  left: 30%;
  right: 30%;
  background-color: #0080ff;
}

.sidebar-sticky .sticky-card .booknow-widget .price-total {
  font-size: 160%;
}

.sidebar-sticky .sticky-card .booknow-widget .package-price {
  margin: 6px auto;
  font-size: 150%;
}

.sidebar-sticky .sticky-card .controls,
.sidebar-sticky .sticky-card .ask-btn {
  margin: 19.2px auto;
}

.sidebar-sticky .sticky-card .controls .icons,
.sidebar-sticky .sticky-card .ask-btn .icons {
  margin-right: 3px;
}

.sidebar-sticky .sticky-card .valid-text .text {
  display: block;
  font-weight: 800;
}

.sidebar-sticky .sticky-card .valid-text .valid {
  font-size: 120%;
}

.sidebar-sticky .sticky-card .valid-text {
  margin-top: 24px;
}

.sidebar-sticky .sticky-card .package-code {
  position: relative;
  font-size: 110%;
  padding-top: 16px;
  text-transform: uppercase;
}

.sidebar-sticky .sticky-card .package-code:after {
  z-index: 1;
  content: '';
  position: absolute;
  top: 0;
  height: 2px;
  left: 30%;
  right: 30%;
  background-color: #0080ff;
}

.sidebar-sticky .sticky-card .package-code .code {
  font-weight: 600;
}

.sidebar-sticky .sticky-code {
  margin: 24px auto;
  background-color: #f7f7f7;
  border-radius: 4px;
  border: 2px dashed #bfbfbf;
  padding: 24px 32px;
}

.sidebar-sticky .sticky-code .package-code {
  position: relative;
  font-size: 110%;
  padding-bottom: 6px;
  margin-bottom: 16px;
  text-transform: uppercase;
}

.sidebar-sticky .sticky-code .package-code:after {
  z-index: 1;
  content: '';
  position: absolute;
  bottom: 0;
  height: 2px;
  width: 100px;
  left: 0;
  background-color: #0080ff;
}

.sidebar-sticky .sticky-code .package-code .code {
  font-weight: 600;
}

.sidebar-sticky .sticky-code .header {
  margin: 0;
  padding: 0;
  margin-bottom: 16px;
}

.sidebar-sticky .sticky-code .sticky-contect {
  margin-top: 24px;
  font-size: 120%;
}

.sidebar-sticky .sticky-code .sticky-contect .icons {
  margin-right: 6px;
}

.package-views {
  padding-bottom: 5px;
}

.package-views:before, .package-views:after {
  display: table;
  content: " ";
}

.package-views:after {
  clear: both;
}

.package-views .view-list {
  margin: 0;
  padding: 0;
}

.package-views .view-list li {
  float: left;
  list-style: none;
  position: relative;
  margin-right: 20px;
}

.package-views .view-list li a {
  color: #6f6f6f;
  font-weight: 500;
}

.package-views .view-list li .icons {
  margin-right: 10px;
  color: #F04124;
}

.package-views .view-list li a:hover {
  color: #0080ff;
}

.package-views .view-list li a:hover .icons {
  color: #0080ff;
}

.guests-booknow {
  padding: 6px 0;
}

.guests-booknow:before, .guests-booknow:after {
  display: table;
  content: " ";
}

.guests-booknow:after {
  clear: both;
}

.guests-booknow .list-out {
  display: block;
}

.guests-booknow .list-out li {
  float: left;
  width: 33%;
  padding: 0 3px;
  font-size: 120%;
  font-weight: 600;
}

.guests-booknow .list-out li .fig {
  width: 28.8px;
  float: left;
}

.guests-booknow .list-out li img {
  display: block;
  width: 100%;
}

.booknow-plist {
  position: relative;
  font-size: 120%;
  margin-bottom: 16px;
}

.booknow-plist .count {
  margin-left: 3px;
  color: gray;
  font-size: 80%;
  font-weight: 500;
}

.booknow-plist .count .icons {
  font-size: 90%;
  margin-left: 2px;
}

.booknow-plist .booking-gst,
.booknow-plist .booking-ammount {
  position: relative;
  line-height: 1.3;
}

.booknow-plist .booking-gst.with-Coopan,
.booknow-plist .booking-ammount.with-Coopan {
  padding: 6px 0;
}

.booknow-plist .booking-gst.with-Coopan .booking-price,
.booknow-plist .booking-ammount.with-Coopan .booking-price {
  top: 4.61538px;
}

.booknow-plist .text {
  font-size: 90%;
  font-weight: 600;
}

.booknow-plist .booking-price {
  position: absolute;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 2;
  font-size: 120%;
}

.booknow-plist .booking-price .icons {
  font-size: 75%;
  margin-right: 2px;
}

.booknow-plist.with-detail {
  margin-top: 6px;
  padding-top: 6px;
  border-top: 1px dashed #bfbfbf;
}

.booknow-plist .large {
  font-size: 140%;
  font-weight: 500;
}

.fixed-top {
  position: fixed;
  top: 0;
  z-index: 1000;
}

.section-padding {
  padding: 6px 0;
}

.info-container-ul {
  margin: 0;
  padding: 0;
}

.info-container-ul li {
  list-style: none;
  position: relative;
  padding-left: 32px;
  margin: 16px auto;
}

.info-container-ul li .icons {
  position: absolute;
  top: 3px;
  left: 0;
  z-index: 2;
  font-size: 120%;
}

.info-container-ul li p {
  margin: 0;
  padding: 0;
}

.single-flight-review .hightlight-inclusions {
  margin-top: 16px;
}

.booking-modal-section {
  position: relative;
}

.booking-modal-section:before, .booking-modal-section:after {
  display: table;
  content: " ";
}

.booking-modal-section:after {
  clear: both;
}

.close-modal {
  position: absolute;
  font-size: 200%;
  border: 2px solid #bfbfbf;
  border-radius: 40px;
  right: 24px;
  top: 24px;
  width: 35px;
  height: 35px;
  line-height: 17.5px;
  z-index: 1100;
  outline: 0 !important;
  outline-offset: 0 !important;
}

.close-modal:hover {
  border-color: #0080ff;
  color: #0080ff;
}

.booking-modal-left {
  position: relative;
  z-index: 2;
  float: left;
  width: 50%;
  padding: 32px;
  padding-right: 48px;
}

.booking-modal-left .heading-modal .heading {
  margin: 0;
  padding: 0;
  margin-bottom: 10px;
  font-size: 200%;
}

.booking-modal-left .heading-modal .sub-heading {
  margin: 0;
  margin-bottom: 10px;
  font-size: 90%;
  color: #6f6f6f;
}

.booking-modal-left .number-sec {
  font-size: 140%;
  font-weight: 300;
}

.booking-modal-left .number-sec:before, .booking-modal-left .number-sec:after {
  display: table;
  content: " ";
}

.booking-modal-left .number-sec:after {
  clear: both;
}

.booking-modal-left .number-sec .thumbnail-list {
  position: relative;
  padding: 8px 0;
  padding-left: 68px;
  min-height: 48px;
}

.booking-modal-left .number-sec .thumbnail-img {
  position: absolute;
  width: 48px;
  left: 0;
  top: 16px;
  z-index: 2;
}

.booking-modal-left .number-sec .thumbnail-img img {
  display: block;
  width: 100%;
}

.footer-modal {
  margin-top: 16px;
}

.footer-modal .link-booknow {
  max-width: 260px;
  display: block;
  margin: 0 auto;
}

@media (max-width: 768px) {
  .footer-modal .link-booknow .link-booknow {
    max-width: initial;
  }
}

.sidebar-link {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 1px 5px 0 rgba(0, 0, 0, 0.08), 0 3px 1px -2px rgba(0, 0, 0, 0.08);
  list-style: none;
  margin: 0;
  padding: 0;
  z-index: 10;
  margin-bottom: 20px;
}

.sidebar-link li {
  border: none;
  display: block;
  margin: 0;
  padding: 0;
}

.sidebar-link li a {
  border: 0 none;
  display: block;
  margin: 0;
  color: #fff;
  padding: 12px 15px;
  position: relative;
  text-decoration: none;
}

.sidebar-link li a .side-arrow {
  position: absolute;
  right: 15px;
  top: 50%;
  margin-top: -12px;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.sidebar-link li a .side-arrow:before {
  font-size: 70%;
  content: '\f054';
  font-weight: 600;
  font-family: "Font Awesome 5 Free";
}

.sidebar-link li a:after {
  content: '';
  left: 0;
  width: 3px;
  top: 0;
  bottom: 0;
  z-index: 2;
  position: absolute;
}

.sidebar-link li a:hover, .sidebar-link li a:focus {
  background-color: rgba(0, 128, 255, 0.05);
}

.sidebar-link li a:hover:after, .sidebar-link li a:focus:after {
  background-color: #0080ff;
}

.sidebar-link li.active > a {
  color: #F04124;
  background-color: #f2f2f2;
}

.sidebar-link li.active > a:after {
  background-color: #0080ff;
}

.sidebar-link li.active > ul.sub-menu {
  display: block;
  padding: 0;
  background: rgba(0, 0, 0, 0.05);
}

.sidebar-link li.active > a > .side-arrow {
  filter: alpha(opacity=100);
  opacity: 1;
}

.sidebar-link li.active > a > .side-arrow:before {
  font-weight: 600;
  font-family: "Font Awesome 5 Free";
  content: '\f078';
}

.sidebar-link li ul.sub-menu {
  clear: both;
  list-style: none;
  margin: 0;
  padding: 0;
  display: none;
  background: rgba(0, 0, 0, 0.05);
}

.sidebar-link li ul.sub-menu li {
  background: none;
  margin-bottom: 0;
  margin-left: 0;
  margin-right: 0;
  margin-top: 1px !important;
  padding: 0;
}

.sidebar-link li ul.sub-menu li a {
  display: block;
  font-size: 90%;
  border: none;
  margin: 0;
  padding-bottom: 10px;
  padding-left: 30px !important;
  padding-right: 15px;
  padding-top: 10px;
  text-decoration: none;
}

.sidebar-link li ul.sub-menu li a .side-arrow {
  margin-right: 22px;
}

.sidebar-link li ul.sub-menu li a:after {
  display: none;
}

.sidebar-link li ul.sub-menu li.current > a {
  background: rgba(0, 0, 0, 0.05);
}

.sidebar-link li ul.sub-menu li.dropdown-header {
  padding-left: 30px !important;
}

.sidebar-link li ul.sub-menu li.divider {
  height: 1px;
  margin: 9px 0;
  overflow: hidden;
  margin-left: 30px !important;
}

.sidebar-link li ul.sub-menu li ul.sub-menu {
  margin: 0;
}

.sidebar-link li ul.sub-menu li ul.sub-menu li a {
  padding-left: 45px !important;
}

.sidebar-link li ul.sub-menu li li ul.sub-menu li a {
  padding-left: 55px !important;
}

.sidebar-navigation {
  list-style: none;
  margin: 0;
  padding: 0;
  background-color: #373737;
  border: 1px solid #cccccc;
  box-shadow: 0 2px 4px 2px rgba(0, 0, 0, 0.03), 0 1px 4px 2px rgba(0, 0, 0, 0.01), 0 1px 3px -1px rgba(0, 0, 0, 0.01);
  border-radius: 4px;
}

.sidebar-navigation.mobile-menu {
  background-color: #fff;
}

.sidebar-navigation li {
  border: none;
  display: block;
  margin: 0;
  padding: 0;
}

.sidebar-navigation li a {
  border: 0 none;
  display: block;
  margin: 0;
  color: #fff;
  padding: 12px 15px;
  position: relative;
  text-decoration: none;
}

.sidebar-navigation li a .icons {
  margin-right: 16px;
}

.sidebar-navigation li a .title {
  font-size: 100%;
}

.sidebar-navigation li a .side-arrow {
  position: absolute;
  right: 15px;
  top: 50%;
  margin-top: -9px;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.sidebar-navigation li a .side-arrow:before {
  font-size: 100%;
  content: '\f054';
  font-weight: 600;
  font-family: "Font Awesome 5 Free";
}

.sidebar-navigation li a:after {
  content: '';
  left: 0;
  width: 3px;
  top: 0;
  bottom: 0;
  z-index: 2;
  position: absolute;
}

.sidebar-navigation li a:hover, .sidebar-navigation li a:focus {
  background-color: #0080ff;
}

.sidebar-navigation li a:hover:after, .sidebar-navigation li a:focus:after {
  background-color: #0066cc;
}

.sidebar-navigation li.active > a {
  background-color: #d32a0e;
}

.sidebar-navigation li.active > a:after {
  background-color: #a3210b;
}

.sidebar-navigation li.active > a .title {
  font-weight: 600;
}

.sidebar-navigation li.active > ul.sub-menu {
  display: block;
  padding: 0;
  background-color: #e6e6e6;
}

.sidebar-navigation li.active > a > .side-arrow {
  filter: alpha(opacity=100);
  opacity: 1;
}

.sidebar-navigation li.active > a > .side-arrow:before {
  font-weight: 600;
  font-family: "Font Awesome 5 Free";
  content: '\f078';
}

.sidebar-navigation li ul.sub-menu {
  clear: both;
  list-style: none;
  margin: 0;
  padding: 0;
  display: none;
  background-color: #e6e6e6;
}

.sidebar-navigation li ul.sub-menu li {
  background: none;
  margin-bottom: 0;
  margin-left: 0;
  margin-right: 0;
  padding: 0;
}

.sidebar-navigation li ul.sub-menu li a {
  display: block;
  font-size: 90%;
  color: #000;
  border: none;
  margin: 0;
  padding-bottom: 10px;
  padding-right: 15px;
  padding-top: 10px;
  text-decoration: none;
}

.sidebar-navigation li ul.sub-menu li a .side-arrow {
  margin-right: 22px;
}

.sidebar-navigation li ul.sub-menu li a:after {
  display: none;
}

.sidebar-navigation li ul.sub-menu li a:hover, .sidebar-navigation li ul.sub-menu li a:focus {
  background-color: transparent;
  color: #F04124;
}

.sidebar-navigation li ul.sub-menu li.current > a {
  background: rgba(0, 0, 0, 0.05);
}

.sidebar-navigation li ul.sub-menu li.dropdown-header {
  padding-left: 30px !important;
}

.sidebar-navigation li ul.sub-menu li.divider {
  height: 1px;
  margin: 9px 0;
  overflow: hidden;
  margin-left: 30px !important;
}

.sidebar-navigation li ul.sub-menu li ul.sub-menu {
  margin: 0;
}

.sidebar-navigation li ul.sub-menu li ul.sub-menu li a {
  padding-left: 45px !important;
}

.sidebar-navigation li ul.sub-menu li li ul.sub-menu li a {
  padding-left: 55px !important;
}

.coupon-code {
  margin-bottom: 20px;
}

.side-user-card {
  padding: 15px 0;
  position: relative;
  min-height: 72px;
  border: 1px solid #bfbfbf;
  margin-bottom: 20px;
  border-radius: 4px;
}

.side-user-card .user-avatar {
  width: 72px;
  height: 72px;
  margin: 0 auto;
  margin-bottom: 10px;
}

.side-user-card .user-avatar img {
  width: 100%;
  height: 100%;
  display: block;
  border-radius: 100%;
}

.side-user-card .user-about {
  text-align: center;
  padding: 0 10px;
}

.side-user-card .user-about .user-name {
  display: block;
  margin: 5px 0;
}

.side-user-card .user-about .user-name,
.side-user-card .user-about .email-id {
  font-size: 90%;
}

.side-user-card .user-about .user-id {
  display: block;
  font-size: 120%;
  text-transform: uppercase;
}

.side-user-card .user-about .email-id {
  display: block;
  font-size: 80%;
}

.side-user-card .user-about .email-id a {
  color: #6f6f6f;
}

.side-user-card .user-about .email-id a:hover {
  color: #0080ff;
}

.table-widget-pagination {
  border-top: 1px solid #bfbfbf;
  padding: 16px 0;
  padding-bottom: 0;
  font-weight: 500;
}

.table-widget-pagination:before, .table-widget-pagination:after {
  display: table;
  content: " ";
}

.table-widget-pagination:after {
  clear: both;
}

.table-widget-pagination .pagination-counter {
  float: left;
}

.table-widget-pagination .pagination-counter .text {
  padding: 6px 0;
}

.table-widget-pagination .pagination-outer {
  float: right;
}

.table-widget-pagination .pagination {
  margin: 0;
}

.relative-tour-section .heading {
  font-weight: 600;
}

.relative-tour-card {
  -webkit-transition: box-shadow 0.3s ease-in-out;
  -o-transition: box-shadow 0.3s ease-in-out;
  transition: box-shadow 0.3s ease-in-out;
  margin: 15px auto;
  overflow: hidden;
  background-color: #fff;
  border-radius: 4px;
  position: relative;
}

.relative-tour-card .packages-card-img-wrap {
  position: relative;
}

.relative-tour-card .relative-tour-img-wrap {
  position: relative;
}

.relative-tour-card .relative-tour-image {
  overflow: hidden;
  height: 300px;
}

.relative-tour-card .relative-tour-image a {
  display: block;
}

.relative-tour-card .relative-tour-image img {
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  display: block;
  width: 100%;
  height: 300px;
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  filter: grayscale(100%);
}

.relative-tour-card .about-contant {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 2;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 50%);
  background-image: -o-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 50%);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 50%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#99000000', GradientType=0);
  background-repeat: repeat-x;
  padding: 5px 10px;
  padding-top: 15px;
}

.relative-tour-card .about-contant .tittle {
  color: #fff;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 120%;
  font-weight: 500;
}

.relative-tour-card .about-contant .address {
  color: #fff;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  position: relative;
}

.relative-tour-card .price-contant {
  padding: 5px 10px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  z-index: 2;
}

.relative-tour-card .price-contant:before, .relative-tour-card .price-contant:after {
  display: table;
  content: " ";
}

.relative-tour-card .price-contant:after {
  clear: both;
}

.relative-tour-card .package-price {
  position: relative;
  float: left;
  width: 50%;
  padding-right: 20px;
}

.relative-tour-card .link-button {
  position: relative;
  width: 50%;
  float: right;
}

.relative-tour-card .package-id-artical {
  padding-left: 20px;
  margin-top: 5px;
}

.relative-tour-card .package-id-artical .package-id {
  font-size: 120%;
  font-weight: 500;
}

.relative-tour-card .package-price {
  position: relative;
}

.relative-tour-card .package-price .tittle {
  color: inherit;
  margin-right: 10px;
}

.relative-tour-card .package-price .new-price {
  line-height: 1;
  font-weight: 600;
}

.relative-tour-card .package-price .new-price .fa {
  font-size: 90%;
  margin-right: 5px;
}

.relative-tour-card .package-price .new-price .price {
  font-size: 120%;
}

.relative-tour-card .package-price .about {
  color: #6f6f6f;
}

.relative-tour-card:hover {
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.1), 0 6px 30px 5px rgba(0, 0, 0, 0.1), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
}

.relative-tour-card:hover .relative-tour-image img {
  display: block;
  width: 100%;
  height: 300px;
  -webkit-filter: grayscale(0);
  -moz-filter: grayscale(0);
  filter: grayscale(0);
}

.new-price,
.price {
  line-height: 1;
  font-weight: 600;
}

.new-price .icon,
.new-price .fa,
.price .icon,
.price .fa {
  font-size: 80%;
  margin-right: 2px;
}

.new-price .price,
.price .price {
  font-size: 120%;
}

.new-price.mini .price,
.price.mini .price {
  font-size: 100%;
}

.new-price.large .price,
.price.large .price {
  font-size: 150%;
}

.blog-page-section {
  padding: 3em 0;
}

.widget-area {
  float: right;
}

.widget {
  margin-bottom: 32px;
}

.widget ul {
  margin: 0;
  padding: 0;
}

.widget ul:before, .widget ul:after {
  display: table;
  content: " ";
}

.widget ul:after {
  clear: both;
}

.widget ul li {
  list-style: none;
}

.widget ul li:hover a, .widget ul li:focus a {
  text-decoration: none;
}

.widget .button-action {
  margin-top: 16px;
}

.widget .widget-title {
  position: relative;
  font-size: 140%;
  margin: 0;
  padding-bottom: 16px;
  border-bottom: 1px solid #bfbfbf;
  margin-bottom: 16px;
}

.widget .widget-title:after {
  content: '';
  position: absolute;
  height: 1px;
  background: #0080ff;
  width: 30%;
  bottom: -1px;
  left: 0;
}

.widget .widget-title.with-edit {
  padding-right: 24px;
  position: relative;
}

.widget .widget-title.with-edit .text-edit {
  font-size: 70%;
  top: 24px;
  right: 24px;
  position: absolute;
  z-index: 2;
}

@media (max-width: 768px) {
  .widget .widget-title.with-edit .text-edit {
    position: relative;
    display: block;
    top: auto;
    right: auto;
    margin-top: 6px;
    font-size: 60%;
  }
}

.widget.widget-search .blog-search {
  position: relative;
  margin: 0;
}

.widget.widget-search .search-submit {
  position: absolute;
  top: 2px;
  bottom: 2px;
  right: 2px;
  min-width: 90px;
  z-index: 2;
  min-height: auto;
  border-radius: 4px;
}

.widget.widget-links li {
  position: relative;
}

.widget.widget-links li a {
  display: block;
  padding: 4px 0;
  color: #333333;
}

.widget.widget-links li .check-list {
  padding: 3px 0;
  font-weight: 500;
}

.widget.widget-links li .count {
  position: absolute;
  right: 0;
  top: 50%;
  margin-top: -8px;
  padding: 3px 5px;
  font-size: 80%;
  border-radius: 40px;
  background: #eeeeee;
}

.widget.widget-links li:hover a {
  color: #0080ff;
}

.widget.widget-links li:hover a .count {
  background-color: #0080ff;
  color: #fff;
}

.widget.widget-compare li {
  position: relative;
}

.widget.widget-compare li a {
  display: block;
  padding: 4px 0;
  color: #333333;
  font-size: 120%;
}

.widget.widget-compare li .count {
  position: absolute;
  right: 0;
  top: 50%;
  margin-top: -7.8px;
  font-size: 70%;
}

.widget.widget-compare li:hover a {
  color: #0080ff;
}

.widget.widget-compare li:hover a .count {
  color: #0080ff;
}

.widget.widget-categories li, .widget.widget-archive li {
  position: relative;
  padding-left: 20px;
}

.widget.widget-categories li:after, .widget.widget-archive li:after {
  content: '\f20e';
  font-family: "Font Awesome 5 Brands";
  color: inherit;
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -10px;
}

.widget.widget-categories li a, .widget.widget-archive li a {
  display: block;
  padding: 6px 0;
}

.widget.widget-categories li .count, .widget.widget-archive li .count {
  position: absolute;
  right: 0;
  top: 50%;
  margin-top: -8px;
  padding: 3px 5px;
  font-size: 80%;
  border-radius: 40px;
  background: #eeeeee;
}

.widget.widget-categories li:hover a .count, .widget.widget-archive li:hover a .count {
  background-color: #0080ff;
  color: #fff;
}

.widget.widget-recent_comments li {
  position: relative;
}

.widget.widget-recent_comments li .url:before {
  content: '\f099';
  font-family: "Font Awesome 5 Brands";
  color: #0080ff;
  font-weight: 900;
}

.widget.widget-recent_comments li a {
  display: block;
  padding: 6px 0;
}

.widget.widget-recent_comments li a.time {
  padding: 0;
  color: #0080ff;
}

.widget.widget-recent_comments li:hover .time {
  color: #F04124;
}

.widget .newslatter {
  padding: 20px;
  border-radius: 10px;
  background: #eeeeee;
  text-align: center;
}

.widget .newslatter .form-group {
  margin: 16px auto;
}

.widget .newslatter input {
  text-align: center;
}

.widget.widget-shipping {
  border-radius: 10px;
  background: #fff;
}

.widget.widget-shipping .shipping-info {
  padding: 6px 32px;
}

.widget.widget-shipping .form-group {
  margin: 16px auto;
}

.widget.widget-shipping .widget-title {
  padding: 16px 32px;
}

.widget.widget-shipping .widget-title:after {
  left: 32px;
}

.widget.widget-tag_cloud .tagcloud:before, .widget.widget-tag_cloud .tagcloud:after {
  display: table;
  content: " ";
}

.widget.widget-tag_cloud .tagcloud:after {
  clear: both;
}

.widget.widget-tag_cloud a,
.widget.widget-tag_cloud .tag-link {
  float: left;
  display: block;
  padding: 10px 20px;
  border: 1px solid #bfbfbf;
  margin: 2px;
}

.widget.widget-tag_cloud a:hover, .widget.widget-tag_cloud a:focus,
.widget.widget-tag_cloud .tag-link:hover,
.widget.widget-tag_cloud .tag-link:focus {
  background-color: #0080ff;
  border-color: #0080ff;
  color: #fff;
  text-decoration: none;
}

.widget.widget-meta:before, .widget.widget-meta:after {
  display: table;
  content: " ";
}

.widget.widget-meta:after {
  clear: both;
}

.widget.widget-meta a,
.widget.widget-meta .tag-link {
  float: left;
  display: block;
  padding: 10px 20px;
  border: 1px solid #bfbfbf;
  margin: 2px;
}

.widget.widget-meta a:hover, .widget.widget-meta a:focus,
.widget.widget-meta .tag-link:hover,
.widget.widget-meta .tag-link:focus {
  background-color: #0080ff;
  border-color: #0080ff;
  color: #fff;
  text-decoration: none;
}

.featured-thumbnail img {
  display: block;
  width: 100%;
  margin: 0 auto;
}

.comment-respond {
  margin-top: 30px;
}

.comment-respond .comment-reply-title {
  font-size: 160%;
  margin-bottom: 20px;
}

.comment-respond .btn {
  text-transform: uppercase;
}

.title {
  font-size: 200%;
  margin-bottom: 20px;
}

.title a {
  color: inherit;
  text-decoration: none;
}

.title a:hover {
  color: #0066cc;
}

@media (max-width: 480px) {
  .title {
    font-size: 120%;
    line-height: 1.5;
    font-weight: 600;
  }
}

.post-info {
  margin-top: 20px;
  color: #999999;
}

.post-info a {
  color: #999999;
}

.post-info a:hover, .post-info a:focus {
  color: #0080ff;
  text-decoration: none;
}

.post-info .theauthor,
.post-info .post-date {
  display: inline-block;
  border-right: 1px solid #bfbfbf;
  margin-right: 20px;
  padding-right: 20px;
}

@media (max-width: 480px) {
  .post-info {
    font-size: 90%;
  }
  .post-info .theauthor,
  .post-info .post-date {
    margin-right: 10px;
    padding-right: 10px;
  }
}

.post {
  margin-bottom: 30px;
}

.post .title {
  margin: 0;
  margin-bottom: 20px;
}

.post .cat-links {
  margin: 10px auto;
  display: block;
}

.post .cat-links a {
  color: #0080ff;
}

.post .cat-links a:hover, .post .cat-links a:focus {
  color: #F04124;
  text-decoration: none;
}

.post .post-info {
  border-top: 1px solid #bfbfbf;
  padding-top: 6px;
}

.product-detail-wrapper {
  padding: 4em 0;
}

.package-detail-card {
  margin-bottom: 40px;
}

.package-detail-card p {
  margin-bottom: 3px;
}

.package-detail-card .user-roduct .sign-heading {
  margin: 0;
  padding: 0;
  margin-bottom: 6px;
}

.package-detail-card .user-roduct .price {
  font-size: 160%;
  color: #eb575d;
  margin-bottom: 6px;
}

.package-detail-card .user-roduct .icd-ico {
  font-size: 90%;
}

.package-detail-card .user-roduct .addid {
  color: #999999;
}

.package-detail-card .user-roduct-abt {
  margin: 25px auto;
}

.flightsearch-bg {
  position: relative;
}

.flightsearch-bg .figure {
  overflow: hidden;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  left: 0;
  height: 330px;
}

.flightsearch-bg .figure img {
  display: block;
  width: 100%;
}

.flightsearch-bg .figure:after {
  position: absolute;
  content: '';
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
}

.flightsearch-section {
  position: relative;
  z-index: 3;
  padding: 80px 0;
}

.preference-section:before, .preference-section:after {
  display: table;
  content: " ";
}

.preference-section:after {
  clear: both;
}

ul {
  margin: 0;
  padding: 0;
}

ul li {
  list-style: none;
}

.airline-trip-artical {
  float: left;
}

.airline-trip-artical .select-trip {
  float: left;
  margin: 0;
  padding: 0;
}

.airline-trip-artical .select-trip li {
  list-style: none;
  float: left;
  border: 2px solid transparent;
  background-color: #fff;
  margin-right: 3px;
  padding: 6px 16px;
  border-radius: 4px;
  font-weight: 500;
  cursor: pointer;
}

.airline-trip-artical .select-trip li .icons {
  margin-right: 6px;
}

.airline-trip-artical .select-trip li.trip-active {
  color: #0080ff;
  border-color: #0066cc;
}

.airline-trip-artical .category-select-style {
  float: right;
  background-color: #fff;
  border-radius: 4px;
  margin-left: 16px;
}

.airline-preference-artical {
  float: right;
}

.airline-preference-artical .preference {
  float: left;
  margin: 0;
  padding: 0;
}

.airline-preference-artical .preference li {
  list-style: none;
  float: left;
  background-color: #fff;
  margin-right: 3px;
  border-radius: 4px;
  position: relative;
  cursor: pointer;
}

.airline-preference-artical .preference li .icons {
  margin-right: 6px;
}

.airline-preference-artical .preference li .list-text {
  padding: 6px 16px;
  display: block;
}

.airline-preference-artical .preference li.trip-active {
  color: #0080ff;
}

.airline-preference-artical .airline-preference {
  position: absolute;
  background-color: #fff;
  min-width: 180px;
  left: 0;
  top: 105%;
  z-index: 500;
  border-radius: 4px;
  box-shadow: 0 10px 15px 8px rgba(0, 0, 0, 0.1), 0 5px 20px 10px rgba(0, 0, 0, 0.08), 0 5px 8px -4px rgba(0, 0, 0, 0.1);
}

.airline-preference-artical .airlines {
  cursor: pointer;
  padding: 3px 6px;
  border-bottom: 1px solid #bfbfbf;
}

.airline-preference-artical .airlines:last-child {
  border: 0;
}

.airline-preference-artical .checkbox {
  cursor: pointer;
}

.airline-preference-artical .checkbox:hover {
  color: #0080ff;
}

.citytrip-tabs {
  background-color: #fff;
  padding: 16px;
  margin: 6px auto;
  border-radius: 4px;
  padding-bottom: 6px;
}

.citytrip-tabs:before, .citytrip-tabs:after {
  display: table;
  content: " ";
}

.citytrip-tabs:after {
  clear: both;
}

.add-city-panel {
  position: relative;
}

.add-city-panel .remove-city {
  width: 26px;
  height: 26px;
  line-height: 26px;
  padding: 0;
  margin-top: 6px;
}

.section-map {
  position: relative;
  background: url("..images/banner/icon-map.png") no-repeat top center;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain;
}

.embed-responsive-half {
  padding-bottom: 40%;
}

.item-image {
  position: relative;
  width: 48px;
  height: 48px;
}

.item-image img {
  display: block;
  width: 100%;
  height: 100%;
}

.table-input .text {
  display: block;
  margin-right: 16px;
}

.card-checkout {
  padding: 6px 16px;
}

.table-checkout {
  margin: 0;
}

.table-checkout .remove {
  top: -6px;
  left: -6px;
  width: 24px;
  height: 24px;
}

.table-checkout .header {
  font-size: 130%;
  font-weight: 600;
}

.table-checkout > thead > tr > th,
.table-checkout > thead > tr > td,
.table-checkout > tbody > tr > th,
.table-checkout > tbody > tr > td,
.table-checkout > tfoot > tr > th,
.table-checkout > tfoot > tr > td {
  padding: 3px;
  border-bottom: 1px solid #ddd;
  vertical-align: middle;
}

.table-checkout > thead > tr > th.subtotal,
.table-checkout > thead > tr > td.subtotal,
.table-checkout > tbody > tr > th.subtotal,
.table-checkout > tbody > tr > td.subtotal,
.table-checkout > tfoot > tr > th.subtotal,
.table-checkout > tfoot > tr > td.subtotal {
  padding: 16px 3px;
  font-size: 140%;
}

.table-checkout > tbody > tr > th,
.table-checkout > tbody > tr > td {
  padding: 3px;
  border-bottom: 1px solid #ddd;
  vertical-align: middle;
}

.table-checkout > tbody > tr > th.subtotal,
.table-checkout > tbody > tr > td.subtotal {
  padding: 16px 3px;
  font-size: 140%;
}

.table-checkout > tbody > tr:last-child > td {
  border: 0;
}

.table-checkout > thead > tr > th,
.table-checkout > thead > tr > td {
  border-bottom-width: 2px;
  font-size: 105%;
}

.container-login {
  max-width: 60%;
  margin: 0 auto;
}

@media (max-width: 768px) {
  .container-login {
    max-width: 92%;
  }
}

.panel-theme {
  border-color: #99ccff;
}

.panel-theme > .panel-heading {
  color: #000;
  background-color: #99ccff;
  border-color: #99ccff;
}

.panel-theme > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #99ccff;
}

.panel-theme > .panel-heading .badge {
  color: #99ccff;
  background-color: #000;
}

.panel-theme > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: #99ccff;
}

.panel-theme .panel-body {
  background-color: #cce6ff;
}

.pages-contactus {
  background: url("..images/banner/icon-map.png") no-repeat center center;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain;
}

.contact-about {
  padding: 32px 0;
}

@media (max-width: 768px) {
  .contact-about {
    padding: 0;
  }
}

.section-aboutus {
  padding: 64px 0;
}

@media (max-width: 768px) {
  .section-aboutus {
    padding: 16px 0;
  }
}

.office-address-card {
  margin: 32px auto;
  display: block;
  position: relative;
  padding-left: 100px;
}

.office-address-card img {
  display: block;
  width: 80px;
  margin: 0 auto;
  position: absolute;
  left: 0;
  top: 0;
}

.office-address-card .office-address-content {
  padding-left: 16px;
}

.office-address-card .header {
  margin: 0;
  padding: 0;
  margin-bottom: 8px;
  font-size: 160%;
  font-weight: 600;
}

.office-address-card .content {
  margin-bottom: 16px;
}

.office-address-card .content p {
  margin: 0;
  padding: 0;
  max-width: 320px;
}

.office-address-card .list-contact {
  margin: 0;
  padding: 0;
}

.office-address-card .list-contact:before, .office-address-card .list-contact:after {
  display: table;
  content: " ";
}

.office-address-card .list-contact:after {
  clear: both;
}

.office-address-card .list-contact li {
  list-style: none;
  float: left;
  margin-right: 32px;
}

.office-address-card .list-contact li a {
  text-decoration: none;
  color: #333333;
  font-size: 90%;
  font-weight: 500;
  display: block;
}

.office-address-card .list-contact li a .icons {
  color: #F04124;
}

.office-address-card .list-contact li a:hover {
  color: #0080ff;
}

.office-address-card .icons {
  margin-right: 10px;
}

@media (max-width: 480px) {
  .office-address-card {
    margin: 0;
    margin-bottom: 32px;
    padding-left: 84px;
  }
  .office-address-card img {
    width: 40px;
    top: 50%;
    left: 16px;
    margin-top: -30px;
  }
  .office-address-card .header {
    font-size: 140%;
  }
  .office-address-card .office-address-content {
    padding-left: 20px;
    font-size: 90%;
  }
}

.img-sucess {
  max-width: 120px;
  margin: 0 auto;
  margin-bottom: 32px;
}

.img-sucess img {
  display: block;
  width: 100%;
}

.partners-card {
  display: block;
  border: 1px dashed #bfbfbf;
  padding: 6px;
  border-radius: 4px;
}

.partners-card img {
  display: block;
  width: 100%;
}

.traveller-selection {
  position: absolute;
  background-color: #fff;
  min-width: 330px;
  left: -30%;
  top: 120%;
  z-index: 500;
  border-radius: 4px;
  box-shadow: 0 10px 15px 8px rgba(0, 0, 0, 0.1), 0 5px 20px 10px rgba(0, 0, 0, 0.08), 0 5px 8px -4px rgba(0, 0, 0, 0.1);
  padding: 6px 16px;
}

.traveller {
  position: relative;
}

.traveller .icons {
  position: absolute;
  right: 0;
  top: 3px;
}

.traveller .total-pax {
  display: block;
  line-height: 1.1;
  font-size: 140%;
}

.traveller .class {
  font-size: 90%;
  font-weight: 500;
  color: #6f6f6f;
}

.traveller .count:focus {
  outline: 0 !important;
  outline-offset: 0 !important;
}

.traveller:focus {
  outline: 0 !important;
  outline-offset: 0 !important;
}

.list-block {
  margin-bottom: 6px;
}

.list-block .header {
  font-size: 110%;
}

.list-block .header small {
  font-size: 80%;
  color: #6f6f6f;
}

.list-block .list-count:before, .list-block .list-count:after {
  display: table;
  content: " ";
}

.list-block .list-count:after {
  clear: both;
}

.list-block .list-count .num {
  float: left;
  width: 26px;
  height: 26px;
  border: 1px solid #bfbfbf;
  margin-right: 3px;
  border-radius: 4px;
  text-align: center;
  font-weight: 600;
  line-height: 23.63636px;
  color: #6f6f6f;
  cursor: pointer;
}

.list-block .list-count .num:hover {
  border-color: #0080ff;
  color: #0080ff;
}

.list-block .list-count .num.numactive {
  color: #F04124;
  border-color: #F04124;
}

.list-block .list-count .num:last-child {
  margin: 0;
}

.flightselect-list {
  border-top: 1px solid #bfbfbf;
  padding: 6px 0;
}

.swipe {
  width: 31.81818px;
  height: 31.81818px;
  position: absolute;
  background-color: #fff;
  right: -15.15152px;
  top: 2px;
  z-index: 250;
  border: 2px solid #bfbfbf;
  border-radius: 40px;
}

.swipe img {
  width: 100%;
  padding: 5px;
}

.destination-list {
  position: absolute;
  background-color: #fff;
  min-width: 270px;
  left: 6px;
  top: 80%;
  z-index: 500;
  border-radius: 4px;
  overflow: auto;
  max-height: 300px;
  margin: 0;
  box-shadow: 0 10px 15px 8px rgba(0, 0, 0, 0.1), 0 5px 20px 10px rgba(0, 0, 0, 0.08), 0 5px 8px -4px rgba(0, 0, 0, 0.1);
}

.destination-list.ng-empty {
  display: none;
}

.destination-list .list-items {
  position: relative;
  padding: 3px 6px;
}

.destination-list .code {
  font-weight: 600;
  font-size: 110%;
  position: absolute;
  right: 6px;
  color: #6f6f6f;
  top: 5px;
}

.destination-list .list-origen {
  font-size: 110%;
  line-height: 1.1;
}

.destination-list .airport-name {
  display: block;
  color: #6f6f6f;
  font-size: 90%;
}

.oadv-artical {
  position: relative;
  padding-left: 67.2px;
  min-height: 48px;
  margin-bottom: 16px;
}

.oadv-artical .oadv-figure {
  position: absolute;
  top: 6px;
  left: 0;
  width: 48px;
  height: 48px;
  overflow: hidden;
}

.oadv-artical .oadv-figure img, .oadv-artical .oadv-figure .oadv-icons {
  width: 100%;
  height: auto;
  display: block;
}

.oadv-artical .oadv-figure .icons {
  color: #6f6f6f;
  margin-top: 10px;
  font-size: 300%;
}

.oadv-artical .header {
  font-size: 140%;
  font-weight: 600;
  color: #F04124;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

.oadv-artical .contant {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  font-size: 90%;
  -webkit-line-clamp: 2;
  height: 40px;
}

.tdeals-section {
  padding: 16px 0;
}

.tdeals-article {
  position: relative;
  border: 1px solid #bfbfbf;
  padding: 8px 16px;
  border-radius: 4px;
  margin-bottom: 16px;
  display: block;
  color: inherit;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.tdeals-article .time-code .times {
  font-size: 160%;
  font-weight: 300;
}

.tdeals-article .time-code .times .line {
  width: 20px;
  margin: 0 6px;
  display: inline-block;
  position: relative;
}

.tdeals-article .time-code .times .line:after {
  content: "";
  background-color: #bfbfbf;
  height: 2px;
  top: -6px;
  left: 0;
  right: 0;
  position: absolute;
  z-index: 2;
}

.tdeals-article .time-code .booking-price {
  text-align: right;
  font-size: 120%;
}

.tdeals-article .time-code .booking-price .icons {
  font-size: 70%;
}

.tdeals-article .time-code .booking-price .fa-w-10 {
  height: 0.7em;
  margin-right: 2px;
  margin-bottom: 1.2px;
}

.tdeals-article .time-code .booking-price .text {
  font-weight: 600;
}

.tdeals-article:hover {
  border-color: #0080ff;
}

.tdeals-img-wrap {
  position: relative;
  min-height: 40px;
  padding-left: 56px;
  margin-bottom: 6px;
}

.tdeals-img-wrap .tdeals-img-iner {
  position: absolute;
  left: 0;
}

.tdeals-img-wrap .tdeals-img-iner img,
.tdeals-img-wrap .tdeals-img-iner .ich-icons {
  display: block;
  width: 40px;
  height: 40px;
}

.tdeals-img-wrap .tdeals-flight {
  line-height: 1.2;
  font-size: 110%;
  padding-top: 3px;
}

.tdeals-img-wrap .tdeals-flight .name {
  font-weight: 600;
}

.tdeals-img-wrap .tdeals-flight .code {
  font-size: 80%;
  color: #6f6f6f;
}

.toptrending-section .icons {
  margin-right: 6px;
}

.toptrending-content {
  padding: 10.66667px 0;
  border-bottom: 1px solid #bfbfbf;
}

.toptrending-content:last-child {
  border: 0;
}

.toptrending-content .time-code {
  position: relative;
}

.toptrending-content .time-code .times {
  font-size: 180%;
  line-height: 1.2;
  font-weight: 300;
}

.toptrending-content .time-code .times .text {
  font-weight: 700;
  font-size: 80%;
  margin-left: 2px;
  color: #6f6f6f;
  text-transform: uppercase;
}

.toptrending-content .time-code .times .line {
  width: 30px;
  text-align: center;
  margin: 0 6px;
  padding: 0 6px;
  display: inline-block;
  position: relative;
  font-weight: 600;
  font-size: 70%;
  text-transform: uppercase;
}

.toptrending-content .time-code .times .line .info {
  position: relative;
  display: inline-block;
  background-color: #fff;
  z-index: 5;
  padding: 2px;
}

.toptrending-content .time-code .times .line:after {
  content: "";
  background-color: #bfbfbf;
  height: 2px;
  top: -4px;
  left: 0;
  right: 0;
  position: absolute;
  z-index: 2;
}

.toptrending-content .time-code .booking-price {
  text-align: right;
  font-size: 160%;
}

.toptrending-content .time-code .booking-price .fa-w-10 {
  height: 0.7em;
  margin-right: 2px;
  margin-bottom: 1.2px;
}

.toptrending-content .time-code .booking-price .text {
  font-weight: 600;
}

.trend-cities-list .cities-list {
  margin: 0;
  padding: 0;
  margin-left: 3px;
  margin-right: 3px;
}

.trend-cities-list .cities-list:before, .trend-cities-list .cities-list:after {
  display: table;
  content: " ";
}

.trend-cities-list .cities-list:after {
  clear: both;
}

.trend-cities-list .cities-list li {
  float: left;
  list-style: none;
  margin: 3px;
}

.trend-cities-list .cities-list li a {
  display: block;
  padding: 5px 12.30769px;
  border: 2px solid #bfbfbf;
  border-radius: 4px;
  color: #6f6f6f;
}

.trend-cities-list .cities-list li:hover a {
  border-color: #0080ff;
  color: #F04124;
}

.article-loader-body .progress {
  border-radius: 0;
  height: 3px;
  margin: 0;
  margin-top: 1x;
}

.article-loader-body .heading {
  font-size: 120%;
  text-align: center;
}

.article-loader-body .progress-stick {
  margin-top: 32px;
}

.article-loader-body .search-place {
  text-align: center;
  font-size: 140%;
}

.article-loader-body .search-place span {
  display: inline-block;
  color: #F04124;
  font-weight: 600;
}

.article-loader-body .search-place .date {
  margin-right: 6px;
}

.article-loader-body .search-place .head-time {
  color: #000;
  font-weight: 600;
}

.article-loader-body .search-place .desti {
  margin: auto 6px;
}

.article-loader-body .search-place .swipe-loader {
  width: 20px;
  display: inline-block;
}

.article-loader-body .search-place .fa-w-14 {
  color: gray;
}

.article-loader-body .progress {
  border-radius: 4px;
}

.article-loader-body .flt-trip-text {
  margin: 16px auto;
  padding: 24px 0;
  border-top: 1px dashed #bfbfbf;
  border-bottom: 1px dashed #bfbfbf;
}

.article-loader-body .flt-trip-content {
  border-right: 2px dashed #bfbfbf;
}

.article-loader-body .flt-trip-content.noborder {
  border: 0;
}

.article-loader-body .flt-trip-content .svg-inline--fa {
  font-size: 260%;
  color: gray;
}

.article-loader-body .flt-trip-content .text {
  margin: 0 2px;
}

.article-loader-body .flt-trip-content .status-text {
  font-size: 110%;
  margin: 6px auto;
  text-transform: uppercase;
}

.article-loader-body .flt-trip-content .status-date {
  line-height: 1;
  font-size: 300%;
}

.article-loader-body .flt-text {
  font-weight: 600;
  text-align: center;
  margin: 0;
}

.slider-container {
  width: 100%;
  /* max-width: 300px; */
  margin: 0 auto 5px;
}

.rs-container * {
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.rs-container {
  font-family: Arial, Helvetica, sans-serif;
  height: 45px;
  position: relative;
}

.rs-container .rs-bg,
.rs-container .rs-selected {
  background-color: #eee;
  border: 1px solid #ededed;
  height: 5px;
  left: 0;
  position: absolute;
  top: 7px;
  width: 100%;
  border-radius: 3px;
}

.rs-container .rs-selected {
  background-color: #00b3bc;
  border: 1px solid #00969b;
  transition: all 0.2s linear;
  width: 0;
}

.rs-container.disabled .rs-selected {
  background-color: #ccc;
  border-color: #bbb;
}

.rs-container .rs-pointer {
  background-color: #fff;
  border: 1px solid #bbb;
  border-radius: 10px;
  cursor: pointer;
  height: 20px;
  left: -10px;
  position: absolute;
  top: 0;
  transition: all 0.2s linear;
  width: 20px;
  box-shadow: inset 0 0 1px #fff, inset 0 1px 6px #ebebeb, 1px 1px 4px rgba(0, 0, 0, 0.1);
}

.rs-container.disabled .rs-pointer {
  border-color: #ccc;
  cursor: default;
}

.rs-container .rs-pointer::after,
.rs-container .rs-pointer::before {
  content: "";
  position: absolute;
  width: 1px;
  height: 9px;
  background-color: #ddd;
  left: 8px;
  top: 5px;
}

.rs-container .rs-pointer::after {
  left: 10px;
  right: 12px;
}

.rs-container.sliding .rs-pointer,
.rs-container.sliding .rs-selected {
  transition: none;
}

.rs-container .rs-scale span {
  float: left;
}

.rs-container .rs-scale span::before {
  background-color: #ededed;
  content: "";
}

.rs-container.rs-noscale span::before {
  display: none;
}

.rs-container.rs-noscale span:first-child::before,
.rs-container.rs-noscale span:last-child::before {
  display: block;
}

.rs-container .rs-scale span:last-child {
  margin-left: -1px;
  width: 0;
}

.rs-container .rs-scale span ins {
  color: #333;
  display: inline-block;
  font-size: 12px;
  margin-top: 20px;
  text-decoration: none;
}

.rs-container.disabled .rs-scale span ins {
  color: #999;
}

.rs-tooltip {
  color: #333;
  width: auto;
  min-width: 60px;
  height: 20px;
  position: absolute;
  transform: translate(-60%, -35px);
  left: 13px;
  text-align: center;
  font-size: 13px;
  padding: 15px 10px 0;
}

.rs-container.disabled .rs-tooltip {
  border-color: #ccc;
  color: #999;
}

.booking-request-artical {
  cursor: pointer;
  text-align: center;
  padding: 16px 0;
}

.booking-request-artical .figure {
  width: 56px;
  margin: 0 auto;
  margin-bottom: 16px;
}

.booking-request-artical .figure img {
  display: block;
  width: 100%;
}

.booking-request-artical .header {
  font-weight: 500;
  font-size: 120%;
}

.booking-request-artical:hover, .booking-request-artical.active {
  color: #0073e6;
}

.panel-heading .sub-title {
  font-size: 90%;
  color: gray;
}

.panel-heading.with-link .panel-title {
  font-weight: 500;
}

.panel-heading.with-link .link {
  display: block;
  margin-top: 6px;
  font-weight: 600;
  text-transform: uppercase;
}

.icons-artical {
  position: relative;
  padding-left: 58.8px;
  min-height: 42px;
  margin: 6px auto;
}

.icons-artical .icons-figure {
  position: absolute;
  top: 0;
  left: 0;
  width: 42px;
  height: 42px;
  overflow: hidden;
  text-align: center;
}

.icons-artical .icons-figure img {
  width: 100%;
  display: block;
}

.icons-artical .icons-figure .icons {
  color: #6f6f6f;
  margin-top: 0;
  top: 0;
  font-size: 240%;
}

.icons-artical .header {
  font-size: 140%;
  font-weight: 400;
  color: #F04124;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

.icons-artical .contant {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  font-size: 90%;
}

.flt-summary-accord {
  background-color: #ddd;
}

.flt-summary-accord .btn-link {
  display: block;
  padding: 0;
  margin: 0;
  margin-bottom: 3px;
  padding-bottom: 2px;
  font-size: 100%;
  font-weight: 600;
  color: #000;
  text-transform: uppercase;
  padding: 3px 0;
}

.flt-summary-accord .btn-link .icons {
  margin-right: 3px;
  color: #F04124;
}

.summary-plist {
  border-top: 1px dashed #bfbfbf;
  margin: 0;
  padding: 0;
}

.summary-plist li {
  list-style: none;
  position: relative;
  padding: 6px 0;
  font-size: 110%;
}

.summary-plist li .count {
  margin-left: 3px;
  color: gray;
  font-size: 80%;
  font-weight: 500;
}

.summary-plist li .count .icons {
  font-size: 90%;
  margin-left: 2px;
}

.summary-plist li .text-rate {
  font-weight: 600;
  font-size: 90%;
  line-height: 1;
  margin-top: 3px;
}

.summary-plist li .text {
  position: relative;
  font-weight: 600;
}

.summary-plist li .booking-price {
  position: absolute;
  right: 0;
  top: 3px;
  z-index: 2;
}

.summary-plist li .booking-price .icons {
  font-size: 75%;
  margin-right: 2px;
}

.summary-plist li .list-out {
  margin-left: -3px;
}

.summary-plist li .list-out:before, .summary-plist li .list-out:after {
  display: table;
  content: " ";
}

.summary-plist li .list-out:after {
  clear: both;
}

.summary-plist.large {
  margin: 0;
  border: none;
  font-size: 140%;
  font-weight: 500;
}

.summary-plist.large li {
  margin: 0;
  padding: 0;
}

.summary-plist.large li .booking-price {
  margin-top: -9.6px;
}

.checkbox-switch {
  float: right;
  position: relative;
  display: block;
  width: 80px;
  background-color: #F04124;
  border-radius: 40px;
  font-weight: 500;
  font-size: 80%;
  color: #fff;
  padding: 2px 6px;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.checkbox-switch:after {
  position: absolute;
  content: '';
  width: 16px;
  height: 16px;
  border-radius: 100%;
  background-color: white;
  z-index: 3;
  top: 50%;
  left: 1.5px;
  margin-top: -8.88889px;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.checkbox-switch input {
  filter: alpha(opacity=0);
  opacity: 0;
  position: absolute;
  left: 0;
  z-index: 2;
}

.checkbox-switch .active {
  display: none;
}

.checkbox-switch .inactive {
  padding-left: 16px;
  display: block;
}

.checkbox-switch.slide {
  background-color: #5cb85c;
}

.checkbox-switch.slide:after {
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  left: 74%;
}

.checkbox-switch.slide .active {
  padding-right: 16px;
  display: block;
}

.checkbox-switch.slide .inactive {
  display: none;
}

.tbs-flt-tinerary.borderd {
  border-right: 2px dashed #bfbfbf;
}

.tourfair-heading {
  position: relative;
  padding: 3px 0;
  padding-bottom: 0;
  border-bottom: 1px dashed #bfbfbf;
}

.tourfair-heading:before, .tourfair-heading:after {
  display: table;
  content: " ";
}

.tourfair-heading:after {
  clear: both;
}

.tourfair-heading.with-text {
  overflow: hidden;
}

.fair-heading {
  float: left;
  padding: 3px 0;
  padding-left: 120px;
  position: relative;
}

.fair-heading .tittle {
  font-weight: 600;
  font-size: 140%;
  text-transform: uppercase;
}

.fair-heading .time {
  font-size: 90%;
}

.fair-heading .date {
  line-height: 1.3;
  display: block;
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  background-color: #bfbfbf;
  color: #000;
  padding: 3px 10.66667px;
  padding-right: 7.2px;
}

.fair-heading .date:after {
  content: '';
  position: absolute;
  z-index: 2;
  top: 0;
  width: 0;
  height: 0;
  left: 100%;
  border-bottom: 49px solid #bfbfbf;
  border-right: 30px solid transparent;
}

.fair-heading .flt-code {
  font-weight: 600;
  font-size: 130%;
  line-height: 1.2;
}

.fair-heading .list-out {
  margin-left: -3px;
}

@media (max-width: 768px) {
  .fair-heading {
    float: none;
    overflow: hidden;
  }
}

.booked-fair-heading {
  float: left;
  padding: 3px 0;
  padding-left: 64px;
  position: relative;
}

.booked-fair-heading .tittle {
  font-weight: 600;
  font-size: 140%;
  text-transform: uppercase;
}

.booked-fair-heading .time {
  font-size: 90%;
}

.booked-fair-heading .tittle-icon {
  line-height: 1.3;
  display: block;
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  background-color: #bfbfbf;
  color: #000;
  padding: 3px 10.66667px;
  padding-right: 7.2px;
}

.booked-fair-heading .tittle-icon:after {
  content: '';
  position: absolute;
  z-index: 2;
  top: 0;
  width: 0;
  height: 0;
  left: 100%;
  border-bottom: 49px solid #bfbfbf;
  border-right: 18px solid transparent;
}

.booked-fair-heading .tittle-icon .icons {
  font-size: 170%;
  line-height: 1.8;
}

.booked-fair-heading .flt-code {
  font-weight: 600;
  font-size: 130%;
  line-height: 1.2;
}

.booked-fair-heading .list-out {
  margin-left: -3px;
}

.tourfair-rules {
  float: right;
  padding: 3px 10.66667px;
  font-size: 100%;
  padding-right: 0;
}

.tourfair-rules .label {
  font-weight: 400;
  border-radius: 2px;
}

.tourfair-rules .rules {
  border-left: 1px solid #bfbfbf;
  padding: 0 6px;
}

.tourfair-rules .list-out {
  padding-top: 8px;
  padding-right: 16px;
}

@media (max-width: 768px) {
  .tourfair-rules {
    float: none;
    padding: 0;
    margin: 6px;
  }
}

.fair-type {
  margin-top: 6px;
}

.icons-heading {
  margin-top: 6px;
  padding: 3px 0;
  padding-left: 80px;
  position: relative;
  overflow: hidden;
  border-bottom: 1px dashed #bfbfbf;
}

.icons-heading .icons {
  line-height: 1.3;
  display: block;
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  line-height: 1.7;
  background-color: #bfbfbf;
  color: #000;
  font-size: 200%;
  padding: 3px 10.66667px;
  padding-right: 7.2px;
}

.icons-heading .icons:after {
  content: '';
  position: absolute;
  z-index: 2;
  top: 0;
  width: 0;
  height: 0;
  left: 100%;
  border-bottom: 49px solid #bfbfbf;
  border-right: 30px solid transparent;
}

.icons-heading .tittle {
  font-weight: 600;
  font-size: 130%;
  line-height: 0.8;
  margin-bottom: 4.28571px;
}

.icons-heading.with-search {
  padding-right: 200px;
}

.icons-heading.with-search .serch-history {
  position: absolute;
  top: 50%;
  right: 6px;
  margin-top: -19.2px;
  z-index: 166.66667;
}

@media (max-width: 768px) {
  .icons-heading {
    padding-left: 6px;
    padding-right: 6px;
  }
  .icons-heading .icons {
    display: none;
  }
}

.button-block {
  margin: 16px auto;
  margin-bottom: 6px;
}

.button-block .link-booknow {
  min-width: 200px;
  border-radius: 40px;
}

.button-block .link-back {
  min-width: 100px;
  border-radius: 40px;
  margin-right: 6px;
}

@media (max-width: 768px) {
  .button-block .link-booknow,
  .button-block .link-back {
    min-width: initial;
  }
}

.passengers-list {
  margin: 6px auto;
  border: 1px solid #bfbfbf;
  border-radius: 4px;
  padding: 6px 16px;
  padding-bottom: 0;
}

.passengers-list .header {
  font-weight: 600;
  font-size: 120%;
}

.passengers-list .header .small {
  font-size: 70%;
}

@media (max-width: 768px) {
  .passengers-list .header {
    margin-bottom: 6px;
  }
}

.passengers-list:hover, .passengers-list:focus {
  border-color: #0080ff;
}

.passengers-list .sub-header {
  font-weight: 600;
  font-size: 90%;
  margin-bottom: 6px;
}

.passengers-list:first-child {
  margin-top: 0;
}

.passengers-list:last-child {
  margin-bottom: 0;
}

.passengers-list.with-detail {
  border: 0;
  padding: 0;
}

.passengers-list.with-detail:hover {
  border: 0;
}

.passengers-list .contant-list {
  margin-top: 6px;
  margin-left: -3px;
  font-weight: 600;
}

.guest-artical {
  margin: 6px auto;
}

.guest-artical .heading {
  text-transform: uppercase;
  font-size: 80%;
  color: #6f6f6f;
  font-weight: 700;
}

.flight-ads {
  display: block;
}

.flight-ads img {
  display: block;
  width: 100%;
}

.dasshboard-widget-registration {
  margin: 16px auto;
  margin-bottom: 32px;
}

.widget-registration-about .heading {
  font-size: 115%;
}

.widget-registration-footer {
  border-top: 1px solid #bfbfbf;
  padding-top: 16px;
  padding-bottom: 0;
}

.widget-registration-footer:before, .widget-registration-footer:after {
  display: table;
  content: " ";
}

.widget-registration-footer:after {
  clear: both;
}

.widget-registration-footer .left {
  float: left;
  max-width: 50%;
}

.widget-registration-footer .left .checkbox {
  margin-top: 6px;
}

.widget-registration-footer .right {
  max-width: 50%;
  float: right;
}

.g-recaptcha {
  -webkit-transform: scale(0.69);
  -ms-transform: scale(0.69);
  -o-transform: scale(0.69);
  transform: scale(0.69);
  -webkit-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  transform-origin: 0 0;
}

.widget-single {
  margin: 32px auto;
}

.widget-single .widget-content:before, .widget-single .widget-content:after {
  display: table;
  content: " ";
}

.widget-single .widget-content:after {
  clear: both;
}

.widget-single .widget-content .img-widget {
  display: block;
  max-width: 45%;
  float: left;
  margin-right: 16px;
  margin-bottom: 16px;
}

.widget-single .widget-content .img-widget.right {
  float: right;
  margin-right: auto;
  margin-left: 16px;
  margin-bottom: 16px;
}

.widget-single .widget-content .img-widget img {
  display: block;
  width: 100%;
}

@media (max-width: 768px) {
  .widget-single .widget-content .img-widget {
    float: none;
    max-width: initial;
    margin: 0;
    margin-bottom: 16px;
  }
  .widget-single .widget-content .img-widget.right {
    float: none;
    margin: 0;
    margin-bottom: 16px;
  }
}

.widget-single .widget-content h1,
.widget-single .widget-content h2,
.widget-single .widget-content h3,
.widget-single .widget-content h4 {
  margin: 0;
  padding: 0;
  margin-bottom: 16px;
}

.modal-open .ui-datepicker {
  z-index: 2000 !important;
}

.link-booknow-res {
  padding: 10.66667px 0;
  padding-top: 0;
}

.link-booknow-res:before, .link-booknow-res:after {
  display: table;
  content: " ";
}

.link-booknow-res:after {
  clear: both;
}

.link-booknow-res .flt-dtl-btn {
  float: left;
}

.link-booknow-res .flt-dtl-btn .icons {
  margin-right: 3px;
}

.link-booknow-res .flt-trip-adv {
  float: right;
}

.link-booknow-res .list-out .icons {
  margin-right: 2px;
}

@media (max-width: 768px) {
  .link-booknow-res .flt-trip-adv,
  .link-booknow-res .flt-dtl-btn {
    float: none;
  }
}

.link-booknow-filter .text-base {
  display: inline-block;
  position: relative;
  font-weight: 500;
  margin-bottom: 6px;
}

.link-booknow-filter .text-base .icons {
  font-size: 90%;
  position: absolute;
  top: 1.5px;
  right: -16px;
}

.filter-with-rtrip {
  background-color: #fff;
  padding: 3px 6px;
  border: 1px solid #bfbfbf;
  margin-bottom: 6px;
  border-radius: 4px;
}

.filter-with-rtrip:before, .filter-with-rtrip:after {
  display: table;
  content: " ";
}

.filter-with-rtrip:after {
  clear: both;
}

.booknow-filte-info.wih-padding {
  padding: 5px 6px;
}

.booknow-filte-info .text-base {
  margin: 0;
}

.booknow-filte-info .list-out {
  margin-left: -3px;
}

.filter-rtrip {
  background-color: #fff;
  padding: 6px;
  border: 1px solid #bfbfbf;
  margin-bottom: 6px;
  border-radius: 4px;
}

.filter-rtrip:before, .filter-rtrip:after {
  display: table;
  content: " ";
}

.filter-rtrip:after {
  clear: both;
}

.filter-rtrip .text-base {
  font-weight: 600;
}

.filter-rtrip .booknow-filte-info {
  float: left;
}

.filter-rtrip .list-filter {
  float: right;
  margin: 0;
  padding: 0;
}

.filter-rtrip .list-filter li {
  position: relative;
  list-style: none;
  font-size: 100%;
  float: left;
  margin: 0 16px;
  color: gray;
  font-weight: 500;
  padding-right: 6px;
}

.filter-rtrip .list-filter li .icons {
  position: absolute;
  top: 0;
  left: 100%;
  z-index: 2;
}

.filter-rtrip .list-filter li .bold {
  color: #333333;
}

.filter-rtrip .list-filter li .form-group {
  margin-bottom: 0;
}

.filter-rtrip .list-filter li:last-child {
  margin-right: 0;
}

.flt-side-accord .btn-link {
  padding: 0;
  margin: 0;
  margin-bottom: 3px;
  padding-bottom: 2px;
  font-size: 105%;
  font-weight: 600;
  text-transform: uppercase;
}

.flt-side-accord .airline-plist {
  margin-bottom: 6px;
}

.flt-side-accord .airline-plist li {
  position: relative;
  border-top: 1px dashed #bfbfbf;
  padding: 8px 0;
}

.flt-side-accord .airline-plist li .count {
  margin-left: 3px;
  color: gray;
  font-size: 90%;
  font-weight: 500;
}

.flt-side-accord .airline-plist li .count .icons {
  font-size: 80%;
  margin-left: 2px;
}

.flt-side-accord .airline-plist li .booking-price {
  position: absolute;
  right: 0;
  bottom: 0;
  top: 50%;
  margin-top: -6px;
  z-index: 2;
  color: gray;
}

.flt-side-accord .airline-plist li .booking-price .fa-w-10 {
  height: 0.7em;
  margin-right: 2px;
}

.flt-side-accord .airline-plist li .booking-price .text {
  font-weight: 600;
}

.flt-side-accord ul {
  margin: 0;
  padding: 0;
}

.flt-side-accord ul li {
  list-style: none;
}

.flt-side-accord ul img {
  display: block;
  width: 100%;
}

.flt-side-accord .tarvel-route {
  font-weight: 500;
  color: gray;
}

.flt-side-accord .tarvel-route .text {
  color: #000;
}

.flt-side-accord .airline-plist-time {
  background-color: #fff;
  margin-bottom: 6px;
  border: 1px solid #bfbfbf;
  border-radius: 4px;
}

.flt-side-accord .airline-plist-time:before, .flt-side-accord .airline-plist-time:after {
  display: table;
  content: " ";
}

.flt-side-accord .airline-plist-time:after {
  clear: both;
}

.flt-side-accord .airline-plist-time li {
  border-right: 1px dashed #bfbfbf;
  padding: 8px 0;
  width: 25%;
  float: left;
}

.flt-side-accord .airline-plist-time li:last-child {
  border: 0;
}

.flt-side-accord .airline-plist-time li .dept-time {
  text-align: center;
}

.flt-side-accord .airline-plist-time li .dept-time .icons {
  font-size: 200%;
  display: block;
  text-align: center;
}

.flt-side-accord .airline-plist-time li .dept-time .time {
  font-size: 80%;
  font-weight: 500;
  display: block;
}

.flt-side-accord .airline-plist-time li .dept-time .clock {
  display: block;
  color: gray;
}

.flt-side-accord .airline-plist-stop {
  margin-bottom: 6px;
}

.flt-side-accord .airline-plist-stop:before, .flt-side-accord .airline-plist-stop:after {
  display: table;
  content: " ";
}

.flt-side-accord .airline-plist-stop:after {
  clear: both;
}

.flt-side-accord .airline-plist-stop li {
  width: 20%;
  float: left;
}

.flt-side-accord .airline-plist-stop li a {
  background-color: #fff;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  margin: 2px 0;
  border: 2px solid #bfbfbf;
  border-radius: 4px;
  font-size: 160%;
  color: inherit;
  width: 50px;
  font-weight: 600;
  display: block;
  padding: 4px 0;
  text-align: center;
}

.flt-side-accord .airline-plist-stop li:hover a, .flt-side-accord .airline-plist-stop li.active a {
  border-color: #0080ff;
  color: #0080ff;
}

.flt-side-accord .airline-plist-ident {
  margin-bottom: 6px;
}

.flt-side-accord .airline-plist-ident:before, .flt-side-accord .airline-plist-ident:after {
  display: table;
  content: " ";
}

.flt-side-accord .airline-plist-ident:after {
  clear: both;
}

.flt-side-accord .airline-plist-ident li {
  float: left;
  position: relative;
}

.flt-side-accord .airline-plist-ident li .badge-c {
  margin: 1.5px;
  border-radius: 4px;
  display: block;
  border-radius: 100%;
  padding: 22.4px 16px;
}

.flt-side-accord .airline-plist-ident li .checkbox-plist {
  width: 48px;
  height: 48px;
  position: relative;
  display: block;
}

.flt-side-accord .airline-plist-ident li .checkbox-plist input {
  position: absolute;
  filter: alpha(opacity=0);
  opacity: 0;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 20;
  position: absolute;
  margin: 0;
  padding: 0;
}

.flt-side-accord .airline-plist-ident li .text {
  display: block;
  font-weight: 600;
  font-size: 120%;
  text-align: center;
  position: absolute;
  color: #fff;
  top: 0;
  bottom: 0;
  line-height: 48px;
  left: 0;
  right: 0;
}

.flt-side-accord .airline-plist-ident li .icons {
  padding: 2px;
  background-color: #fff;
  line-height: 0.9;
  border-radius: 40px;
  top: -2px;
  right: 3px;
  color: #F04124;
  position: absolute;
  font-size: 120%;
}

.flt-side-accord .airline-plist-hotel,
.flt-side-accord .airline-plist-package {
  margin-bottom: 6px;
}

.flt-side-accord .airline-plist-hotel li,
.flt-side-accord .airline-plist-package li {
  position: relative;
  padding: 3px 0;
}

.flt-side-accord .airline-plist-hotel li .count,
.flt-side-accord .airline-plist-package li .count {
  margin-left: 3px;
  color: gray;
  font-size: 90%;
  font-weight: 500;
}

.flt-side-accord .airline-plist-hotel li .count .icons,
.flt-side-accord .airline-plist-package li .count .icons {
  font-size: 80%;
  margin-left: 2px;
}

.flt-side-accord .airline-plist-hotel li .figure,
.flt-side-accord .airline-plist-package li .figure {
  display: inline-block;
  width: 18px;
}

.flt-side-accord .airline-plist-hotel li .star-circle,
.flt-side-accord .airline-plist-package li .star-circle {
  color: #009688;
}

.flt-side-accord .airline-plist-hotel li .booking-price,
.flt-side-accord .airline-plist-package li .booking-price {
  position: absolute;
  right: 0;
  bottom: 0;
  top: 50%;
  margin-top: -7.8px;
  z-index: 2;
  color: gray;
}

.flt-side-accord .airline-plist-hotel li .booking-price .fa-w-10,
.flt-side-accord .airline-plist-package li .booking-price .fa-w-10 {
  height: 0.7em;
  margin-right: 2px;
}

.flt-side-accord .airline-plist-hotel li .booking-price .text,
.flt-side-accord .airline-plist-package li .booking-price .text {
  font-weight: 600;
}

.flt-side-accord .airline-plist-hotel li .booking-price .text.count,
.flt-side-accord .airline-plist-package li .booking-price .text.count {
  font-size: 90%;
}

.flt-side-accord .airline-plist-hotel .view-more,
.flt-side-accord .airline-plist-package .view-more {
  text-align: right;
  font-weight: 600;
}

.content-slider {
  margin: 6px auto;
  margin-right: 6px;
  margin-left: 3px;
}

.content-slider .slider-range-sec {
  margin-bottom: 6px;
  padding: 0 6px;
}

.content-slider .readonly {
  border: none;
  font-weight: 600;
  color: #F04124;
  outline: 0 !important;
  outline-offset: 0 !important;
}

.cardartical-list-artical {
  padding-bottom: 16px;
}

.media-cart {
  position: relative;
}

.media-cart img {
  display: block;
  width: 100%;
}

.media-cart .card-img {
  display: block;
  overflow: hidden;
  border-radius: 4px;
  width: 48px;
}

.dept-icons {
  text-align: center;
  border: 1px solid #bfbfbf;
  margin: 6px;
  border-radius: 4px;
  padding: 16px 6px;
  border-top: 0;
  border-bottom: 0;
}

.dept-icons.with-calender {
  padding: 12px 6px;
  margin: 0;
  border-radius: 0;
  margin-left: -1px;
}

.dept-icons .icons {
  font-size: 200%;
  display: block;
  text-align: center;
}

.dept-icons .time {
  font-size: 80%;
  font-weight: 500;
  display: block;
}

.dept-icons .dates {
  font-size: 100%;
  font-weight: 500;
  display: block;
}

.dept-icons .text {
  margin-top: 6px;
  display: block;
  color: gray;
}

.cli-code {
  line-height: 1;
  display: block;
  font-weight: 500;
  font-size: 90%;
  color: gray;
}

.cli-code.with-margin {
  margin: 6px 0;
}

.cli-code.with-watemark {
  filter: alpha(opacity=50);
  opacity: 0.5;
  margin-bottom: 3px;
}

.cli-code.with-watemark.single {
  margin-top: 16px;
}

.cli-code.with-info {
  margin: 16px 0;
  font-size: 110%;
  color: #333333;
}

.cli-code.with-info .code-popover {
  display: inline-block;
  font-weight: 400;
  margin-left: 6px;
  position: relative;
}

.list-payment-type-widget {
  position: relative;
}

.list-payment-type-widget .link-booknow-view {
  position: absolute;
  bottom: -6px;
  left: 50%;
  z-index: 10;
  margin-left: -6px;
}

.list-payment-type-widget.without-list {
  padding-bottom: 16px;
  margin-bottom: 6px;
}

.list-payment-type {
  margin: 0;
  padding: 0;
  max-height: 147px;
  overflow: hidden;
}

.list-payment-type.no-list {
  margin-top: 0;
  padding-left: 6px;
}

.list-payment-type > li {
  list-style: none;
  padding: 0;
  margin-bottom: 5px;
}

.list-payment-type .radio,
.list-payment-type .checkbox {
  padding-left: 22px;
}

.list-payment-type .options {
  position: absolute !important;
  left: 1px;
  top: 0;
  z-index: 30;
}

.list-payment-type .bkg-policy {
  color: #6f6f6f;
}

.list-payment-type .bkg-policy .text {
  font-size: 90%;
}

.list-payment-type .bkg-policy .icons {
  font-size: 70%;
}

.list-payment-type .booking-price {
  display: block;
  line-height: 1.2;
  font-size: 140%;
}

.list-payment-type .booking-price .icons {
  font-size: 75%;
  margin-right: 1.5px;
}

.list-payment-type .booking-price .text {
  font-weight: 600;
}

.list-payment-type .flt-info {
  margin-left: -4px;
}

.list-payment-type.with-border > li {
  border-bottom: 1px solid #bfbfbf;
  padding: 5px 0;
}

.list-payment-type.with-border > li:last-child {
  border: 0;
  margin: 0;
  padding: 0;
}

.list-payment-type.with-coupon {
  max-height: none;
}

.list-payment-type.with-coupon li {
  padding: 3.33333px 0;
}

.list-payment-type.with-coupon li:last-child {
  padding-bottom: 0;
}

.list-payment-type.with-coupon .heading {
  display: block;
  font-size: 120%;
  line-height: 1.2;
  margin-bottom: 6px;
}

.list-payment-type.with-coupon .sub-heading {
  display: block;
  line-height: 1.2;
}

.list-payment-type.with-coupon .form-control {
  margin: 3px 0;
}

.list-payment-type.with-coupon .booking-price {
  display: block;
  line-height: 1.2;
  font-size: 170%;
}

.list-payment-type.with-coupon .booking-price .icons {
  font-size: 75%;
  margin-right: 1.5px;
}

.list-payment-type.with-coupon .booking-price .text {
  font-weight: 600;
}

.list-payment-flt {
  margin: 0;
  padding: 0;
  margin: 3px;
}

.list-payment-flt.no-list {
  margin-top: 0;
  padding-left: 6px;
}

.list-payment-flt .radio,
.list-payment-flt .checkbox {
  padding-left: 22px;
}

.list-payment-flt .options {
  position: absolute !important;
  left: 0;
  top: -3px;
  z-index: 30;
}

.list-payment-flt .list-filter {
  margin-left: -6px;
}

.list-payment-flt .list-filter li {
  font-size: 100%;
  color: gray;
}

.list-payment-flt .list-filter li .bold {
  color: #333333;
}

.list-payment-flt.large .list-out {
  margin-left: -6px;
  font-weight: 500;
}

.list-payment-flt.large .list-out li {
  font-size: 105%;
}

.list-payment-flt.large .list-out li .icons {
  padding: 0 4px;
}

.list-payment-flt.large .list-out li .bold {
  color: #333333;
}

.onewaypage-result {
  background-color: #f2f2f2;
}

.onewaypage-result.with-padding {
  padding: 16px 0;
}

.flt-list-one-way-card {
  background-color: #fff;
  margin: 8px auto;
  border: 1px solid #bfbfbf;
  border-radius: 4px;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.flt-list-one-way-card.active {
  background-color: #fff;
  border-color: #0080ff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 1px 5px 0 rgba(0, 0, 0, 0.08), 0 3px 1px -2px rgba(0, 0, 0, 0.08);
}

.flt-list-one-way-card:hover {
  border-color: #F04124;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 1px 5px 0 rgba(0, 0, 0, 0.08), 0 3px 1px -2px rgba(0, 0, 0, 0.08);
}

@media (max-width: 768px) {
  .flt-list-one-way-card .card.card-placeloader {
    margin-top: 6px;
  }
}

.list-out {
  margin: 0;
  padding: 0;
  display: inline-block;
}

.list-out:before, .list-out:after {
  display: table;
  content: " ";
}

.list-out:after {
  clear: both;
}

.list-out li {
  float: left;
  list-style: none;
  line-height: 1;
  border-right: 1px solid #8c8c8c;
  font-size: 90%;
  color: #6f6f6f;
  padding: 0 4px;
}

.list-out li:last-child {
  border: 0;
  padding-right: 0;
}

.list-out li .icons {
  font-size: 80%;
  margin-right: 2px;
}

.list-out li .checkbox {
  padding: 0;
  padding-right: 20.8px;
}

.list-out li .checkbox .options {
  position: absolute;
  right: 16px;
  top: -3px;
}

.list-out li .cursor {
  cursor: pointer;
}

.flt-list-one-way-inner {
  padding: 6px;
}

.flt-list-one-way-inner.with-round-trip .flt {
  margin-bottom: 9px;
}

.flt-list-one-way-inner .destination-price .booking-price {
  display: block;
  line-height: 1.2;
  font-size: 160%;
  cursor: pointer;
}

.flt-list-one-way-inner .destination-price .booking-price .icons {
  font-size: 75%;
  margin-right: 0;
}

.flt-list-one-way-inner .destination-price .booking-price .text {
  font-weight: 600;
}

.link-booknow-info .link-booknow {
  margin: 6px auto;
}

.link-booknow-info .abl-seat {
  margin-top: 6px;
  text-align: center;
  font-size: 90%;
  font-weight: 500;
}

.flt-list-one-way-card-outer {
  position: relative;
}

.flt-list-one-way-card-outer.with-badge {
  position: relative;
  border-top: 1px solid #bfbfbf;
  padding: 32px 0;
}

.flt-list-one-way-card-outer .flt-badge {
  position: absolute;
  min-width: 100px;
  background-color: #bfbfbf;
  top: 0;
  left: 50%;
  text-align: center;
  margin-left: -50px;
  padding: 2.66667px;
}

.trip-type {
  padding: 16px 0;
  text-align: center;
}

.trip-type.with-line {
  padding: 16px 0;
  border-right: 1px solid #bfbfbf;
  border-left: 1px solid #bfbfbf;
}

.trip-type.with-text {
  padding: 0;
}

.trip-type .text-out {
  text-align: center;
}

.trip-type .text-out:before, .trip-type .text-out:after {
  display: table;
  content: " ";
}

.trip-type .text-out:after {
  clear: both;
}

.trip-type .flt-icons {
  position: relative;
  padding: 8px 0;
}

.trip-type .flt-icons .icons {
  text-align: center;
  position: absolute;
  width: 16px;
  height: 16px;
  color: #3c3c3c;
  left: 50%;
  margin-left: -7.61905px;
  top: 50%;
  margin-top: -6.66667px;
  font-size: 110%;
}

.trip-type .flt-icons .icons.rotate-45 {
  text-align: right;
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=1)";
  transform: rotate(-45deg);
}

.trip-type .flt-icons .line:after, .trip-type .flt-icons .line:before {
  position: absolute;
  content: '';
  width: 32%;
  height: 2px;
  top: 50%;
  margin-top: -1px;
  background: #bfbfbf;
}

.trip-type .flt-icons .line:after {
  left: 0;
}

.trip-type .flt-icons .line:before {
  right: 0;
}

.trip-type .flt-icons.full {
  padding: 0;
  min-height: 50px;
}

.trip-type .flt-icons.full .icons {
  text-align: center;
  position: absolute;
  width: 16px;
  height: 26px;
  color: #3c3c3c;
  left: 3px;
  margin-left: -7.61905px;
  top: 50%;
  margin-top: -6px;
  font-size: 110%;
}

.trip-type .flt-icons.full .icons.rotate-45 {
  text-align: right;
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=1)";
  transform: rotate(-20deg);
}

.trip-type .flt-icons.full .line-vertical:after, .trip-type .flt-icons.full .line-vertical:before {
  position: absolute;
  content: '';
  height: 20%;
  width: 2px;
  top: 50%;
  left: 0;
  margin-top: -16px;
  margin-top: 3px;
  background: #bfbfbf;
}

.trip-type .flt-icons.full .line-vertical:after {
  bottom: 0;
  top: auto;
}

.trip-type .flt-icons.full .line-vertical:before {
  top: 0;
}

.list-one-way .trip.location.header {
  font-size: 110%;
  font-weight: 600;
}

.list-one-way .trip.location .state,
.list-one-way .trip.location .carrier {
  font-weight: 400;
  color: #565656;
}

.list-one-way .trip.time-info.large {
  font-size: 200%;
}

.list-one-way .trip.time-info .days,
.list-one-way .trip.time-info .date,
.list-one-way .trip.time-info .year,
.list-one-way .trip.time-info .room-info,
.list-one-way .trip.time-info .room-type {
  color: #6f6f6f;
}

.list-one-way .trip.time-info .room-type {
  font-weight: 500;
}

.list-one-way .trip.flt-info.large {
  font-size: 200%;
  line-height: 1;
}

.list-one-way .trip.flt-info .name,
.list-one-way .trip.flt-info .number {
  font-weight: 300;
  color: #6f6f6f;
}

.list-one-way.out .header {
  font-size: 110%;
  font-weight: 600;
}

.list-one-way .flt-name {
  color: #333333;
  font-weight: 600;
  line-height: 1.3;
  padding-top: 4px;
  font-size: 115%;
}

.list-one-way.flt {
  position: relative;
  padding-left: 48px;
}

.list-one-way.flt .flt-logo {
  width: 32px;
  height: 32px;
  position: absolute;
  left: 0;
  top: 2.72727px;
}

.list-one-way.flt .flt-logo img {
  border-radius: 4px;
  display: block;
  width: 100%;
}

.list-one-way.flt.large {
  padding-left: 64px;
}

.list-one-way.flt.large .flt-logo {
  border-radius: 4px;
  width: 41.6px;
  height: 41.6px;
}

.list-one-way.flt .list-out {
  margin-left: -5.33333px;
}

.list-one-way.info .flt-code {
  font-size: 90%;
  font-weight: 600;
  color: gray;
  text-transform: uppercase;
}

.list-one-way.info .header {
  line-height: 1.3;
  font-size: 120%;
  font-weight: 600;
}

@media (max-width: 768px) {
  .list-one-way.info .header {
    font-size: 90%;
  }
}

.list-one-way.with-margin {
  margin-bottom: 10.66667px;
}

.list-one-way-price {
  display: block;
}

.list-one-way-price .new-price {
  line-height: 1;
  font-size: 220%;
  font-weight: 600;
  margin-right: 10px;
}

.list-one-way-price .new-price .icons {
  font-size: 80%;
  margin-right: 2px;
}

.list-one-way-price .new-price .price {
  font-size: 120%;
}

.list-one-way-price .old-price {
  line-height: 1;
  font-size: 110%;
  font-weight: 300;
  color: #6f6f6f;
}

.list-one-way-price .old-price .icons {
  font-size: 80%;
  margin-right: 2px;
}

.list-one-way-price .old-price .price {
  font-size: 120%;
  text-decoration: line-through;
}

.flt-detail-tbs {
  border-top: 1px solid #bfbfbf;
}

.flt-detail-tbs.active {
  background-color: #f7f7f7;
}

.link-booknow-footer {
  padding: 6px;
}

.link-booknow-footer:before, .link-booknow-footer:after {
  display: table;
  content: " ";
}

.link-booknow-footer:after {
  clear: both;
}

.link-booknow-footer .flt-dtl-btn {
  float: left;
  font-size: 90%;
  font-weight: 600;
  text-transform: uppercase;
}

.link-booknow-footer .flt-dtl-btn .icons {
  margin-left: 3px;
}

.link-booknow-footer .flt-dtl-btn .link-booknow {
  color: #6f6f6f;
  cursor: pointer;
}

.link-booknow-footer .flt-trip-adv {
  float: right;
}

@media (max-width: 768px) {
  .link-booknow-footer .flt-dtl-btn, .link-booknow-footer .flt-trip-adv {
    float: none;
  }
}

.rout-info-panel {
  position: relative;
  z-index: 4;
  margin: 16px auto;
}

.rout-info-panel .rout-text {
  float: left;
}

.rout-info-panel .rout-due-time {
  float: right;
}

.rout-info-panel .plan-changer:after {
  position: absolute;
  z-index: -1;
  left: 0;
  right: 0;
  width: 100%;
  top: 50%;
  content: '';
  background-color: #bfbfbf;
  height: 2px;
}

.rout-plan-text {
  background-color: #fff;
  border: 1px solid #bfbfbf;
  border-radius: 4px;
  line-height: 1;
  padding: 6px;
  margin-bottom: 16px;
}

.rout-plan-text:before, .rout-plan-text:after {
  display: table;
  content: " ";
}

.rout-plan-text:after {
  clear: both;
}

.rout-plan-text.with-duel-rout .rout-text,
.rout-plan-text.with-duel-rout .rout-due-time {
  float: none;
}

.rout-plan-text.with-duel-rout .rout-text {
  margin: 3px;
  font-weight: 500;
}

.rout-plan-text.plan-changer {
  width: 90%;
  text-align: center;
  margin: 0 auto;
  font-weight: 600;
  text-align: center;
  border-radius: 40px;
}

.rout-plan-text.plan-changer .with-duel {
  display: flex;
}

@media (max-width: 768px) {
  .rout-plan-text .list-out li {
    float: none;
    border: 0;
  }
}

.icons.rotate-45 {
  text-align: right;
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=1)";
  transform: rotate(-20deg);
}

.flurry-flt-card {
  margin: 16px auto;
  border-bottom: 1px solid #bfbfbf;
  padding-bottom: 16px;
}

.flurry-flt-card:last-child {
  padding: 0;
  border: 0;
  margin: 0;
}

.flurry-flt-card:first-child {
  margin-top: 0;
}

.flurry-flt-card .flurry-flt-icons {
  margin-bottom: 6px;
}

.flurry-flt-card .borderd {
  border-right: 1px solid #bfbfbf;
}

.flurry-flt-card .btn-card .hoverable {
  margin: 10px auto;
}

.form-modal-padding {
  max-width: 80%;
  margin: 0 auto;
}

@media (max-width: 768px) {
  .form-modal-padding {
    max-width: 94%;
  }
}

.ich-ico-tabs {
  display: block;
  width: 36px;
}

.ich-ico-tabs img {
  display: block;
  width: 100%;
}

.roundtrip-farebox {
  display: none;
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 250;
  background-color: #bfbfbf;
}

.flight-list-split-fixed {
  z-index: 1000;
  position: fixed;
  display: none;
  padding: 0;
  margin: 0;
  bottom: 0;
  box-shadow: 0 10px 15px 8px rgba(0, 0, 0, 0.1), 0 5px 20px 10px rgba(0, 0, 0, 0.08), 0 5px 8px -4px rgba(0, 0, 0, 0.1);
}

.fltl-split-artical.borderd {
  padding-left: 16px;
  border-left: 2px solid rgba(255, 255, 255, 0.2);
}

.fltl-split-artical .bkg-policy .text {
  display: block;
}

.fltl-split-artical .bkg-policy .icons {
  font-size: 70%;
  margin-left: 3px;
}

.fltl-split-artical .list-one-way .text-out li {
  font-size: 80%;
  padding: 0;
}

.fltl-split-price {
  border-left: 2px solid rgba(255, 255, 255, 0.2);
  padding-left: 16px;
}

.fltl-split-price:before, .fltl-split-price:after {
  display: table;
  content: " ";
}

.fltl-split-price:after {
  clear: both;
}

.fltl-split-price .link-booknow {
  margin: 0;
}

.fltl-split-price .destination-price {
  display: block;
  text-align: right;
  margin: 6px auto;
}

.fltl-split-price .bkg-policy {
  font-size: 80%;
  color: #fff;
}

.fltl-split-price .bkg-policy .text {
  display: block;
}

.fltl-split-price .bkg-policy .icons {
  font-size: 70%;
}

.fltl-split-price .booking-price {
  display: block;
  line-height: 1.2;
  font-size: 160%;
  color: #fff;
  cursor: pointer;
}

.fltl-split-price .booking-price .icons {
  font-size: 75%;
  margin-right: 0;
}

.fltl-split-price .booking-price .text {
  font-weight: 600;
}

@media (max-width: 768px) {
  .fltl-split-price {
    padding-left: 6px;
    padding-top: 6px;
    border-left: 0;
    border-top: 2px solid rgba(255, 255, 255, 0.2);
  }
  .fltl-split-price .destination-price {
    float: left;
  }
  .fltl-split-price .link-booknow-info {
    float: right;
    min-width: 60%;
  }
}

.fltli-split-tbs-content {
  overflow: hidden;
  overflow-y: auto;
  padding: 6px 16px;
}

.routinfo-panel-out.borderd {
  position: relative;
}

.routinfo-panel-out.borderd:after {
  content: '';
  position: absolute;
  left: -10px;
  top: 0;
  width: 1px;
  border-left: 2px dashed #bfbfbf;
  bottom: 0;
}

.flt-destination-price {
  margin-top: 16px;
}

.flt-destination-price .booking-price {
  margin: 6px auto;
  display: block;
  line-height: 1.2;
  font-size: 140%;
}

.flt-destination-price .booking-price .icons {
  font-size: 75%;
  margin-right: 1.5px;
}

.flt-destination-price .booking-price .text {
  font-weight: 600;
}

.tourfair-fairlist {
  font-size: 90%;
  margin-bottom: 16px;
}

.tourfair-fairlist .table {
  margin-bottom: 6px;
}

.tourfair-fairlist .table > thead > tr > th,
.tourfair-fairlist .table > thead > tr > td,
.tourfair-fairlist .table > tbody > tr > th,
.tourfair-fairlist .table > tbody > tr > td,
.tourfair-fairlist .table > tfoot > tr > th,
.tourfair-fairlist .table > tfoot > tr > td {
  text-align: center;
  padding: 5px 0;
}

.tourfair-fairlist .tourfair-btn .hoverable {
  display: block;
}

.tourfair-seat-map {
  border-left: 1px solid #bfbfbf;
  border-right: 1px solid #bfbfbf;
}

.tourfair-seat-map:before, .tourfair-seat-map:after {
  display: table;
  content: " ";
}

.tourfair-seat-map:after {
  clear: both;
}

.tourfair-seat-map .block-list {
  margin: 0;
  padding: 0;
}

.tourfair-seat-map .block-list:before, .tourfair-seat-map .block-list:after {
  display: table;
  content: " ";
}

.tourfair-seat-map .block-list:after {
  clear: both;
}

.tourfair-seat-map .block-list li {
  list-style: none;
  width: 14.28%;
  float: left;
  text-align: center;
}

.tourfair-seat-map .block-list li a {
  cursor: pointer;
  display: block;
  color: #fff;
}

.tourfair-seat-map .column-seat-block {
  position: relative;
  margin: 2px 1.5px;
  display: block;
  height: 40px;
  border-radius: 4px;
  line-height: 40px;
}

.tourfair-seat-map .column-seat-block:after {
  content: '';
  position: absolute;
  z-index: 2;
  top: 16px;
  left: 4px;
  right: 4px;
  bottom: 4px;
  border-radius: 4px;
  border: 3px solid #fff;
  border-top: 0;
  filter: alpha(opacity=30);
  opacity: 0.3;
}

.tourfair-seat-map .column-seat-block .icons {
  top: 2px;
  right: 2px;
  line-height: 1;
  position: absolute;
  font-size: 90%;
  z-index: 220;
}

.tourfair-seat-map .column-seat-block.bg-fair-booked {
  text-align: center;
}

.tourfair-seat-map .column-seat-block.bg-fair-booked .icons {
  font-size: 140%;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  line-height: 40px;
}

.tourfair-seat-map .column-seat-block .tittle {
  position: relative;
  z-index: 3;
  font-weight: 800;
  font-size: 110%;
  text-transform: uppercase;
}

.tourfair-seat-map .column-seat-block.empty {
  background-color: transparent;
}

.tourfair-seat-map .column-seat-block.empty:after {
  display: none;
}

.tourfair-seat-map-section {
  position: relative;
  width: 90%;
  margin: 0 auto;
  background-color: #f2f2f2;
}

.tourfair-seat-map-section:after, .tourfair-seat-map-section:before {
  content: '';
  width: 11.42857px;
  height: 30%;
  position: absolute;
  top: 35%;
  z-index: 109;
  background-color: #f2f2f2;
}

.tourfair-seat-map-section:after {
  left: 100%;
}

.tourfair-seat-map-section:before {
  right: 100%;
}

.tourfair-seat-map-section .aircap-bg {
  z-index: -2px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 99.5%;
}

.tourfair-seat-map-section .aircap-bg .ich-icons {
  height: auto;
  width: 100%;
}

.tourfair-seat-map-section .aircap-back {
  z-index: -2px;
  position: absolute;
  left: 0;
  right: 0;
  top: 99.5%;
}

.tourfair-seat-map-section .aircap-back .ich-icons {
  height: auto;
  width: 100%;
}

.tourfair-seat-map-article {
  position: relative;
  padding: 16px 16px;
}

.tourfair-seat-map-article:before, .tourfair-seat-map-article:after {
  display: table;
  content: " ";
}

.tourfair-seat-map-article:after {
  clear: both;
}

.bg-fair-plist,
.seat-mapper-list,
.airline-plist {
  margin: 0;
  padding: 0;
}

.bg-fair-plist li,
.seat-mapper-list li,
.airline-plist li {
  list-style: none;
}

.seat-mapper-list {
  margin-bottom: 6px;
}

.seat-mapper-list .tittle {
  font-size: 110%;
  font-weight: 600;
}

.seat-mapper-list .icons-block {
  font-size: 200%;
  margin: 16px 0;
}

.seat-mapper-list .icons-block .icons {
  padding: 16px;
  border-radius: 100%;
  background-color: #f2f2f2;
}

.options-color {
  position: relative;
  padding: 4px 0;
  padding-left: 33.33333px;
}

.options-color .color-list {
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -13.33333px;
  z-index: 3;
  text-align: center;
  color: #fff;
  border-radius: 4px;
}

.options-color .color-list .icons {
  position: absolute;
  left: 0;
  z-index: 3;
  top: 0;
  width: 22.22222px;
  line-height: 25px;
}

.options-color .color-list .color-option {
  display: block;
  width: 22.22222px;
  height: 22.22222px;
}

.options-color .text-tittle .icons {
  font-size: 80%;
  margin-right: 2px;
}

.seat-map-way {
  margin-bottom: 16px;
}

.seat-map-way .tittle {
  font-size: 180%;
  font-weight: 300;
}

.seat-map-way .seat-map-inner {
  border-radius: 4px;
  background-color: #fafafa;
  margin: 6px 0;
  padding: 3px 9px;
}

.seat-map-way .booknow {
  margin-top: 3px;
}

/* Switch
   ========================================================================== */
.switch,
.switch * {
  -webkit-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -ms-user-select: none;
}

.switch {
  display: inline-block;
  position: relative;
  margin: 0 5px;
}

.switch .active,
.switch .inactive {
  z-index: 2;
  position: absolute;
  top: 50%;
  margin-top: -8px;
  text-transform: uppercase;
}

.switch .active {
  left: 24px;
}

.switch .inactive {
  right: 20px;
}

.switch label {
  cursor: pointer;
  margin: 0;
  padding: 0;
  position: relative;
  height: 20px;
  width: 50px;
}

.switch label input[type='checkbox'] {
  opacity: 0;
  width: 0;
  height: 0;
}

.switch label input[type='checkbox']:checked + .lever {
  background-color: #bdd7bd;
}

.switch label input[type='checkbox']:checked + .lever:after {
  background-color: #357935;
  left: 28px;
}

.switch label input[type='checkbox'][disabled] + .lever {
  cursor: default;
}

.switch label .lever {
  content: '';
  display: block;
  top: -2.85714px;
  left: 0;
  right: 0;
  position: absolute;
  height: 23px;
  background-color: #e5a9ac;
  border-radius: 3px;
  -webkit-transition: background 0.3s ease;
  -o-transition: background 0.3s ease;
  transition: background 0.3s ease;
}

.switch label .lever:after {
  content: '';
  z-index: 10;
  position: absolute;
  display: block;
  width: 20px;
  height: 20px;
  background-color: #e62931;
  border-radius: 3px;
  left: 2px;
  top: 50%;
  margin-top: -10px;
  -webkit-transition: left 0.3s ease, background 0.3s ease;
  -o-transition: left 0.3s ease, background 0.3s ease;
  transition: left 0.3s ease, background 0.3s ease;
}

.switch label input[type='checkbox'][disabled] + .lever:after,
.switch label input[type='checkbox'][disabled]:checked + .lever:after {
  background-color: #eeeeee;
}

.hotel-search-advanced,
.package-search-advanced {
  background-color: #e6e6e6;
  padding: 16px 0;
  margin-bottom: 16px;
}

@media (max-width: 768px) {
  .hotel-search-advanced,
  .package-search-advanced {
    padding: 3px 0;
    padding-top: 6px;
  }
}

.hotel-destination .heading,
.hotel-travel-dates .heading,
.hotel-types .heading {
  font-weight: 700;
  line-height: 1;
  font-size: 80%;
  text-transform: uppercase;
}

.hotel-destination .sub-text,
.hotel-travel-dates .sub-text,
.hotel-types .sub-text {
  margin-right: 6px;
}

.hotel-destination .adults-count,
.hotel-destination .hotel-checkin,
.hotel-destination .child-count,
.hotel-travel-dates .adults-count,
.hotel-travel-dates .hotel-checkin,
.hotel-travel-dates .child-count,
.hotel-types .adults-count,
.hotel-types .hotel-checkin,
.hotel-types .child-count {
  font-size: 110%;
}

.hotel-destination .rate-count,
.hotel-travel-dates .rate-count,
.hotel-types .rate-count {
  font-size: 56%;
}

@media (max-width: 768px) {
  .hotel-destination .heading,
  .hotel-travel-dates .heading,
  .hotel-types .heading {
    font-size: 120%;
  }
  .hotel-destination .adults-count,
  .hotel-destination .hotel-checkin,
  .hotel-destination .child-count,
  .hotel-travel-dates .adults-count,
  .hotel-travel-dates .hotel-checkin,
  .hotel-travel-dates .child-count,
  .hotel-types .adults-count,
  .hotel-types .hotel-checkin,
  .hotel-types .child-count {
    font-size: 90%;
  }
}

.form-count,
.form-flight-count {
  position: relative;
  cursor: pointer;
}

.form-count .bs-caret,
.form-flight-count .bs-caret {
  position: absolute;
  z-index: 3;
  top: 50%;
  right: 10.66667px;
  margin-top: -11.42857px;
}

.form-count .text,
.form-flight-count .text {
  color: #6f6f6f;
}

.form-count .count-traveller,
.form-count .count-rooms,
.form-flight-count .count-traveller,
.form-flight-count .count-rooms {
  color: #000;
  font-weight: 600;
}

.serch-room-count,
.serch-flt-traveller-count {
  position: relative;
}

.serch-room-count.active .search-group-wrapper,
.serch-room-count.active .search-flt-wrapper,
.serch-flt-traveller-count.active .search-group-wrapper,
.serch-flt-traveller-count.active .search-flt-wrapper {
  display: block;
}

.serch-room-count.active .form-count,
.serch-flt-traveller-count.active .form-count {
  border-color: #0096fb;
}

.search-group-rooms {
  max-height: 320px;
  overflow-x: auto;
  padding: 6px 16px;
}

.search-group-rooms .heading {
  margin: 0;
  font-size: 120%;
}

.search-group-rooms.max {
  padding-right: 16px;
}

.search-group-wrapper {
  display: none;
  padding-bottom: 6px;
  border-radius: 4px;
  position: absolute;
  border: 1px solid #bfbfbf;
  right: 0;
  z-index: 1000;
  top: 100%;
  background-color: #fff;
  min-width: 300px;
  box-shadow: 0 15px 20px 10px rgba(0, 0, 0, 0.05), 0 6px 20px 10px rgba(0, 0, 0, 0.02), 0 6px 10px -6px rgba(0, 0, 0, 0.05);
}

.search-group-wrapper .btn-serch.with-border {
  padding: 0 6px;
  padding-top: 6px;
  border-top: 1px solid #bfbfbf;
}

.travellers-serch-wrapper {
  position: relative;
}

.search-flt-wrapper {
  display: none;
  padding-bottom: 10px;
  padding-top: 10px;
  border-radius: 4px;
  position: absolute;
  right: 0;
  z-index: 1000;
  top: 100%;
  background-color: #fff;
  min-width: 220px;
  box-shadow: 0 15px 20px 10px rgba(0, 0, 0, 0.05), 0 6px 20px 10px rgba(0, 0, 0, 0.02), 0 6px 10px -6px rgba(0, 0, 0, 0.05);
}

.search-flt-wrapper .btn-serch.with-border {
  padding: 0 6px;
  padding-top: 6px;
  border-top: 1px solid #bfbfbf;
}

.search-flt-wrapper .booking-modal-room-artical,
.search-flt-wrapper .booking-modal-flight-artical {
  padding: 6px 16px;
}

.hotel-hightlight-scroll,
.package-hightlight-scroll {
  box-shadow: 0 15px 20px 10px rgba(0, 0, 0, 0.05), 0 6px 20px 10px rgba(0, 0, 0, 0.02), 0 6px 10px -6px rgba(0, 0, 0, 0.05);
  padding: 16px 0;
  padding-top: 13.33333px;
  display: none;
  border: 0;
  margin: 0;
}

.hotel-hightlight-scroll .hotel-destination .heading,
.hotel-hightlight-scroll .hotel-travel-dates .heading,
.hotel-hightlight-scroll .hotel-types .heading,
.package-hightlight-scroll .hotel-destination .heading,
.package-hightlight-scroll .hotel-travel-dates .heading,
.package-hightlight-scroll .hotel-types .heading {
  font-size: 80%;
  font-weight: 400;
  line-height: 1;
  text-transform: uppercase;
}

.hotel-hightlight-scroll .hotel-destination .adults-count,
.hotel-hightlight-scroll .hotel-destination .hotel-checkin,
.hotel-hightlight-scroll .hotel-destination .child-count,
.hotel-hightlight-scroll .hotel-travel-dates .adults-count,
.hotel-hightlight-scroll .hotel-travel-dates .hotel-checkin,
.hotel-hightlight-scroll .hotel-travel-dates .child-count,
.hotel-hightlight-scroll .hotel-types .adults-count,
.hotel-hightlight-scroll .hotel-types .hotel-checkin,
.hotel-hightlight-scroll .hotel-types .child-count,
.package-hightlight-scroll .hotel-destination .adults-count,
.package-hightlight-scroll .hotel-destination .hotel-checkin,
.package-hightlight-scroll .hotel-destination .child-count,
.package-hightlight-scroll .hotel-travel-dates .adults-count,
.package-hightlight-scroll .hotel-travel-dates .hotel-checkin,
.package-hightlight-scroll .hotel-travel-dates .child-count,
.package-hightlight-scroll .hotel-types .adults-count,
.package-hightlight-scroll .hotel-types .hotel-checkin,
.package-hightlight-scroll .hotel-types .child-count {
  font-size: 110%;
  font-weight: 600;
}

.hotel-hightlight-scroll .hotel-destination .rate-count,
.hotel-hightlight-scroll .hotel-travel-dates .rate-count,
.hotel-hightlight-scroll .hotel-types .rate-count,
.package-hightlight-scroll .hotel-destination .rate-count,
.package-hightlight-scroll .hotel-travel-dates .rate-count,
.package-hightlight-scroll .hotel-types .rate-count {
  font-size: 56%;
}

@media (max-width: 768px) {
  .hotel-hightlight-scroll,
  .package-hightlight-scroll {
    padding: 6px 0;
  }
  .hotel-hightlight-scroll .hotel-destination,
  .hotel-hightlight-scroll .hotel-travel-dates,
  .hotel-hightlight-scroll .hotel-types,
  .package-hightlight-scroll .hotel-destination,
  .package-hightlight-scroll .hotel-travel-dates,
  .package-hightlight-scroll .hotel-types {
    margin: 2px auto;
  }
  .hotel-hightlight-scroll .hotel-destination .heading,
  .hotel-hightlight-scroll .hotel-travel-dates .heading,
  .hotel-hightlight-scroll .hotel-types .heading,
  .package-hightlight-scroll .hotel-destination .heading,
  .package-hightlight-scroll .hotel-travel-dates .heading,
  .package-hightlight-scroll .hotel-types .heading {
    font-size: 120%;
  }
  .hotel-hightlight-scroll .hotel-destination .adults-count,
  .hotel-hightlight-scroll .hotel-destination .hotel-checkin,
  .hotel-hightlight-scroll .hotel-destination .child-count,
  .hotel-hightlight-scroll .hotel-travel-dates .adults-count,
  .hotel-hightlight-scroll .hotel-travel-dates .hotel-checkin,
  .hotel-hightlight-scroll .hotel-travel-dates .child-count,
  .hotel-hightlight-scroll .hotel-types .adults-count,
  .hotel-hightlight-scroll .hotel-types .hotel-checkin,
  .hotel-hightlight-scroll .hotel-types .child-count,
  .package-hightlight-scroll .hotel-destination .adults-count,
  .package-hightlight-scroll .hotel-destination .hotel-checkin,
  .package-hightlight-scroll .hotel-destination .child-count,
  .package-hightlight-scroll .hotel-travel-dates .adults-count,
  .package-hightlight-scroll .hotel-travel-dates .hotel-checkin,
  .package-hightlight-scroll .hotel-travel-dates .child-count,
  .package-hightlight-scroll .hotel-types .adults-count,
  .package-hightlight-scroll .hotel-types .hotel-checkin,
  .package-hightlight-scroll .hotel-types .child-count {
    font-size: 90%;
  }
}

.search-sticky-sidebar {
  position: relative;
}

.search-sticky {
  overflow-x: auto;
}

.sidebar-sticky.fixed-top {
  top: 78px;
  border: 0;
  position: fixed;
  z-index: 888;
  background-color: #fff;
  margin: 0;
  padding-right: 16px;
}

.sidebar-sticky.fixed-bottom {
  position: absolute;
  bottom: 0px;
}

@media (max-width: 768px) {
  .mobile-filter-menu {
    box-shadow: 0 20px 30px 15px rgba(0, 0, 0, 0.1), 0 10px 40px 20px rgba(0, 0, 0, 0.08), 0 10px 15px -8px rgba(0, 0, 0, 0.1);
    position: fixed;
    max-width: 380px;
    width: 86%;
    left: 0;
    top: 0;
    margin: 0;
    padding: 16px;
    height: 100%;
    height: calc(100% + 60px);
    height: -moz-calc(100%);
    padding-bottom: 60px;
    background-color: #fff;
    z-index: 1110;
    overflow-y: auto;
    will-change: transform;
    backface-visibility: hidden;
    transform: translateX(-105%);
  }
  .mobile-filter-menu.not-fixerd {
    position: relative;
    max-width: initial;
    width: auto;
    transform: translateX(0);
    box-shadow: none;
    padding-bottom: 0;
    z-index: auto;
  }
}

.search-advanced-mobile {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background-color: #fff;
  border-top: 1px solid #bfbfbf;
}

.search-advanced-mobile .btn-serch-toggle {
  display: block;
  font-weight: 600;
  padding: 6px 0;
}

.page-loader {
  position: fixed;
  z-index: 1308;
  padding: 32px 0;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  overflow: hidden;
}

.page-loader .page-loader-wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.7);
  overflow: hidden;
}

.loader-img {
  width: 180px;
  margin: 16px auto;
}

.loader-img img {
  display: block;
  width: 100%;
}

@media (max-width: 768px) {
  .loader-img {
    width: 120px;
    margin-top: 48px;
  }
}

.loader-container {
  position: relative;
  z-index: 40;
  max-width: 600px;
  margin: 0 auto;
  padding: 32px;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 15px 20px 10px rgba(0, 0, 0, 0.05), 0 6px 20px 10px rgba(0, 0, 0, 0.02), 0 6px 10px -6px rgba(0, 0, 0, 0.05);
}

.loader-container .header {
  font-size: 220%;
  font-weight: 800;
}

.loader-container .sub-header {
  font-size: 120%;
  text-transform: uppercase;
}

@media (max-width: 768px) {
  .loader-container {
    max-width: 94%;
    padding: 16px;
  }
}

.loader-panel .time {
  font-size: 120%;
  margin-top: 6px;
}

@media (max-width: 768px) {
  .loader-panel .time {
    margin-top: 19.2px;
    font-size: 110%;
  }
}

.guests-loader {
  text-align: center;
  margin: 32px auto;
  margin-bottom: 6px;
}

.guests-loader:before, .guests-loader:after {
  display: table;
  content: " ";
}

.guests-loader:after {
  clear: both;
}

.guests-loader .list-out {
  display: inline-block;
  text-align: left;
}

.guests-loader .list-out li {
  float: left;
  padding: 0 6px;
  font-size: 120%;
}

.guests-loader .list-out li .fig {
  width: 28.8px;
  float: left;
  margin-right: 6px;
}

.guests-loader .list-out li .counts {
  float: left;
}

.guests-loader .list-out li .text {
  font-weight: 600;
  font-size: 140%;
}

.guests-loader .list-out li .info {
  font-size: 80%;
}

.guests-loader .list-out li img {
  display: block;
  width: 100%;
}

@media (max-width: 768px) {
  .guests-loader .list-out li {
    padding: 0 3px;
  }
  .guests-loader .list-out li .fig {
    width: 27.2px;
    margin-right: 2px;
  }
  .guests-loader .list-out li .counts {
    font-size: 80%;
  }
}

.progress-stick {
  position: relative;
  margin: 0;
}

.progress-stick .progress {
  margin: 0;
}

.progress-stick .percent-count {
  z-index: 3;
  font-weight: 6;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  font-weight: 700;
  font-size: 80%;
}

.download-pdf-widgit {
  border: 2px dashed #bfbfbf;
  border-radius: 8px;
}

.download-pdf-widgit:before, .download-pdf-widgit:after {
  display: table;
  content: " ";
}

.download-pdf-widgit:after {
  clear: both;
}

.download-pdf-widgit .widgit-heading {
  float: left;
  padding: 16px 32px;
}

.download-pdf-widgit .widgit-heading .heading {
  font-size: 200%;
  font-weight: 700;
}

.download-pdf-widgit .widgit-heading .heading .link {
  font-size: 70%;
  margin-left: 32px;
}

.download-pdf-widgit .widgit-heading .heading .link a {
  text-decoration: underline;
}

.download-pdf-widgit .widgit-btns {
  padding: 16px 16px;
  border-left: 2px dashed #bfbfbf;
  float: right;
  max-width: 200px;
}

.download-pdf-widgit .widgit-btns img {
  display: block;
  width: 100%;
}

.breadcrumb-section {
  margin: 0;
  padding: 3px 0;
}

.breadcrumb-section .breadcrumb {
  background-color: transparent;
  margin: 0;
}

.page-layout {
  background-color: #f9fafb;
}

.dashboard-page {
  overflow: hidden;
  position: relative;
}

.dashboard-section {
  position: relative;
}

.dashboard-warapper {
  padding: 16px 0;
  padding-top: 0;
}

.home-dasshboard-wrapper {
  background-color: #fff;
  border: 1px solid #cccccc;
  box-shadow: 0 2px 4px 2px rgba(0, 0, 0, 0.03), 0 1px 4px 2px rgba(0, 0, 0, 0.01), 0 1px 3px -1px rgba(0, 0, 0, 0.01);
  border-radius: 4px;
}

.no-border {
  border: 0;
}

.dasshboard-content-widget {
  padding: 16px 16px;
}

.content-widget-about {
  margin-bottom: 16px;
}

.content-widget-about .heading {
  margin: 0;
  padding: 0;
  margin-bottom: 6px;
  font-size: 115%;
}

.section-with-checkbox .label-header {
  margin: 0;
  padding: 0;
  font-size: 90%;
  font-weight: 600;
  line-height: 1.3;
}

.content-widget-footer {
  border-top: 1px solid #bfbfbf;
  padding-top: 16px;
  padding-bottom: 0;
}

.content-widget-footer:before, .content-widget-footer:after {
  display: table;
  content: " ";
}

.content-widget-footer:after {
  clear: both;
}

.content-widget-footer.no-border {
  border: 0;
  padding-top: 0;
}

.content-widget-footer .left {
  float: left;
  max-width: 50%;
}

.content-widget-footer .right {
  max-width: 50%;
  float: right;
}

.quotation-form-widget {
  margin-top: 32px;
}

.wallet-article {
  border: 1px solid #bfbfbf;
  padding: 6px 16px;
  min-height: 76px;
  margin-bottom: 16px;
  border-radius: 4px;
  position: relative;
  padding-left: 68.4px;
}

.wallet-article.white {
  background: #fff;
}

.wallet-article.white:after {
  border-top-color: #fff;
}

.wallet-article .figure {
  position: absolute;
  width: 38px;
  left: 16px;
  text-align: center;
  font-size: 400%;
  font-weight: 600;
  line-height: 38px;
  top: 16px;
}

.wallet-article .figure img {
  width: 100%;
  display: block;
}

.wallet-article .wallet-card .count {
  font-size: 160%;
  font-weight: 600;
}

.wallet-article .wallet-card .icons {
  font-size: 70%;
}

.wallet-article .wallet-card .header {
  font-size: 90%;
  line-height: 1;
}

@media (max-width: 768px) {
  .wallet-article .wallet-card {
    min-height: 130px;
  }
}

.wallet-well {
  background-color: #d9d9d9;
  padding: 6px 16px;
  margin-bottom: 6px;
  border-radius: 4px;
}

.wallet-well .tittle {
  margin: 0;
  padding: 0;
  margin-bottom: 6px;
}

.flt-summary-steps-panel {
  padding: 40px 0;
}

.flt-summary-steps-panel .header {
  margin: 0;
  padding: 6px 0;
  color: #fff;
}

.flt-summary-steps-panel.with-bg {
  padding: 8px 0;
}

.steps {
  position: relative;
  min-height: 42px;
  padding-left: 54.6px;
  padding-top: 6px;
}

.steps .pillar {
  position: absolute;
  width: 42px;
  height: 42px;
  line-height: 46.2px;
  top: 50%;
  left: 0;
  border-radius: 100%;
  margin-top: -21px;
  background-color: rgba(0, 0, 0, 0.3);
  text-align: center;
}

.steps .pillar .icons {
  font-size: 130%;
}

.steps .circle {
  font-size: 85%;
  line-height: 1;
  text-transform: uppercase;
  font-weight: 600;
}

.steps .title {
  font-size: 120%;
  font-weight: 300;
}

.steps.active .pillar {
  background-color: #0080ff;
  color: #fff;
}

@-webkit-keyframes placeholderShimmer {
  0% {
    background-position: -1200px 0;
  }
  100% {
    background-position: 1200px 0;
  }
}

@keyframes placeholderShimmer {
  0% {
    background-position: -1200px 0;
  }
  100% {
    background-position: 1200px 0;
  }
}

.card.card-placeloader {
  position: static;
  overflow: hidden;
  animation: placeholderShimmer 2s linear;
  animation-iteration-count: infinite;
  background-color: #fff;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.08) 0%, rgba(0, 0, 0, 0.15) 15%, rgba(0, 0, 0, 0.08) 30%);
  background-size: 1200px 100%;
  max-width: 300rem;
}

.card.card-placeloader + .card.card-placeloader {
  margin-top: 2rem;
}

.card.card-placeloader + .card.card-placeloader {
  animation-delay: 0.15s;
}

.card.card-placeloader + .card.card-placeloader + .card.card-placeloader {
  animation-delay: 0.3s;
}

.card.card-placeloader
+ .card.card-placeloader
+ .card.card-placeloader
+ .card.card-placeloader {
  animation-delay: 0.45s;
}

.card.card-placeloader
+ .card.card-placeloader
+ .card.card-placeloader
+ .card.card-placeloader
+ .card.card-placeloader {
  animation-delay: 0.6s;
}

.card.card-placeloader,
.card.card-placeloader > :before,
.card.card-placeloader .image.header:after,
.card.card-placeloader .line,
.card.card-placeloader .line:after {
  background-color: #fff;
}

/* Image */
.card.card-placeloader .image:not(.header):not(.card) {
  height: 100px;
}

.card.card-placeloader .square.image:not(.header) {
  height: 0px;
  overflow: hidden;
  /* 1/1 aspect ratio */
  padding-top: 100%;
}

.card.card-placeloader .rectangular.image:not(.header) {
  height: 0px;
  overflow: hidden;
  /* 4/3 aspect ratio */
  padding-top: 75%;
}

/* Lines */
.card.card-placeloader .line {
  position: relative;
  height: 12px;
}

.card.card-placeloader .line:before,
.card.card-placeloader .line:after {
  top: 100%;
  position: absolute;
  content: '';
  background-color: inherit;
}

.card.card-placeloader .line:before {
  left: 0px;
}

.card.card-placeloader .line:after {
  right: 0px;
}

/* Any Lines */
.card.card-placeloader .line {
  margin-bottom: 7px;
}

.card.card-placeloader .line:before,
.card.card-placeloader .line:after {
  height: 7px;
}

.card.card-placeloader .line:not(:first-child) {
  margin-top: 7px;
}

/* Header Image + 2 Lines */
.card.card-placeloader .header {
  position: relative;
  overflow: hidden;
}

/* Line Outdent */
.card.card-placeloader .line:nth-child(1):after {
  width: 0%;
}

.card.card-placeloader .line:nth-child(2):after {
  width: 50%;
}

.card.card-placeloader .line:nth-child(3):after {
  width: 10%;
}

.card.card-placeloader .line:nth-child(4):after {
  width: 35%;
}

.card.card-placeloader .line:nth-child(5):after {
  width: 65%;
}

/* Header Line 1 & 2*/
.card.card-placeloader .header .line {
  margin-bottom: 9px;
}

.card.card-placeloader .header .line:before,
.card.card-placeloader .header .line:after {
  height: 9px;
}

.card.card-placeloader .header .line:not(:first-child) {
  margin-top: 9px;
}

.card.card-placeloader .header .line:after {
  width: 20%;
}

.card.card-placeloader .header .line:nth-child(2):after {
  width: 60%;
}

/* Image Header */
.card.card-placeloader .image.header .line {
  margin-left: 3em;
}

.card.card-placeloader .image.header .line:before {
  width: 10px;
}

.card.card-placeloader .image.header:after {
  display: block;
  height: 12px;
  content: '';
  margin-left: 3em;
}

/* Spacing */
.card.card-placeloader .image .line:first-child,
.card.card-placeloader .paragraph .line:first-child,
.card.card-placeloader .header .line:first-child {
  height: 0.01px;
}

.card.card-placeloader .image:not(:first-child):before,
.card.card-placeloader .paragraph:not(:first-child):before,
.card.card-placeloader .header:not(:first-child):before {
  height: 20px;
  content: '';
  display: block;
}

/* Inverted Content Loader */
.card.inverted.card-placeloader {
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.08) 0%, rgba(255, 255, 255, 0.14) 15%, rgba(255, 255, 255, 0.08) 30%);
}

.card.inverted.card-placeloader,
.card.inverted.card-placeloader > :before,
.card.inverted.card-placeloader .image.header:after,
.card.inverted.card-placeloader .line,
.card.inverted.card-placeloader .line:after {
  background-color: #000;
}

/*******************************
            Variations
*******************************/
/*-------------------
        Sizes
--------------------*/
.card.card-placeloader .full.line.line.line:after {
  width: 0%;
}

.card.card-placeloader .very.long.line.line.line:after {
  width: 10%;
}

.card.card-placeloader .long.line.line.line:after {
  width: 35%;
}

.card.card-placeloader .medium.line.line.line:after {
  width: 50%;
}

.card.card-placeloader .short.line.line.line:after {
  width: 65%;
}

.card.card-placeloader .very.short.line.line.line:after {
  width: 80%;
}

/*-------------------
        Fluid
--------------------*/
.card.fluid.card-placeloader {
  max-width: none;
}

.page-placeloader {
  margin: 16px auto;
}

.page-placeloader .card {
  margin: 6px auto;
}

.hotel-search-cards {
  position: relative;
}

.split-flt-artical {
  background-color: #222222;
  padding: 6px 16px;
  border-radius: 4px;
}

.split-flt-artical.active {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.split-flt-artical .list-out li {
  color: #fff;
}

.split-flt-artical .list-one-way.info {
  color: #fff;
}

.split-flt-artical .list-one-way.info .flt-code {
  font-weight: 400;
  color: #fff;
}

.split-flt-artical .list-one-way.info .header {
  color: #fff;
}

.split-flt-artical .list-one-way.info .date {
  color: #fff;
}

.split-flt-artical .list-payment-type .booking-price {
  color: #fff;
}

.split-flt-artical .list-payment-type .bkg-policy {
  color: #fff;
}

.split-flt-artical .link-booknow-info .text-link {
  padding: 6px 0;
  line-height: 1;
}

.fltli-split-tbs {
  background-color: #fff;
}

.flt-detail-expand {
  padding: 0 16px;
}
